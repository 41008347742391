import React, { Component } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Loader, Nocontent } from "components";
import client from "graphql/client";
import { BiLoaderAlt } from "react-icons/bi";
import getUnitOfMeasure from "graphql/queries/getUnitOfMeasure";
import { connect } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";

class UnitMeasurement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props?.checked || null,
      isSearch: false,
      measurement: [],
      categories: [],
      selectedUnit: "",
      // checked : []
      loading: true,
      count: 0,
      hasMorePages: false,
    };
  }
  componentDidMount() {
    client
      .query({
        query: getUnitOfMeasure,
      })
      .then((result) => {
        this.setState(
          {
            measurement: result.data.unit_of_measure_web.data,
            hasMorePages:
              result.data.unit_of_measure_web.paginatorInfo.hasMorePages,
            loading: false,
          },
          () => {
            this.defaultSelectUnit(this.props.uom);
          }
        );
      })
      .catch((error) => {});
  }

  defaultSelectUnit = (value) => {
    if (value) {
      let found = this.state.measurement.filter((o) => o.unit === value);
      this.setState({ checked: found[0] });
    }
  };
  searchUnitMeasures(keyword) {
    const firstState = this.state.measurement;
    this.setState({ loading: true }); // Set loading to true before making the API call

    if (keyword !== "") {
      client
        .query({
          query: getUnitOfMeasure,
          variables: { search: keyword },
        })
        .then((result) => {
          this.setState({
            loading: false, // Set loading to false after the API call is completed
            measurement: result.data.unit_of_measure_web.data,
            hasMorePages:
              result.data.unit_of_measure_web.paginatorInfo.hasMorePages,
          });
        })
        .catch((error) => {
          // Handle any errors if needed
          this.setState({ loading: false }); // Set loading to false if there's an error
        });
    } else {
      // Show all measurements when the keyword is empty
      client
        .query({
          query: getUnitOfMeasure,
        })
        .then((result) => {
          this.setState(
            {
              measurement: result.data.unit_of_measure_web.data,
              hasMorePages:
                result.data.unit_of_measure_web.paginatorInfo.hasMorePages,
              loading: false,
            },
            () => {
              this.defaultSelectUnit(this.props.uom);
            }
          );
        })
        .catch((error) => {});
    }
  }

  addUnitMeasure(item) {
    this.setState({
      checked: item,
    });

    this.addFields("uom", item.unit);
  }
  addForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addRfqForm(form);
  };

  addFields = (key, value) => {
    let form = Object.assign({}, this.props.fields);
    form[key] = value;
    this.props.addRfqFields(form);
  };

  moreTags = () => {
    this.setState(
      {
        loading: true,
        count: this.state.count + 1,
      },
      () => {
        client
          .query({
            query: getUnitOfMeasure,
            variables: { page: 1 + this.state.count },
          })
          .then((result) => {
            this.setState({
              ...this.state,
              measurement: [
                this.state.measurement,
                result.data.unit_of_measure_web.data,
              ],
            });
            this.setState({
              loading: false,
              hasMorePages:
                result.data.unit_of_measure_web.paginatorInfo.hasMorePages,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  };
  render() {
    const { checked, measurement, loading, hasMorePages } = this.state;
    const { uom } = this.props;

    return (
      <div className="relative p-4">
        <div className="bg--lightGray rounded-xl flex items-center h-10 px-3">
          <HiOutlineSearch className="darkGray" />
          <input
            placeholder="Enter to search and select"
            className="bg-transparent h-full w-full pl-3"
            onChange={(e) => this.searchUnitMeasures(e.target.value)}
          />
        </div>
        <div className="divider w-full my-3" />
        <div className="-mx-2">
          {loading ? ( // Show the loader while loading
            <Loader />
          ) : measurement && measurement.length ? ( // Check if measurement is available and not empty
            measurement.flat(Infinity).map((item, index) => (
              <ListItem
                disablePadding
                className="rounded-lg overflow-hidden"
                key={item.id}
              >
                <ListItemButton dense className="hover:primary-all">
                  <ListItemText>
                    <div className="darkGray">{item.unit}</div>
                  </ListItemText>
                  <ListItemIcon className="min-w-auto">
                    <Checkbox
                      checked={
                        checked !== null && checked.id === item.id ? true : ""
                      }
                      onChange={(event) => this.addUnitMeasure(item)}
                      className="primary-checkbox"
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <Nocontent text="No Record Found" />
          )}

          {this.state.hasMorePages && (
            <div className="text-center mt-5">
              <Box sx={{ "& > button": { m: 1 } }}>
                {!loading && hasMorePages && (
                  <LoadingButton
                    className="relative mx-auto important:white mt-10 hover:white hover:opacity-80 flex justify-center items-center px-5 border border-transparent rounded-lg h-11 w-44 important:Medium important:bg--primary hover:bg--primary focus:outline-none"
                    onClick={this.moreTags}
                    loading={loading}
                    title="More Tags"
                    loadingIndicator={
                      <BiLoaderAlt className="animate-spin white" size={16} />
                    }
                    variant="contained"
                  >
                    More Units
                  </LoadingButton>
                )}
              </Box>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ Rfq }) => {
  return {
    form: Rfq.form,
    fields: Rfq.fields,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    addRfqFields: (data) => {
      dispatch(actions.addRfqFields(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(UnitMeasurement);
