import React, { Component } from "react";
import {
  CustomDatePicker,
  Buttonlink,
  Fullscreenpopup,
  Subbanner,
  Loader,
} from "components";
import { Myteams } from "navigation";
import TextEditor from "./TextEditor";
import { connect } from "react-redux";
import client from "graphql/client";
import { Navigate } from "react-router";
import { CREATE_OR_UPDATE_RFQ } from "graphql/mutations/createOfferMutation";
import { SAVE_RFQ_DESCRIPTION } from "graphql/mutations/saveOfferDescriptionMutatiion";
import ChooseRFQCategories from "navigation/CategoriesScreen/components/chooseRFQCategories";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sendEmailAndAlertsToInvitedUsers from "graphql/mutations/sendEmailAndAlertsToInvitedUsers";
import getCategories from "../../../graphql/queries/getCategories";
import csvSample from "assets/file/Super_Rfq_Sample.csv";
import { IoIosCloseCircle } from "react-icons/io";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import { FiX } from "react-icons/fi";
import Box from "@mui/material/Box";
import cookie from "js-cookie";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { CgCloseO } from "react-icons/cg";
import Switch from "@mui/material/Switch";
import GET_MENU from "../../../graphql/queries/mro/menu";
import { extractValidationErrors } from "helpers/extractValidationErrors ";
import { checkPlan } from "helpers/checkPlan";
import { FaFileCsv } from "react-icons/fa";
import axios from "axios";
import { GRAPH_MRO_USER_AUTH_TOKEN } from "config/constants";

function TransitionAlerts(props) {
  const [open, setOpen] = React.useState(props.open);

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={open}>
        <Alert severity="warning" sx={{ mb: 2 }}>
          "You are not registered on Procurement League, If you want to post RFQ
          on Procurement League then click on following link”{" "}
          <a
            className={"font-bold btn btn-primary"}
            href={process.env.REACT_APP_PL_WEB_URL + "/signup"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            Click here
          </a>
        </Alert>
      </Collapse>
      <Buttonlink
        onClick={() => setOpen(true)}
        text="POST"
        className="relative ml-auto w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
      />
    </Box>
  );
}

const today = new Date();
const deadlineDate = new Date(today);
deadlineDate.setDate(today.getDate() + 14);
const formattedDeadline = deadlineDate.toISOString().split("T")[0];
class NewRFQ extends Component {
  constructor(props) {
    super(props);
    if (props.editable === false) {
      this.props.addRfqFields({});
    }
    this.state = {
      editor: true,
      modalType: "",
      isLoading: false,
      errors: [],
      createdOfferID: null,
      title: props.fields?.title ? props.fields.title : "",
      refNum: props.data?.reference_number
        ? props.data.reference_number
        : props.fields?.reference_number
          ? props.fields.reference_number
          : "",
      deadline: props.data.last_date ? props.data.last_date : "",
      quantity: props.fields?.quantity ? props.fields.quantity : "",
      unit: props.fields?.uom ? props.fields.uom : "",
      isRoute: false,
      showTagsModal: false,
      fields: {},
      // fields: {last_date: new Date(Date.now() + 12096e5)},
      mentions: [],
      suggestCategory: [],
      selectedCategories: [],
      titleErr: false,
      refNumErr: false,
      lastdateErr: false,
      quantityErr: false,
      uomErr: false,
      companyErr: false,
      categoryErr: false,
      editable: false,
      addInputProps: true,
      executive_roles: [],
      isOpen: false,
      sg_platform: true,
      preferredVendor: false,
      other_platform: false,
      offer_id: 0,
      executives: {},
      offerHasRoles: false,
      menu: [],
      inPage: props.inPage ? true : false,
      generalCategoryLimit: false,
      nextClicked: false,
      attachments: this.props?.data.attachment ?? null,
      nextButtonLoader: false,
      executiveLength: -1,
      is_public: this.props.is_public ?? false,
      multipleProduct: true,
      multipleProductFields: [
        {
          "Product Title": {
            type: "input",
            name: `product_title_1`,
            value: "",
          },
          "Product Description": {
            type: "input",
            name: `product_description_1`,
            value: "",
          },
          "Quantity": { type: "input", name: `number_1`, value: "" },
          "Unit of Measure": {
            type: "button",
            name: `unit_1`,
            value: "",
          },
          Deadline: { type: "date", name: `date_1`, value: formattedDeadline },
        },
      ],
      product_file: null,
      confirmation: false,
      uploadFile: false,
      file_product_type: true,
    };

    this.SelectFullPopup = React.createRef();
    this.timeout = 0;

    if (this.state.deadline !== "") {
      this.addFields("last_date", this.state.deadline);
    }
  }

  componentDidMount() {
    this.addForm("company", this.props?.data?.company?.id);

    if (this.props?.data) {
      this.getMenu();
    }

    if (this.props.editable === false) {
      this.props.addRfqFields({});
      //this.props.addRfqForm({});
      this.props.selectCategories([]);
      this.props.selectTags({});
      this.setState({
        title: "",
        refNum: "",
        quantity: "",
        unit: "",
        deadline: "",
        mentions: [],
        suggestCategory: [],
        selectedCategories: [],
        file_product_type: this.props.user?.authUserInformation.file_type,
      });
    }
    if (!this.props.editable) {
      this.addFields("reference_number", this.props.data?.reference_number);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { menu, params, editable } = this.props;

    if (
      this.props.params?.option !== this.state.menu?.code &&
      this.props.params?.option !== prevProps.params?.option
    ) {
      this.props.addRfqFields({});
      //this.props.addRfqForm({});
      this.props.selectCategories([]);
      this.props.selectTags({});
      this.setState({
        title: "",
        refNum: "",
        quantity: "",
        unit: "",
        deadline: "",
        mentions: [],
        suggestCategory: [],
        selectedCategories: [],
      });
    }
    // if (!editable) {
    //   this.setState({
    //     title: "",
    //     refNum: "",
    //     quantity: "",
    //     unit: "",
    //     deadline: "",
    //     mentions: [],
    //     suggestCategory: [],
    //     selectedCategories: [],
    //   });
    // }
    //if (prevState.editable && prevState.addInputProps)
    if (prevState.addInputProps) {
      const fields = [
        "title",
        "reference_number",
        "quantity",
        "uom",
        "tags",
        "description",
        "is_public"
      ];
      fields.forEach((field, index) => {
        this.timeout = setTimeout(() => {
          this.addFields(`${field}`, prevProps.data?.[`${field}`]);
        }, 10);
        if (index === 3) {
          this.setState({
            addInputProps: false,
          });
        }
      });
    }
  }

  getMenu = () => {
    this.setState({
      isLoading: true,
    });
    client
      .query({
        query: GET_MENU,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        // console.log(
        //   res.data.menu.filter((o) => o.code === this.props.params.option)
        // );
        let menuObject = res.data.menu.filter(
          (o) => o.code === this.props.params.option
        );
        if (!this.props.editable) {
          this.addFields("reference_number", menuObject[0].reference_number);
        }
        this.setState(
          {
            menu: menuObject[0],
            refNum: menuObject[0].reference_number,
            isLoading: false,
          },
          () => {
            let data = this.props.data;
            if (data && Object.keys(data).length !== 0) {
              if (data.products.length > 0) {
                const newFields = data.products.map((product, index) => {
                  // const formattedDeadline = formatDate(product.last_date);

                  return {
                    "Product Title": {
                      id: product.id,
                      type: "input",
                      name: `product_title_${index + 1}`,
                      value: product.title,
                    },
                    "Product Description": {
                      id: product.id,
                      type: "input",
                      name: `product_description_${index + 1}`,
                      value: product.description,
                    },
                    "Quantity": {
                      id: product.id,
                      type: "input",
                      name: `number_${index + 1}`,
                      value: product.quantity,
                    },
                    "Unit of Measure": {
                      id: product.id,
                      type: "button",
                      name: `unit_${index + 1}`,
                      value: product.uom,
                    },
                    Deadline: {
                      id: product.id,
                      type: "date",
                      name: `date_${index + 1}`,
                      value: product.last_date,
                    },
                  };
                });
                this.setState({
                  multipleProduct: true,
                  multipleProductFields: newFields,
                });
              }
              //this.addForm("company", this.props.data.company.id);
              this.addForm("category", data.categories);
              this.setState({
                selectedCategories: data.categories,
                createdOfferID: data.id,
                title: data.title,
                refNum: data.reference_number,
                deadline: data.last_date,
                quantity: data.quantity,
                unit: data.uom,
                is_public: data.is_public,
                editable: true,
                multipleProduct: data?.quantity > 0 ? false : true
              });
            }
          }
        );
        // console.log(this.props.params.option);
      });
  };

  simulateNetworkRequest = () => {
    return new Promise((resolve) => setTimeout(resolve, 3000));
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    return toast.success(message, { position: "top-right" });
  };

  openMeasurementModel = (product_id) => {
    const { fields, addRfqFields } = this.props;
    // this.addFields("uom", null);
    if (product_id >= 0) {
      // this.addFields("productId", product_id);
      // const form = { ...fields, [uom]: null, [productId]: product_id };
      // addRfqFields(form);
      this.props.addRfqFields({
        ...this.props.fields,
        productId: product_id,
        uom: null,
      });
    }
    this.setState({ modalType: "Unit of Measure" }, () => {
      this.SelectFullPopup.current.openPopup();
    });
  };

  choseCategory = () => {
    this.setState({ modalType: "Choose Category" }, () => {
      this.SelectFullPopup.current.openPopup();
    });
  };

  choseTags = () => {
    this.setState({ modalType: "Tags" }, () => {
      this.SelectFullPopup.current.openPopup();
    });
  };

  addFields = (key, fieldValue) => {
    const { fields, addRfqFields } = this.props;
    const { menu } = this.state;
    const form = {
      ...fields,
      [key]: fieldValue,
      reference_number: this.state.refNum,
    };
    addRfqFields(form);

    if (form.title && menu.id === "4") {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.searchCategories(form.title);
      }, 500);
    }

    setTimeout(() => { }, 1000);
  };

  searchCategories = (value) => {
    client
      .query({
        query: getCategories,
        variables: {
          search: value,
        },
      })
      .then((result) => {
        this.setState({
          suggestCategory: result.data.categories_web.data,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  suggestCategorieshandler = (category, maxCategories) => {
    let { selectedCategories } = this.state;
    let found = selectedCategories.find((data) => data.id === category.id);
    if (!found) {
      if (selectedCategories.length < maxCategories - 1) {
        selectedCategories.push(category);
      } else {
        this.notify(`Maximum ${maxCategories} categories are allowed`, "error");
        this.setState({ getCategories: true });
        return;
      }
    } else {
      selectedCategories = selectedCategories.filter(
        (data) => data.id !== category.id
      );
    }
    this.setState({ selectedCategories });
    // MySelectedRFQsAction.selectCategory(selectedCategories);
  };

  addForm = (key, value) => {
    // debugger;
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addRfqForm(form);
    this.props.selectCategories(form);
  };

  handleVendorCreateRfq = (e) => {
    e.preventDefault();
    const { form, fields } = this.props;
    const { menu } = this.state;
    const { isLoading, multipleProduct, multipleProductFields } = this.state;
    const isValid = Object.values(multipleProductFields).every((product) => {
      return Object.values(product).every((field) => {
        return field.value !== "" && field.value !== null;
      });
    });
    // if (!isValid && menu.id == 3) {
    //   return this.notify("Please Fill all the values", "error");
    // }
    // Validate form inputs
    const titleErr = !fields.title;
    const companyErr = !form.company;
    this.setState({ titleErr, companyErr });
    if (fields.title && form.company) {
      this.setState({ isLoading: true });
      client
        .mutate({
          mutation: CREATE_OR_UPDATE_RFQ,
          variables: {
            offer_id: this.state.createdOfferID,
            is_public: this.state.is_public,
            produts: multipleProduct ? multipleProductFields : null,
            fields,
            menu_id: parseInt(menu.id),
            company: parseInt(form.company),
            status: "0",
          },
        })
        .then((result) => {
          if (result.data?.createOffer?.id) {
            this.setState(
              {
                title: fields.title,
                editor: false,
                isLoading: false,
                createdOfferID: result.data.createOffer.id,
                showTagsModal: true,
                executives: result.data.createOffer.executives,
                offerHasRoles: result.data.createOffer.offerHasRoles,
                inPage: true,
              },
              () => {
                this.addFields("offer", result.data.createOffer.id);
              }
            );
          } else {
            this.setState({ isLoading: false, showTagsModal: true });
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.setState({ isLoading: false });
        });
    } else {
      this.notify("Something went wrong Please check your package", "error");
    }
  };

  handleCreateRfq = (e) => {

    e.preventDefault();

    const { form, fields } = this.props;
    const { menu, isLoading, multipleProduct, multipleProductFields, is_public } = this.state;


    if (isLoading) {
      return;
    }
    if (this.state.product_file !== null) {
      if (this.state.nextClicked) {
        this.setState({
          showTagsModal: !this.state.showTagsModal,
          editor: !this.state.editor,
        });
        return;
      }
      if (!fields.title) {
        this.setState({ titleErr: true });
        return;
      }
      this.setState({
        confirmation: true,
      });
      // this.setState({
      //   editor: false,
      //   isLoading: false,
      //   createdOfferID: result.data.createOffer.id,
      //   showTagsModal: true,
      //   executives: result.data.createOffer.executives,
      //   offerHasRoles: result.data.createOffer.offerHasRoles,
      //   nextClicked: true
      // });

      // this.addFields("offer", result.data.createOffer.id);
      return;
    }
    if (multipleProduct || (this.props.editable && fields.uom == null)) {
      if (fields.title) {
        this.setState({ titleErr: false });
      }
      if (fields.reference_number) {
        this.setState({ refNumErr: false });
      }
      if (!this.state.file_product_type) {
        let i = 0;
        const isValid = Object.values(multipleProductFields).every((product) => {
          return Object.entries(product).every(([key, field]) => {
            if ((field.value === "" || field.value === null) && i === 0) {
              i++;
              this.notify(`${key} is required!`, "error");
            }
            return field.value !== "" && field.value !== null;
          });
        });
        if (!isValid) {
          return;
        }
      }
      
    } else {
      if (fields.title) {
        this.setState({ titleErr: false });
      }
      if (fields.reference_number) {
        this.setState({ refNumErr: false });
      }
      if (fields.last_date) {
        this.setState({ lastdateErr: false });
      }

      if (fields.quantity) {
        this.setState({ quantityErr: false });
      } else {
        this.setState({ quantityErr: true });
        return this.notify("Quantity can't be Zero", "error");
      }

      if (fields.uom) {
        this.setState({ uomErr: false });
      } else {
        this.setState({ uomErr: true });
        return this.notify("Unit of Measure", "error");
      }

      if (this.state.quantity === 0) {
        this.notify("Quantity can't be Zero", "error");
      }
    }

    if (form.company) {
      this.setState({ companyErr: false });
    } else {
      return this.notify("Please Select/Create a Team", "error");
    }
    this.setState({
      isLoading: true,
    });
    // this.removeOfferInFields(fields);
    if (
      fields?.title &&
      fields?.reference_number &&
      // fields?.last_date &&
      // fields?.last_date !== "Invalid date" &&
      // fields?.quantity &&
      // fields?.uom &&
      form?.company
    ) {
      client
        .mutate({
          mutation: CREATE_OR_UPDATE_RFQ,
          variables: {
            offer_id: this.state.createdOfferID,
            is_public: this.state.is_public,
            products: !this.props.user?.authUserInformation.file_type && multipleProduct
              ? multipleProductFields
              : null,
            fields: fields,
            menu_id: parseInt(menu.id),
            company: parseInt(form.company),
            status: "0",
          },
        })
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.createOffer &&
            result.data.createOffer.id
          ) {
            this.setState({
              editor: false,
              isLoading: false,
              createdOfferID: result.data.createOffer.id,
              showTagsModal: true,
              executives: result.data.createOffer.executives,
              offerHasRoles: result.data.createOffer.offerHasRoles,
              nextClicked: true,
            });

            this.addFields("offer", result.data.createOffer.id);
          } else {
            this.setState({ isLoading: false, showTagsModal: true });
          }

          //MyAuthUserAction.getAuthUserInformation();
          // client.query({
          //   query: getPayment,
          //   fetchPolicy: 'no-cache',
          // }).then((res) => {
          //   console.log('res.data.me.paymentsss', res.data.me.payments);
          //   store.dispatch({
          //     type: UPDATE_AUTH_USER_PROPERTY,
          //     payload: { key: "payments", value: res.data.me.payments },
          //   });
          // })
        })
        .catch((error) => {
          extractValidationErrors(error).forEach((error) =>
            this.notify(error, "error")
          );
          this.setState({ isLoading: false });
          // if (error) {
          //   this.props.navigate("/pricing/system");
          // }
        });
    } else {
      if (!fields.title) {
        this.setState({ titleErr: true });
      }
      if (!fields.reference_number) {
        this.setState({ refNumErr: true });
      }
      if (!fields.last_date) {
        this.setState({ lastdateErr: true });
      }
      if (fields.last_date === "Invalid date") {
        this.setState({ lastdateErr: true });
      }
      if (!fields.quantity) {
        this.setState({ quantityErr: true });
      }
      if (!fields.uom) {
        this.setState({ uomErr: true });
      }
      if (!form.company) {
        this.setState({ companyErr: true });
      }
      this.setState({
        isLoading: false,
      });
    }
  };

  clearFields = () => {
    this.props.clearFields();
  };

  validateFields = () => {
    const { form, fields, menu } = this.props;
    const { sg_platform, other_platform, selectedCategories, executiveLength, offerHasRoles } = this.state;
    const canAssignRole = checkPlan(
      "bool",
      "Assigned Role to team",
      this.props.user?.authUserInformation?.payments
    );

    let selected_platform = null;
    if (sg_platform && !other_platform) {
      selected_platform = 0;
    } else if (other_platform && !sg_platform) {
      selected_platform = 1;
    } else {
      selected_platform = 2;
    }

    let categories = [];
    if (menu?.id == 4) {
      selectedCategories.forEach((element) => {
        categories.push(parseInt(element.id));
      });
      categories.push(1);
    } else if (form.category) {
      form.category.forEach((element) => {
        categories.push(parseInt(element.id));
      });
    }

    let tags = [];
    if (fields.tags) {
      fields.tags.forEach((element) => {
        tags.push(parseInt(element.id));
      });
    }

    if (!fields.description || fields.description.length < 9) {
      this.notify("Description is required!", "error");
      return false;
    } else if (categories.length === 0) {
      this.notify("Sourcing Genie Categories are required!", "error");
      return false;
    } else if (tags.length === 0) {
      this.notify("Sourcing Genie Tags are required!", "error");
      return false;
    } else if (executiveLength > 1 && canAssignRole && !offerHasRoles && menu?.id !== "7") {
      this.notify("You can't publish RFQ without selecting a member", "error");
      return false;
    }

    // Return validated data
    return {
      selected_platform,
      categories,
      tags,
    };
  };

  handlepostRfq = () => {
    const validatedData = this.validateFields();
    if (!validatedData) {
      return; // Stop execution if validation fails
    }

    const { selected_platform, categories, tags } = validatedData;
    const { fields, menu } = this.props;
    const { sg_platform, other_platform } = this.state;

    if (!sg_platform && !other_platform && menu?.id !== "7") {
      this.notify("RFQ post platform is required!", "error");
      return false;
    } else if (other_platform && this.props.user?.authUserInformation?.has_pl_account === false) {
      this.notify("You do not have a PL Account!", "error");
      return false;
    }

    client
      .mutate({
        mutation: SAVE_RFQ_DESCRIPTION,
        variables: {
          offer_id: this.state.createdOfferID,
          description: fields.description,
          status: "1",
          tags: tags,
          category: categories,
          attachments: fields.image ? fields.image : [],
          platform: selected_platform,
          preferredVendor: this.state.preferredVendor,
        },
      })
      .then((result) => {
        if (this.props.user.socket?.connected) {
          this.props.user.socket.emit("notify", "Notification data");
        }
        if (this.state.mentions.length > 0) {
          this.sendEmailToMentionUsers();
        } else {
          this.props.addRfqFields({});
          this.props.selectCategories([]);
          this.props.selectTags({});
          this.setState({ isRoute: true, showTagsModal: false });
          const successMessage = this.props.params.option === "help_expert"
            ? "Great! Recommendation successfully created."
            : "Great! RFQ successfully submitted. If required you can share the RFQ with your preferred suppliers via social media.";
          toast.success(successMessage, { position: "top-right" });
        }
      })
      .catch((error) => {
        console.error("error", error);
        this.props.addRfqFields({});
        this.setState({ isRoute: true, showTagsModal: false });
      });

    this.setState({ isOpen: false });
  };

  getMentions = (mentions) => {
    this.setState({ mentions: mentions });
  };

  sendEmailToMentionUsers = () => {
    const { fields } = this.props;
    // console.log("in email/mentions > 0");
    // console.log("mentions", this.state.mentions);
    // console.log("offer id", parseInt(fields.offer));
    // console.log("offer title", fields);

    client
      .mutate({
        mutation: sendEmailAndAlertsToInvitedUsers,
        variables: {
          username: this.state.mentions,
          offer_id: parseInt(fields.offer),
          offer_title: fields.title,
        },
      })
      .then((res) => {
        // console.log("mentioned", res);
        // console.log("this.state.mentions", this.state.mentions);
        // console.log("Email sent to mentioned users", res);
        this.props.addRfqFields({});
        //this.props.addRfqForm({});
        this.setState({ isRoute: true, showTagsModal: false });
      })
      .catch((err) => {
        // console.log("ERRRRR", err);
        // console.log("Email NOT sent to mentioned users", err);
        this.props.addRfqFields({});
        //this.props.addRfqForm({});
        this.setState({ isRoute: true, showTagsModal: false });
      });
  };

  companyChangeLoader = (length, loader) => {
    this.setState({ executiveLength: length, nextButtonLoader: loader });
  };

  handleMultipleProductFields = () => {
    const fields = {
      "Product Title": {
        type: "input",
        name: `product_title_${this.state.multipleProductFields.length + 1}`,
        value: "",
      },
      "Product Description": {
        type: "input",
        name: `product_description_${this.state.multipleProductFields.length + 1
          }`,
        value: "",
      },
      "Quantity": {
        type: "input",
        name: `number_${this.state.multipleProductFields.length + 1}`,
        value: "",
      },
      "Unit of Measure": {
        type: "button",
        name: `unit_${this.state.multipleProductFields.length + 1}`,
        value: "",
      },
      Deadline: {
        type: "date",
        name: `date_${this.state.multipleProductFields.length + 1}`,
        value: formattedDeadline,
      },
    };
    this.setState({
      multipleProductFields: [...this.state.multipleProductFields, fields],
    });
  };

  handleMultipleProduct = () => {
    if (!this.props.editable && this.state.product_file === null) {
      this.setState({ multipleProduct: !this.state.multipleProduct }, () => {
        if (!this.state.multipleProduct) {
          this.setState({
            multipleProductFields: [
              {
                "Product Title": {
                  type: "input",
                  name: `product_title_1`,
                  value: "",
                },
                "Product Description": {
                  type: "input",
                  name: `product_description_1`,
                  value: "",
                },
                "Quantity": {
                  type: "input",
                  name: `number_1`,
                  value: "",
                },
                "Unit of Measure": {
                  type: "button",
                  name: `unit_1`,
                  value: "",
                },
                Deadline: {
                  type: "date",
                  name: `date_1`,
                  value: formattedDeadline,
                },
              },
            ],
          });
        }
      });
    }
  };

  handleChangeFile = async (e) => {
    if (this.state.nextClicked) {
      return this.notify(
        "Your RFQ is already in progress, can't upload file now",
        "error"
      );
    }

    const file = e.target.files[0];

    if (!file) {
      return;
    }

    const validMimeType = "text/csv";
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes

    if (file.type !== validMimeType) {
      return this.notify("Only CSV files are allowed", "error");
    }

    if (file.size > maxFileSize) {
      return this.notify(
        "File size must be less than or equal to 5 MB",
        "error"
      );
    }

    this.setState({
      product_file: file,
    });
  };

  handleFileUpload = async () => {
    if (this.state.nextClicked) {
      return;
    }
    const formData = new FormData();
    formData.append("csv_file", this.state.product_file);
    formData.append("company", this.props?.form.company);
    formData.append("reference_number", this.state.refNum);
    formData.append("title", this.props?.fields.title);

    const token = cookie.get(GRAPH_MRO_USER_AUTH_TOKEN);
    if (this.state.product_file) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/api/super-rfq`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          this.notify("File uploaded successfully", "success"); // Show success message
          this.setState({
            editor: false,
            isLoading: false,
            confirmation: !this.state.confirmation,
            createdOfferID: response.data?.offer?.id,
            showTagsModal: true,
            // executives: result.data.createOffer.executives,
            // offerHasRoles: result.data.createOffer.offerHasRoles,
            nextClicked: true,
          });

          return true;
        }
      } catch (error) {
        this.notify("The given data was invalid");
        this.setState({ confirmation: !this.state.confirmation });
      }
    } else {
      this.notify("Please select file");
    }
  };

  handleInputChange = (groupIndex, fieldKey, event) => {
    const value = event.target.value;

    if (fieldKey === "Quantity") {
      if (value !== "") {
        const validQuantity = /^\d*$/;
        if (!validQuantity.test(value) || value == 0) {
          // If the value is invalid, ignore the change
          return;
        }
      }
      // Validate quantity: accept only numbers and should not start with zero unless it's zero
    }

    const updatedFields = this.state.multipleProductFields.map(
      (group, index) => {
        if (index === groupIndex) {
          const updatedFieldProps = { ...group[fieldKey] };
          updatedFieldProps.value = value;
          return {
            ...group,
            [fieldKey]: updatedFieldProps,
          };
        }
        return group;
      }
    );

    this.setState({
      multipleProductFields: updatedFields,
    });
  };

  handleCheckboxChange = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key],
    }));
  };


  render() {
    const {
      modalType,
      isLoading,
      createdOfferID,
      isRoute,
      showTagsModal,
      titleErr,
      refNumErr,
      lastdateErr,
      quantityErr,
      uomErr,
      companyErr,
      categoryErr,
      title,
      refNum,
      deadline,
      quantity,
      unit,
      sg_platform,
      other_platform,
      executives,
      menu,
      is_public,
      selectedCategories,
      multipleProduct,
      multipleProductFields,
    } = this.state;
    const { form, fields } = this.props;

    if (multipleProduct && fields.productId >= 0) {
      const uomField =
        multipleProductFields[fields.productId]?.["Unit of Measure"];
      if (uomField) {
        uomField.value = fields.uom;
      }
    }
    if (isRoute) {
      if (this.props.params.option === "help_expert") {
        return <Navigate to="/recommendation" />;
      } else {
        return <Navigate to="/rfq/0" state={{ cardId: 0 }} />;
      }
    }

    const size = 5;
    let slicearray = this.state.suggestCategory.slice(
      0,
      this.state.suggestCategory.length
    );

    const userInput = this.state.title.toLowerCase(); // Convert user input to lowercase

    slicearray.sort((a, b) => {
      const aName = String(a.name).toLowerCase(); // Ensure 'a' is a string and convert to lowercase
      const bName = String(b.name).toLowerCase(); // Ensure 'b' is a string and convert to lowercase

      if (aName.startsWith(userInput) && !bName.startsWith(userInput)) {
        return -1; // 'a' starts with the user input, so it comes first
      } else if (!aName.startsWith(userInput) && bName.startsWith(userInput)) {
        return 1; // 'b' starts with the user input, so it comes first
      } else {
        return aName.localeCompare(bName); // Sort alphabetically for other strings
      }
    });

    // Now slicearray is sorted based on the user input with names that start with the input coming first.

    const label = { inputProps: { "aria-label": "Switch demo" } };

    let canSubmitPreferedVendors = false;
    if (this.props?.user?.authUserInformation?.payments) {
      this.props?.user?.authUserInformation?.payments?.forEach((plan) => {
        if (plan.plan.plan_features) {
          plan.plan.plan_features.forEach((plan_feature) => {
            if (plan_feature.name === "Preferred vendor Submission") {
              canSubmitPreferedVendors = true;
            }
          });
        }
      });
    }
    let maxCategories = checkPlan(
      "number",
      "No. of category selection on RFQ",
      this.props?.user?.authUserInformation?.payments
    );
    const multiplePostingPlatforms = checkPlan(
      "bool",
      "RFQ posting platforms",
      this.props?.user?.authUserInformation?.payments
    );
    const minDate = new Date(today);
    minDate.setDate(today.getDate() + 14);
    return (
      <>
        <div className="h-full">
          <Subbanner title={`${menu?.name}`} />
          <div className="container -mt-28">
            <div className="grid gap-4 lg:grid-cols-3 overflow-x-auto">
              {isLoading ? (
                <div className="lg:col-span-2 ">
                  <Loader />
                </div>
              ) : (
                <div className="lg:col-span-2 intro-y">
                  <div className="overflow-hidden box">
                    <div>
                      <div className="sm:p-10 p-2 bg-white rounded-xl">
                        {this.state.editor ? (
                          (menu && menu.id == 6) || menu.id == 7 ? (
                            <>
                              <div className="intro-y">
                                <label
                                  htmlFor="RFQ-Title"
                                  className="Medium fs-20"
                                >
                                  Enter Title
                                </label>
                                <input
                                  id="RFQ-Title"
                                  name="RFQ Title"
                                  type="text"
                                  autoComplete="RFQ Title"
                                  required
                                  maxLength="70"
                                  value={title}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const alphanumericRegex =
                                      /^[a-zA-Z0-9\s]*$/;
                                    this.setState({
                                      title: e.target.value,
                                    });
                                    if (
                                      alphanumericRegex.test(value) ||
                                      value === ""
                                    ) {
                                      this.addFields("title", value);
                                    }
                                  }}
                                  className={`appearance-none placeholder-responsive rounded-md relative block w-full px-3 py-3 border ${titleErr
                                    ? "border-red-500"
                                    : "border-gray-300"
                                    } placeholder-gray-500 focus:outline-none shadow-sm`}
                                  placeholder="Vendor Enquiry"
                                />
                              </div>
                              <div className="h-5" />
                              <div className="flex items-end justify-end intro-y">
                                <Buttonlink
                                  onClick={(e) => this.handleVendorCreateRfq(e)}
                                  disabled={this.state.nextButtonLoader}
                                  text={
                                    this.state.nextButtonLoader ? (
                                      <Loader spinner={true} />
                                    ) : (
                                      "Next"
                                    )
                                  }
                                  className="relative w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="space-y-4">
                                <div className="intro-y">
                                  <div className="flex justify-between items-center mb-2">
                                    <label
                                      htmlFor="RFQ-Title"
                                      className="Medium fs-20"
                                    >
                                      RFQ Title
                                    </label>
                                    {menu.id == 3 && (
                                      <div className="flex items-center gap-x-5">
                                        <div className="flex items-center">
                                          <Checkbox
                                            className="primary-checkbox"
                                            onChange={() => this.handleCheckboxChange('multipleProduct')}
                                            checked={multipleProduct}
                                          />
                                          <p className="fs-14">Multiple Products</p>
                                        </div>
                                        <div className="flex items-center">
                                          <Checkbox
                                            className="primary-checkbox"
                                            onChange={() => this.handleCheckboxChange('is_public')}
                                            checked={!is_public}
                                          />
                                          <p className="fs-14">Private</p>
                                        </div>
                                        {!this.props.editable &&
                                          !this.state.file_product_type &&
                                          multipleProduct && (
                                            <>
                                              <div className="flex items-center gap-x-2">
                                                <p>upload file</p>
                                                <Switch
                                                  checked={
                                                    this.state.uploadFile
                                                  }
                                                  onChange={() =>
                                                    this.setState({
                                                      uploadFile:
                                                        !this.state.uploadFile,
                                                      product_file: null,
                                                    })
                                                  }
                                                  sx={{
                                                    "& .Mui-checked": {
                                                      color: "#ef553b", // Change this to your custom color
                                                    },
                                                    "& .Mui-checked + .MuiSwitch-track":
                                                    {
                                                      backgroundColor:
                                                        "#ef553b ", // Change this to your custom color
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </>
                                          )}
                                        {!this.props.editable &&
                                          this.state.uploadFile &&
                                          !this.state.file_product_type &&
                                          multipleProduct && (
                                            <>
                                              <label
                                                htmlFor="product_file"
                                                className="flex intro-x items-center gap-x-3 cursor-pointer"
                                              >
                                                <input
                                                  accept=".csv"
                                                  onChange={
                                                    this.handleChangeFile
                                                  }
                                                  type="file"
                                                  name="product_file"
                                                  className="hidden"
                                                  id="product_file"
                                                />
                                                <FaFileCsv
                                                  className="primary"
                                                  size={20}
                                                />{" "}
                                                <p className="fs-14">
                                                  Upload file
                                                </p>
                                              </label>
                                              <a
                                                download
                                                href={csvSample}
                                                className={`primary bg--lightPrimary intro-x hover:bg--primary relative hover:white hover:opacity-80 flex justify-center fs-13 py-1 px-4 border border-transparent rounded-lg focus:outline-none`}
                                              >
                                                Download sample
                                              </a>
                                            </>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                  <input
                                    id="RFQ-Title"
                                    name="RFQ Title"
                                    type="text"
                                    autoComplete="RFQ Title"
                                    required
                                    maxLength="100"
                                    value={title}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const alphanumericRegex =
                                        /^[a-zA-Z0-9\s]*$/;

                                      if (
                                        alphanumericRegex.test(value) ||
                                        value === ""
                                      ) {
                                        this.setState({
                                          title: e.target.value,
                                        });
                                        this.addFields("title", e.target.value);
                                      }
                                    }}
                                    className={`appearance-none placeholder-responsive rounded-md relative block w-full px-3 py-3 border ${titleErr
                                      ? "border-red-500"
                                      : "border-gray-300"
                                      } placeholder-gray-500 focus:outline-none shadow-sm`}
                                    placeholder="Enter short description or header"
                                  />
                                </div>
                                <div className="intro-y">
                                  <label
                                    htmlFor="Reference-Number"
                                    className="Medium fs-20"
                                  >
                                    Reference ID
                                  </label>
                                  <input
                                    id="Reference-Number"
                                    name="Reference ID"
                                    type="text"
                                    maxLength="20"
                                    readOnly
                                    value={refNum}
                                    onChange={(e) => {
                                      this.setState({ refNum: e.target.value });
                                      // this.addFields(
                                      //   "reference_number",
                                      //   e.target.value
                                      // );
                                    }}
                                    autoComplete="Reference ID"
                                    required
                                    className={`appearance-none placeholder-responsive rounded-md relative block w-full px-3 py-3 border ${refNumErr
                                      ? "border-red-500"
                                      : "border-gray-300"
                                      } placeholder-gray-500 focus:outline-none shadow-sm`}
                                    placeholder="Enter code or internal tracking number"
                                  />
                                </div>
                                {(this.props.data?.products?.length == 0 &&
                                  this.props.fields?.uom !== null &&
                                  this.props?.editable) ||
                                  (!this.state.product_file &&
                                    !this.props?.editable &&
                                    !multipleProduct) ? (
                                  <>
                                    <div className="intro-y">
                                      <label
                                        htmlFor="numeric"
                                        className="Medium fs-20"
                                      >
                                        Quantity
                                      </label>
                                      <input
                                        id="number"
                                        name="number"
                                        type="text"
                                        maxLength={8}
                                        pattern="^[0-9\\-]+$"
                                        value={quantity}
                                        max={99999999}
                                        onChange={(e) => {
                                          const newValue =
                                            e.target.value.replace(/\D/g, "");
                                          const newQuantity =
                                            newValue === ""
                                              ? ""
                                              : parseInt(newValue, 10);
                                          this.setState({
                                            quantity: newQuantity,
                                          });
                                          this.addFields(
                                            "quantity",
                                            newQuantity
                                          );
                                        }}
                                        // onKeyPress="return event.charCode >= 48"
                                        required
                                        className={`appearance-none placeholder-responsive rounded-md relative block w-full px-3 py-3 border ${quantityErr
                                          ? "border-red-500"
                                          : "border-gray-300"
                                          } placeholder-gray-500 focus:outline-none shadow-sm`}
                                        placeholder="Quantity only in numeric"
                                      />
                                    </div>
                                    <div className="intro-y">
                                      <label
                                        htmlFor="password"
                                        className="Medium fs-20"
                                      >
                                        Unit of Measure
                                      </label>
                                      <Buttonlink
                                        onClick={() =>
                                          this.openMeasurementModel(-1)
                                        }
                                        className={`Light darkGray text-left appearance-none rounded-md sm:fs-15 fs-12 relative block w-full pr-20 px-3 py-3 border ${uomErr
                                          ? "border-red-500"
                                          : "border-gray-300"
                                          } placeholder-gray-500 focus:outline-none shadow-sm`}
                                        text={
                                          // multipleProductFields[0]['Unit of Measure'].value ? multipleProductFields[0]['Unit of Measure'].value : "Select Unitsss"
                                          unit
                                            ? unit
                                            : fields.uom
                                              ? fields.uom
                                              : "Select Unit"
                                        }
                                      />
                                    </div>
                                    <div className="intro-y">
                                      <label
                                        htmlFor="email-address"
                                        className="Medium fs-20"
                                      >
                                        Deadline
                                      </label>
                                      <div
                                        className={
                                          lastdateErr
                                            ? "flex items-center gap-x-3 restrict-picker"
                                            : "flex items-center gap-x-3"
                                        }
                                      >
                                        <p className="darkGray Light">
                                          You can edit the deadline:{" "}
                                        </p>
                                        <CustomDatePicker />
                                      </div>
                                    </div>
                                  </>
                                ) : (this.state.file_product_type &&
                                  !this.props.editable) ||
                                  (this.props.editable &&
                                    this.props.data.products.length === 0 &&
                                    this.props.fields?.uom == null) ? (
                                  <div className="intro-y">
                                    <label
                                      htmlFor="email-address"
                                      className="Medium fs-20"
                                    >
                                      Deadline
                                    </label>
                                    <div
                                      className={
                                        lastdateErr
                                          ? "flex items-center gap-x-3 restrict-picker"
                                          : "flex items-center gap-x-3"
                                      }
                                    >
                                      <p className="darkGray Light">
                                        You can edit the deadline:{" "}
                                      </p>
                                      <CustomDatePicker />
                                    </div>
                                  </div>
                                ) : null}
                                {((!this.state.uploadFile &&
                                  multipleProduct &&
                                  !this.state.file_product_type) ||
                                  (this.props.data.products &&
                                    this.props.data.products.length > 0)) &&
                                  multipleProductFields.map(
                                    (group, groupIndex) => (
                                      <div
                                        key={groupIndex}
                                        className="space-y-4 pb-4"
                                      >
                                        {multipleProductFields.length >= 2 &&
                                          groupIndex ===
                                          multipleProductFields.length -
                                          1 && (
                                            <div className="flex justify-end items-end">
                                              <button
                                                onClick={() =>
                                                  this.setState(
                                                    (prevState) => ({
                                                      multipleProductFields:
                                                        prevState.multipleProductFields.filter(
                                                          (_, i) =>
                                                            i !== groupIndex
                                                        ),
                                                    })
                                                  )
                                                }
                                                className="primary bg--lightPrimary intro-x hover:bg--primary relative hover:white hover:opacity-80 flex justify-center fs-13 py-1 px-4 border border-transparent rounded-lg focus:outline-none"
                                              >
                                                remove
                                              </button>
                                            </div>
                                          )}
                                        {Object.entries(group).map(
                                          ([fieldLabel, fieldProps]) =>
                                            fieldLabel === "Product Title" ? (
                                              <div className="intro-y">
                                                <label
                                                  htmlFor="Product-Title"
                                                  className="Medium fs-20"
                                                >
                                                  {fieldLabel}
                                                </label>
                                                <input
                                                  id="Product-Title"
                                                  name="product_title"
                                                  type="text"
                                                  required
                                                  value={fieldProps.value}
                                                  className={`appearance-none placeholder-responsive rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm`}
                                                  placeholder="Enter product title"
                                                  onChange={(event) =>
                                                    this.handleInputChange(
                                                      groupIndex,
                                                      "Product Title",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            ) : fieldLabel ===
                                              "Product Description" ? (
                                              <div className="intro-y">
                                                <label
                                                  htmlFor="pro_des"
                                                  className="Medium fs-20"
                                                >
                                                  {fieldLabel}
                                                </label>
                                                <input
                                                  id="pro_des"
                                                  name="product_description"
                                                  type="text"
                                                  value={fieldProps.value}
                                                  onChange={(event) =>
                                                    this.handleInputChange(
                                                      groupIndex,
                                                      "Product Description",
                                                      event
                                                    )
                                                  }
                                                  required
                                                  className={`appearance-none placeholder-responsive rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm`}
                                                  placeholder="Quantity only in numeric"
                                                ></input>
                                              </div>
                                            ) : fieldLabel ===
                                              "Quantity" ? (
                                              <div className="intro-y">
                                                <label
                                                  htmlFor="numeric"
                                                  className="Medium fs-20"
                                                >
                                                  {fieldLabel}
                                                </label>
                                                <input
                                                  id="number"
                                                  name="number"
                                                  type="text"
                                                  maxLength={8}
                                                  pattern="^[0-9\\-]+$"
                                                  max={99999999}
                                                  value={fieldProps.value}
                                                  onChange={(event) =>
                                                    this.handleInputChange(
                                                      groupIndex,
                                                      "Quantity",
                                                      event
                                                    )
                                                  }
                                                  required
                                                  className={`appearance-none placeholder-responsive rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm`}
                                                  placeholder="Quantity only in numeric"
                                                />
                                              </div>
                                            ) : fieldLabel ===
                                              "Unit of Measure" ? (
                                              <div className="intro-y">
                                                <label className="Medium fs-20">
                                                  {fieldLabel}
                                                </label>
                                                <Buttonlink
                                                  onClick={() =>
                                                    this.openMeasurementModel(
                                                      groupIndex
                                                    )
                                                  }
                                                  className={`Light darkGray text-left appearance-none rounded-md sm:fs-15 fs-12 relative block w-full pr-20 px-3 py-3 border ${uomErr
                                                    ? "border-red-500"
                                                    : "border-gray-300"
                                                    } placeholder-gray-500 focus:outline-none shadow-sm`}
                                                  text={
                                                    fieldProps.value?.length > 2
                                                      ? fieldProps.value
                                                      : "Select Unit"
                                                  }
                                                // value={fieldProps.value !== '' ? fieldProps.value : "Select Unit"}
                                                />
                                              </div>
                                            ) : (
                                              <div className="intro-y">
                                                <label
                                                  htmlFor="date"
                                                  className="Medium fs-20"
                                                >
                                                  {fieldLabel}
                                                </label>
                                                <div
                                                  className={
                                                    "flex items-center gap-x-3"
                                                  }
                                                >
                                                  <p className="darkGray Light">
                                                    You can edit the deadline:{" "}
                                                  </p>
                                                  <input
                                                    type="date"
                                                    value={fieldProps.value}
                                                    min={
                                                      minDate
                                                        .toISOString()
                                                        .split("T")[0]
                                                    }
                                                    onChange={(event) =>
                                                      this.handleInputChange(
                                                        groupIndex,
                                                        fieldLabel,
                                                        event
                                                      )
                                                    }
                                                    className="bg-transparent h-full w-max border rounded-lg border-gray-300 py-1 shadow-sm px-3"
                                                    id=""
                                                  />
                                                </div>
                                              </div>
                                            )
                                        )}
                                      </div>
                                    )
                                  )}

                                <div className="flex justify-between items-center">
                                  {!this.state.uploadFile &&
                                    !this.state.file_product_type &&
                                    multipleProduct &&
                                    multipleProductFields?.length !== 20 && (
                                      <div className="flex items-end justify-end intro-y">
                                        <Buttonlink
                                          onClick={
                                            this.handleMultipleProductFields
                                          }
                                          text={"Add more"}
                                          className="relative w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
                                        />
                                      </div>
                                    )}
                                  <div className="flex grow items-end justify-end intro-y">
                                    <Buttonlink
                                      onClick={(e) => this.handleCreateRfq(e)}
                                      disabled={this.state.nextButtonLoader}
                                      text={
                                        this.state.nextButtonLoader ? (
                                          <Loader spinner={true} />
                                        ) : (
                                          "Next"
                                        )
                                      }
                                      className="relative w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        ) : (
                          <>
                            <div className="space-y-4">
                              <TextEditor
                                createdOffer={createdOfferID}
                                sendMailToMentionedUsers={this.getMentions}
                                data={
                                  this.props?.editable
                                    ? this.props?.data
                                    : this.props?.fields
                                }
                              />
                              <div className="h-5" />
                              <div className="flex items-end justify-between intro-y">
                                {showTagsModal && (
                                  <Buttonlink
                                    onClick={() =>
                                      this.setState({
                                        showTagsModal:
                                          !this.state.showTagsModal,
                                        editor: !this.state.editor,
                                      })
                                    }
                                    text="Back"
                                    className="relative w-28 sm:w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
                                  />
                                )}
                                {menu && menu.id === "7" ? (
                                  <Buttonlink
                                    onClick={this.handlepostRfq}
                                    text="POST"
                                    className="relative w-28 sm:w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
                                  />
                                ) : (
                                  <Buttonlink
                                    onClick={() => {
                                      if (this.validateFields()) {
                                        this.setState({ isOpen: true });
                                      }
                                    }}
                                    text="Post"
                                    className="relative w-28 sm:w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                {this.state.suggestCategory &&
                  this.state.suggestCategory.length > 0 &&
                  this.state.title.length > 0 && (
                    <div className="p-5 mb-5 overflow-hidden box intro-y">
                      <h3 className="mb-5 text-xl Medium intro-x">
                        Suggested Category
                      </h3>
                      <div className="flex flex-wrap items-center gap-3 selected-tags">
                        {this.state.suggestCategory &&
                          !showTagsModal &&
                          menu.id == 4 &&
                          slicearray.slice(0, size).map((category) => (
                            <div
                              className="relative intro-x"
                              onClick={() =>
                                this.suggestCategorieshandler(
                                  category,
                                  maxCategories
                                )
                              }
                            >
                              <label
                                htmlFor={category.name}
                                className="bg-gray-200 hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
                              >
                                <p className="fs-13">{category.name}</p>
                              </label>
                            </div>
                          ))}

                        {/* {this.state.suggestCategory &&
                          !showTagsModal &&
                          menu.id == 4 &&
                          slicearray.map((category) =>
                            category.items.map((item) => (
                              <div
                                className="relative intro-x"
                                onClick={() =>
                                  this.suggestCategorieshandler(item)
                                }
                              >
                                <input
                                  type="checkbox"
                                  className="absolute inset-0 z-0 invisible"
                                  id={item.id}
                                  checked={this.state.selectedCategories.find(
                                    (data) => data.id === item.id
                                  )}
                                />
                                <label
                                  htmlFor={item.name}
                                  className="bg-gray-200 hover:opacity-70 rounded-lg px-4 py-1.5 cursor-pointer inline-block"
                                >
                                  <p className="fs-13">{item.name}</p>
                                </label>
                              </div>
                            ))
                          )} */}
                      </div>
                    </div>
                  )}
                <div className="p-5 overflow-hidden box intro-y">
                  <div className="">
                    {menu && menu.id === "4" ? (
                      false
                    ) : (
                      <>
                        <div className="grid grid-cols-1 gap-5 mb-3">
                          <Buttonlink
                            onClick={this.choseCategory}
                            className={`bg-gray-100 intro-y  ${categoryErr ? "border-red-500 border" : ""
                              } hover:bg-gray-200 w-full primary flex items-center justify-center py-3 rounded-xl`}
                            text="Choose Sourcing Genie Category"
                          />
                          <ChooseRFQCategories
                            inPage={this.props.editable}
                            maxValue={maxCategories}
                          />
                        </div>
                      </>
                    )}
                    {menu?.id === "4" && (
                      <div className="flex flex-wrap items-center gap-3 mb-3">
                        <div className="relative intro-x">
                          <label className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 flex items-center gap-x-2">
                            <p className="fs-13">General</p>
                          </label>
                        </div>
                        {this.state.selectedCategories.map(
                          (category) =>
                            category.name !== "General" && (
                              <div
                                className="relative intro-x"
                                key={category.id}
                              >
                                <label className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 flex items-center gap-x-2">
                                  <p className="fs-13">{category.name}</p>
                                  <div className="cursor-pointer">
                                    <div>
                                      <IoIosCloseCircle
                                        onClick={() => {
                                          let updatedCat =
                                            this.state.selectedCategories.filter(
                                              (data) => data.id !== category.id
                                            );

                                          this.setState({
                                            selectedCategories: updatedCat,
                                          });
                                        }}
                                        size={20}
                                        className="primary"
                                      />
                                    </div>
                                  </div>
                                </label>
                              </div>
                            )
                        )}
                      </div>
                    )}
                    <div className="space-y-5">
                      {!showTagsModal &&
                        menu.id !== "7" &&
                        this.props.preffered_vendors?.length > 0 && (
                          <>
                            <div className="mb-5 divider intro-y important:mt-0" />
                            <div className={"flex justify-between"}>
                              <p className={"text-xl Medium pt-2"}>
                                Share With Preferred Vendor
                              </p>
                              <Switch
                                {...label}
                                color="warning"
                                checked={this.state.preferredVendor}
                                onChange={() => {
                                  if (canSubmitPreferedVendors) {
                                    this.setState({
                                      preferredVendor:
                                        !this.state.preferredVendor,
                                    });
                                  } else {
                                    this.notify(
                                      "You can't share with Preferred vendors with current plan, Please Upgrade your plan",
                                      "error"
                                    );
                                  }
                                }}
                              />
                            </div>
                          </>
                        )}
                      {showTagsModal && (
                        <>
                          <div className="mb-5 divider intro-y important:mt-0" />
                          <Buttonlink
                            onClick={() => this.choseTags()}
                            className="flex items-center justify-center w-full py-3 bg-gray-100 intro-y hover:bg-gray-200 primary rounded-xl"
                            text="Choose Industrial Tags"
                          />
                        </>
                      )}
                      <div className="flex flex-wrap gap-x-3">
                        {showTagsModal &&
                          fields?.tags?.map((tag) => (
                            <div className="relative mb-4 intro-x">
                              <label className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                <p className="fs-13">{tag.name}</p>
                                <CgCloseO
                                  onClick={() => {
                                    const { fields } = this.props;
                                    const updatedTags = fields?.tags.filter(
                                      (data) => data.id !== tag.id
                                    );

                                    // Create a copy of the this.props.fields object and update the tags property
                                    const updatedFields = {
                                      ...fields,
                                      tags: updatedTags,
                                    };

                                    // Call selectTags and addRfqFields with the updatedFields object
                                    this.props.selectTags(updatedFields);
                                    this.props.addRfqFields(updatedFields);
                                  }}
                                  className="primary"
                                />
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="mb-6 divider intro-y" />
                    <h3 className="text-xl Medium intro-x">
                      {showTagsModal ||
                        this.state.nextClicked ||
                        this.props?.editable
                        ? "Selected Team"
                        : "Select Your Team"}
                    </h3>
                    {showTagsModal ? (
                      <p className="darkGray Light fs-13 intro-x">
                        Your team will be able to see changes.
                      </p>
                    ) : this.state.nextClicked ||
                      this.props?.editable ? null : ( // Don't display anything if nextClicked is true and showTagsModal is false
                      <p className="darkGray Light fs-13 intro-x">
                        Select wisely, you can't edit your team later.
                      </p>
                    )}

                    <div className="h-5" />
                    <div className="space-y-5">
                      <Myteams
                        from="post-rfq"
                        // Screen was loading twice because of it... dont know or cant see its sideEffects just yet
                        //editable={this.props?.editable}
                        edit={this.props?.editable}
                        nextClicked={this.state.nextClicked}
                        company={this.props?.data?.company}
                        loader={(v) => v && this.getMenu()}
                        menu={menu}
                        id={1}
                        offer_id={
                          this.props.data.id ?? this.state.createdOfferID
                        }
                        getAlert={(v) => this.notify(v, "success")}
                        offerHasRoles={(v) =>
                          this.setState({ offerHasRoles: v })
                        }
                        //this.props.data.executives
                        // executives={executives}
                        executives={this.props.data.executives}
                        showTagsModal={showTagsModal}
                        companyErr={companyErr}
                        companyLoader={(v, x) => {
                          this.companyChangeLoader(v, x);
                        }}
                        load_again={(v) => {
                          switch (v) {
                            case "delete":
                              this.notify(
                                "Team had been removed successfully",
                                "success"
                              );
                              break;
                            case "switch":
                              this.notify("Team has been switched", "success");
                              break;
                            default:
                              this.notify("Unknown Status", "error");
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Fullscreenpopup
            ref={this.SelectFullPopup}
            type={modalType}
            uom={fields?.uom}
            categories={form?.category}
            tags={fields?.tags}
            maxValue={maxCategories}
            from="createNewRfq"
            clearState={(value) => console.log("value", value)}
          />
        </div>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          scroll="body"
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          className="rounded-2xl"
        >
          <div>
            <div className="bg-gray-200 py-2 px-5 flex items-center justify-between">
              <h1 className="text-[17px] Medium">Select RFQ Post Platform</h1>
              <div
                className="bg-gray-300 hover:opacity-70 cursor-pointer rounded-full p-2"
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
              >
                <FiX className="primary" size={22} />
              </div>
            </div>
            <div className="p-6 space-y-5">
              <FormGroup className="bg-gray-100 rounded-lg p-0 overflow-hidden">
                <FormControlLabel
                  className="p-2 hover:bg-gray-200 !mr-0 !ml-0"
                  control={
                    <Checkbox
                      className="primary-checkbox"
                      value={this.state.sg_platform}
                      checked={this.state.sg_platform}
                      onClick={() =>
                        this.setState({ sg_platform: !sg_platform })
                      }
                    />
                  }
                  label="Sourcing Genie"
                />
                <div className="bg-gray-300 h-[0.5px] w-full" />
                <FormControlLabel
                  className="p-2 hover:bg-gray-200 !mr-0 !ml-0"
                  control={
                    <Checkbox
                      className="primary-checkbox"
                      value={this.state.other_platform}
                      checked={this.state.other_platform}
                      onClick={() => {
                        this.setState({ other_platform: !other_platform });
                        // if (multiplePostingPlatforms) {
                        //   this.setState({ other_platform: !other_platform });
                        // } else {
                        //   // this.setState({ notAllowedToast: false })
                        //   this.notify(
                        //     "You are not allowed to use this feature, Please upgrade your plan"
                        //   );
                        //   // setTimeout(() => {
                        //   //   this.setState({ notAllowedToast: true })
                        //   // }, 9000);
                        // }
                      }}
                    />
                  }
                  label="Procurement League"
                />
              </FormGroup>
              <div className="text-center">
                {/*{(this.state.other_platform && this.props.user?.authUserInformation?.has_pl_account) || this.state.sg_platform ?
                    <Buttonlink
                        onClick={this.handlepostRfq}
                        text="POST"
                        className="relative ml-auto w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
                    />:
                    <TransitionAlerts open={this.state.open}/>
                }*/}

                {this.state.other_platform || this.state.sg_platform ? (
                  this.state.other_platform &&
                    !this.props.user?.authUserInformation?.has_pl_account ? (
                    <TransitionAlerts open={this.state.open} />
                  ) : (
                    <Buttonlink
                      onClick={this.handlepostRfq}
                      text="POST"
                      className="relative ml-auto w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
                    />
                  )
                ) : (
                  <Buttonlink
                    onClick={this.handlepostRfq}
                    text="POST"
                    className="relative ml-auto w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
                  />
                )}
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          scroll="body"
          open={this.state.confirmation}
          onClose={() => this.setState({ confirmation: false })}
          className="rounded-2xl"
        >
          <div>
            <div className="bg-gray-200 py-2 px-5 flex items-center justify-between">
              <h1 className="text-[17px] Medium">
                Are you sure to continue with this file.
              </h1>
              <div
                className="bg-gray-300 hover:opacity-70 cursor-pointer rounded-full p-2"
                onClick={() =>
                  this.setState({ confirmation: !this.state.confirmation })
                }
              >
                <FiX className="primary" size={22} />
              </div>
            </div>
            <div className="p-6 space-x-5 flex justify-center items-center">
              <Buttonlink
                onClick={() =>
                  this.setState({ confirmation: !this.state.confirmation })
                }
                text="No"
                className="relative w-20   hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
              />
              <Buttonlink
                onClick={this.handleFileUpload}
                text="Yes"
                className="relative w-20   hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary hover:bg--primary focus:outline-none"
              />
            </div>
          </div>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ Rfq, Auth, RFQs, Page }) => {
  return {
    form: Rfq.form,
    user: Auth,
    fields: Rfq.fields,
    categories: RFQs.allCategories,
    showCurrentPage: Page.url,
    preffered_vendors: RFQs.prefferedVendors,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    clearForm: () => {
      dispatch(actions.clearForm());
    },
    // handleUpdateAuthUserKeyValue: (value) => {
    //   dispatch(actions.handleUpdateAuthUserKeyValue(value))
    // },
    addRfqFields: (data) => {
      dispatch(actions.addRfqFields(data));
    },
    clearFields: () => {
      dispatch(actions.clearFields());
    },
    selectTags: (data) => {
      dispatch(actions.selectTags(data));
    },
    selectCategories: (data) => {
      dispatch(actions.selectCategories(data));
    },
  };
};

export default connect(mapStateToProps, undefined, mergeProps)(NewRFQ);
