import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Loader } from "../../../../components";
import moment from "moment";

const SpendAnalytics = ({ queryType, dateRange, my_price, copiedSearch }) => {
	// console.log("SpendAnalytics", dateRange);

	const capitalizeFirstWord = (str) => {
		if (str.length === 0) {
			return str; // Return an empty string if the input is empty
		}

		const words = str.split(" "); // Split the string into an array of words
		words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1); // Capitalize the first word
		return words.join(" "); // Join the words back into a string
	};

	const capitalizedString = capitalizeFirstWord(queryType);

	const GET_CATEGORIES_PERCENT = gql`
		query GetCategoriesPercent(
			$queryType: String!
			$priceFrom: String!
			$priceTo: String!
			$title: String
			$dateFrom: Date!
			$dateTo: Date!
		) {
			getCategoriesPercent(
				querytype: $queryType
				pricefrom: $priceFrom
				priceto: $priceTo
				datefrom: $dateFrom
				dateto: $dateTo
				title: $title
			) {
				name
				total
				percentage
			}
		}
	`;

	const { loading, error, data } = useQuery(GET_CATEGORIES_PERCENT, {
		variables: {
			queryType,
			priceFrom: my_price[0],
			priceTo: my_price[1],
			dateFrom: dateRange[0],
			dateTo: dateRange[1],
			title: copiedSearch,
		},
	});

	if (loading) return <Loader />;

	if (error) {
		console.error(error); // Handle error state if needed
		return <p>{error.message}</p>;
	}

	return (
		<div className="">
			<div className="py-14 px-5 sm:px-20 space-y-3 bg-white">
				<h6 className="font-bold text-center">{capitalizedString}</h6>
				{data &&
					(data?.getCategoriesPercent || []).map((category) => (
						<div
							key={category.name}
							className="flex justify-between items-center border-b border-lightGray pb-2"
						>
							<div className="flex flex-col justify-center items-center gap-2">
								<p className="font-semibold">Username</p>
								<p className="Regular">{category.name}</p>
							</div>
							{capitalizedString === "Suppliers" && (
								<div className="flex flex-col justify-center items-center gap-2">
									<p className="font-semibold">Amount</p>
									<p className="Regular">${category.total}</p>
								</div>
							)}

							<div className="flex flex-col justify-center items-center gap-2">
								<p className="font-semibold">Percentage</p>
								<p className="Medium font-bold flex items-center">
									{Math.round(category.percentage).toFixed(2)}%
								</p>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};

export default SpendAnalytics;
