export const IndustryTags = ({ rfqDetail }) => {
	const tags = rfqDetail?.tags ? Array.from(new Set(rfqDetail.tags)) : [];

	return (
		<div className="p-5 mt-5 box overflow-hidden space-y-2">
			<h3 className="mb-2 text-xl Medium">Industry Tags:</h3>
			<div className="flex flex-wrap items-center gap-2 selected-tags">
				{(tags || []).map(({ id, name }) => (
					<label className="bg-gray-200 hover:opacity-70 rounded-lg px-5 py-1.5 inline-block" key={id}>
						<p className="fs-13">{name}</p>
					</label>
				))}
			</div>
		</div>
	);
};
