import React, { Component } from "react";
import { Buttonlink, Loader, RFQSignaturePopup } from "components";
import { IoIosArrowBack } from "react-icons/io";
import { Dialog, IconButton, TextareaAutosize } from "@mui/material";
import { FcDocument } from "react-icons/fc";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import amountFormat from "components/amountFormat/amountFormat";
import axios from "axios";
import { connect } from "react-redux";
import cookie from "js-cookie";
import client from "graphql/client";
import { GRAPH_MRO_USER_AUTH_TOKEN } from "../../../config/constants";
import createOfferParticipantMutation from "../../../graphql/mutations/createOfferParticipantMutation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Switch } from "@headlessui/react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateRangePicker } from "@mui/lab";
import { extractValidationErrors } from "helpers/extractValidationErrors ";
import { FaFileCsv } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { IoCloseCircleOutline } from "react-icons/io5";
import ImageDisplay from "components/Imagedisplay";

class RFQquote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      grossTotal: 0,
      tenderedAmount: true,
      submitted: false,
      price: "",
      tenderedPrice: "",
      comment: "",
      file_url: null,
      loading: false,
      isRoute: false,
      id: props.rfq.id,
      revisedDate: "",
      enabled: true,
      productQuote:
        props.rfq?.products?.length !== 0
          ? props.rfq?.products?.map((product) => ({
            id: product.id,
            price: "",
            lastDate: product.last_date,
            enabled: true,
            deliveryDate: "",
          }))
          : [],
      remainingProducts: [],
      revisionProducts: [],
      // props.rfq?.participants?.data.length === 0
      //   ? true
      //   : props.rfq?.participants?.data.filter(
      //       (o) => o.user_id === props.authUser?.id
      //     ).length === 0
      //   ? true
      //   : props.rfq?.participants?.data.filter(
      //       (o) => o.user_id === props.authUser?.id
      //     )[0]?.expected_date === null,
      deliveryDate:
        props.rfq?.participants?.data.filter(
          (o) => o.user_id === props.authUser?.id
        )[0]?.expected_date === null
          ? props.rfq?.last_date
          : props.rfq?.participants?.data.filter(
            (o) => o.user_id === props.authUser?.id
          )[0]?.expected_date,
      file: null,
      files: [],
      productFile: null,
      isBlured: props?.rfq?.offerSignature == "false" ? true : false,
      isBluredPop: props?.rfq?.offerSignature == "false" ? true : false,
      isDocu: false,
    };
    this.photoRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.rfq.id !== this.state.id) {
      this.setState({ id: prevProps.rfq.id });
    }
  }
  componentDidMount() {
    // if (this.props?.rfq?.offerSignature == 0) {
    //   this.setState({ isBluredPop: true, isBlured: true });
    // }

    if (this.props.rfq?.participant?.participant_files?.length !== 0) {
      this.setState({ files: this.props.rfq?.participant?.participant_files });
    }
    const filteredProducts = this.props.rfq?.products?.filter((product) => {
      return this.props.rfq?.participants?.data.some((participant) => {
        return (
          participant.user.id === this.props.authUser?.id &&
          participant.product_id == product.id && // Ensure product_id and id are comparable
          participant.isRevision
        );
      });
    });
    this.setState({ revisionProducts: filteredProducts });
    if (this.props?.isRevision) {
      this.setState({
        productQuote:
          filteredProducts?.length !== 0
            ? filteredProducts?.map((product) => ({
              id: product.id,
              price: "",
              lastDate: product.last_date,
              enabled: true,
              deliveryDate: "",
            }))
            : [],
      });
    }

    const filterParti = this.props.rfq?.participants?.data?.filter(
      (dat) => parseInt(dat?.user?.id) === parseInt(this.props.authUser?.id)
    );
    const filterPro = this.props.rfq?.products?.filter((pro) => {
      return filterParti?.every(
        (par) => parseInt(par?.product_id) !== parseInt(pro?.id)
      );
    });
    this.setState({ remainingProducts: filterPro });
    if (this.props?.submitAgain) {
      this.setState({
        productQuote:
          filterPro?.length !== 0
            ? filterPro?.map((product) => ({
              id: product.id,
              price: "",
              lastDate: product.last_date,
              enabled: true,
              deliveryDate: "",
            }))
            : [],
      });
    }
  }

  onPhotoClick = () => {
    this.photoRef.current.click();
  };

  quotePrice = (price) => {
    let amount = parseFloat(price.replace(/,/g, ""));
    let quantity = parseFloat(
      this.props.rfq.quantity.toString().replace(/,/g, "")
    );
    let total = (amount * quantity).toFixed(2);

    if (!isNaN(total)) {
      this.setState({
        total: total,
        grossTotal: amountFormat(total),
        tenderedAmount: true,
        price: price,
      });
    } else {
      this.setState({
        price: "",
      });
    }
  };

  fileUpload = (e) => {
    const file = e.target.files[0];
    const size = e.target.files[0].size;
    if (size > 10e6) {
      this.notify("Please upload a file smaller than 10 MB!", "error");
      return;
    }

    let token = cookie.get(GRAPH_MRO_USER_AUTH_TOKEN);

    axios({
      method: "POST",
      url: process.env.REACT_APP_MAIN_URL + "/api/s3upload",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: this.createFormData(
        file,
        { offer_id: parseInt(this.props.rfq.id) },
        {
          type: "document",
          id: this.props.authUser.id,
        }
      ),
    })
      .then((res) => {
        // console.log("Upload File Response: ", res);
        this.setState({ file_url: res.data.file });
      })
      .catch((err) => {
        console.log("Upload File Error: ", err);
      });
  };
  createFormData = (photo, body) => {
    const data = new FormData();
    data.append("file", photo);
    Object.keys(body).forEach((key) => {
      data.append(key, body[key]);
    });

    return data;
  };

  handleTenderAmount = (value) => {
    if (!isNaN(value)) {
      this.setState({
        tenderedPrice: value,
      });
    } else {
      this.setState({
        tenderedPrice: "",
      });
    }
    let amount = parseFloat(value.toString().replace(/,/g, ""));
    if (
      (amount === parseFloat(this.state.price) ||
        amount === parseFloat(this.state.total)) &&
      amount !== 0
    ) {
      this.setState({ tenderedAmount: false });
    } else {
      this.setState({ tenderedAmount: true });
    }
  };

  submitQuotation = () => {
    const { price, comment, file_url, tenderedAmount, enabled, deliveryDate } =
      this.state;
    let setDeliveryDate = deliveryDate;
    if (this.state.submitted) {
      return;
    }
    if (this.props.rfq.products?.length > 0) {
      const { productQuote } = this.state;
      const { rfq } = this.props;
      const hasValidProduct = productQuote?.map((product) => {
        if (product?.price !== "") {
          if (!product.enabled && product.deliveryDate === "") {
            this.notify("please enter valid deadline");
            return;
          }
          return true;
        }
        return false;
      });

      if (!hasValidProduct) {
        this.notify(
          "Please fill in at least one product with valid price and delivery date!",
          "error"
        );
        return;
      }
    }

    if (!price) {
      this.notify("Quote price is required!", "error");
    } else if (tenderedAmount) {
      this.notify("Enter valid total amount to verify!", "error");
    } else {
      const parsedDeliveryDate = new Date(deliveryDate); // Convert the string to a Date object

      if (!isNaN(parsedDeliveryDate)) {
        setDeliveryDate = `${parsedDeliveryDate.getFullYear()}-${(
          parsedDeliveryDate.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${parsedDeliveryDate
            .getDate()
            .toString()
            .padStart(2, "0")} ${parsedDeliveryDate
              .getHours()
              .toString()
              .padStart(2, "0")}:${parsedDeliveryDate
                .getMinutes()
                .toString()
                .padStart(2, "0")}:${parsedDeliveryDate
                  .getSeconds()
                  .toString()
                  .padStart(2, "0")}`;
      }
      const validProducts = this.state.productQuote.filter(
        (product) => product?.price !== ""
      );
      let res = true;
      if (res) {
        this.setState({ loading: true, submitted: true });
        client
          .mutate({
            mutation: createOfferParticipantMutation,
            variables: {
              offer_id: parseInt(this.props.rfq.id),
              amount: price,
              comment: comment,
              again: this.props.submitAgain ?? false,
              products: validProducts,
              document: file_url,
              expected_date: enabled === false ? setDeliveryDate : null,
            },
          })
          .then((res) => {
            if (this.props.socket?.connected) {
              this.props?.socket?.emit("notify", "Notification data");
            }
            if (res.data.createOfferParticipant.id) {
              // console.log("Submit Quotation Success Res", res);

              this.setState(
                {
                  price: "",
                  tenderedPrice: "",
                  comment: "",
                  file_url: "",
                  loading: false,
                  tenderedAmount: false,
                  isRoute: true,
                },
                () => {
                  this.notify(
                    "RFQ Quote Submitted! Please wait for the buyer review.",
                    "success"
                  );
                }
              );
            }
          })
          .catch((err) => {
            console.log("Submit Quotation Error", err);
            this.setState(
              {
                price: "",
                comment: "",
                file_url: "",
                loading: false,
                tenderedAmount: false,
                isRoute: true,
              },
              () => {
                extractValidationErrors(err).forEach((err) =>
                  this.notify(err, "error")
                );
                // this.notify(
                //   "RFQ Quote not Submitted! Please try again.",
                //   "error"
                // );
              }
            );
          });
      }
    }
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };

  dateChangeHandler = (date) => {
    let last_date = moment(date).format("YYYY-MM-DD");
    this.setState({ deliveryDate: date });
  };

  handlePriceChange = (index, value, quantity) => {
    const newProductQuote = [...this.state.productQuote];
    newProductQuote[index].price = value;

    const total = newProductQuote.reduce((sum, product) => {
      const price = parseFloat(product.price * quantity);
      return sum + (isNaN(price) ? 0 : price);
    }, 0);

    this.setState({
      productQuote: newProductQuote,
      price: total,
      grossTotal: amountFormat(total),
    });
  };
  handleDateChange = (index, value) => {
    const newProductQuote = [...this.state.productQuote];
    newProductQuote[index].lastDate = value;
    this.setState({ productQuote: newProductQuote });
  };

  handleSwitchChange = (index) => {
    const newProductQuote = [...this.state.productQuote];
    newProductQuote[index].enabled = !newProductQuote[index].enabled;
    this.setState({ productQuote: newProductQuote });
  };

  dateChangeHandler = (index, value) => {
    const newProductQuote = [...this.state.productQuote];
    newProductQuote[index].deliveryDate = value;
    this.setState({ productQuote: newProductQuote });
  };
  handleDeleteImage = (index) => {
    this.setState((prevState) => ({
      files: prevState?.files.filter((_, i) => i !== index),
    }));
  };
  handleChangeFile = async (event) => {
    const selectedFile = event?.target?.files[0];

    const fileSizeLimit = 5 * 1024 * 1024;
    if (selectedFile.size > fileSizeLimit) {
      this.notify(
        `File ${selectedFile.name} exceeds the size limit (5MB) and will not be uploaded.`,
        "error"
      );
      return false;
    }

    if (selectedFile.type === "application/zip") {
      this.notify(
        `File ${selectedFile.name} is a zip file and will not be uploaded.`,
        "error"
      );
      return false;
    }
    this.setState({ file: selectedFile });
    const fileList = event?.target?.files;
    if (fileList && fileList.length > 0) {
      if (this.state.files && this.state.files.length > 4) {
        this.notify("You can't upload more than 5 files", "error");
        return false;
      }
      this.setState((prevState) => ({
        files: Array.isArray(prevState.files)
          ? [...prevState.files, ...Array.from(fileList)]
          : Array.from(fileList),
      }));
    }
  };
  handleChangeProductFile = async (event) => {
    const selectedFile = event?.target?.files[0];

    const fileSizeLimit = 5 * 1024 * 1024;
    if (selectedFile.size > fileSizeLimit) {
      this.notify(
        `File ${selectedFile.name} exceeds the size limit (5MB) and will not be uploaded.`,
        "error"
      );
      return false;
    }

    if (selectedFile.type === "application/zip") {
      this.notify(
        `File ${selectedFile.name} is a zip file and will not be uploaded.`,
        "error"
      );
      return false;
    }

    this.setState({ productFile: selectedFile });
  };

  closeSignature = () => {
    this.setState({ isDocu: false, isBluredPop: false, isBlured: false });
  };
  render() {
    const {
      price,
      tenderedPrice,
      comment,
      grossTotal,
      isRoute,
      loading,
      enabled,
      product,
      productQuote,
    } = this.state;
    const { rfq, authUser } = this.props;
    let deliveryDate = this.state.deliveryDate;
    let lastDate = new Date(rfq?.last_date);
    lastDate = moment(lastDate).format("YYYY-MM-DD");
    deliveryDate = new Date(deliveryDate);
    deliveryDate = moment(deliveryDate).format("YYYY-MM-DD");

    if (isRoute) {
      this.props.navigate("/rfq/0", {
        state: {
          path: "quoteSubmitted",
        },
      });
    }

    const submitfileQuotation = async () => {
      // this.setState({ file: event?.target?.files[0] });
      if (this.state.files?.length == 0 || this.state.files == undefined) {
        this.notify("Please Upload Quotation File", "error");
        return;
      }
      const formData = new FormData();
      this.state.files?.forEach((file, index) => {
        if (file.name === undefined) {
          formData.append(`csv_file[${index}]`, file?.id);
        } else {
          formData.append(`csv_file[${index}]`, file);
        }
      });
      formData.append("offer_id", rfq?.id);
      formData.append("participant_id", rfq?.participant?.id ?? null);
      formData.append("comment", this.state.comment);
      const token = cookie.get(GRAPH_MRO_USER_AUTH_TOKEN);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/api/super-rfq-quotation`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          this.notify("File uploaded successfully", "success"); // Show success message
          if (this.props.socket?.connected) {
            this.props?.socket?.emit("notify", "Notification data");
          }

          this.setState(
            {
              price: "",
              tenderedPrice: "",
              comment: "",
              file_url: "",
              loading: false,
              tenderedAmount: false,
              isRoute: true,
            },
            () => {
              this.notify(
                "RFQ Quote Submitted! Please wait for the buyer review.",
                "success"
              );
            }
          );

          return true;
        }
      } catch (error) {
        this.notify("The given data was invalid");
        this.setState({
          price: "",
          comment: "",
          file_url: "",
          loading: false,
          tenderedAmount: false,
          isRoute: true,
        });
      }
    };
    const submitProductFileQuotation = async () => {
      // this.setState({ file: event?.target?.files[0] });

      const formData = new FormData();
      formData.append("csv_file", this.state.productFile);
      formData.append("offer_id", rfq?.id);
      formData.append("participant_id", rfq?.participant?.id ?? null);
      formData.append("comment", this.state.comment);
      const token = cookie.get(GRAPH_MRO_USER_AUTH_TOKEN);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/api/super-rfq-quotation_file`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          this.notify("File uploaded successfully", "success"); // Show success message
          if (this.props.socket?.connected) {
            this.props?.socket?.emit("notify", "Notification data");
          }

          this.setState(
            {
              price: "",
              tenderedPrice: "",
              comment: "",
              file_url: "",
              loading: false,
              tenderedAmount: false,
              isRoute: true,
            },
            () => {
              this.notify(
                "RFQ Quote Submitted! Please wait for the buyer review.",
                "success"
              );
            }
          );

          return true;
        }
      } catch (error) {
        this.notify("The given data was invalid");
        this.setState({
          price: "",
          comment: "",
          file_url: "",
          loading: false,
          tenderedAmount: false,
          isRoute: true,
        });
      }
    };
    const handleChangeFile = async (event) => {
      const selectedFile = event?.target?.files[0];

      const fileSizeLimit = 5 * 1024 * 1024;
      if (selectedFile.size > fileSizeLimit) {
        this.notify(
          `File ${selectedFile.name} exceeds the size limit (5MB) and will not be uploaded.`,
          "error"
        );
        return false;
      }

      if (selectedFile.type === "application/zip") {
        this.notify(
          `File ${selectedFile.name} is a zip file and will not be uploaded.`,
          "error"
        );
        return false;
      }

      this.setState({ file: selectedFile });
      const fileList = event?.target?.files;
      if (fileList && fileList.length > 0) {
        this.setState((prevState) => ({
          files: Array.isArray(prevState.files)
            ? [...prevState.files, ...Array.from(fileList)]
            : Array.from(fileList),
        }));
      }
    };
    return (
      <div className="container mt-10">
        <div className="mx-auto">
          <div className="grid lg:grid-cols-6 gap-4">
            <div
              className={`lg:col-start-2 lg:col-span-4 ${rfq?.offerSignature !== "false" || !this.state.isBlured
                ? ""
                : "blur select-none cursor-no-drop"
                } bg-white rounded-xl p-10`}
            >
              <div className="relative mb-10">
                <Buttonlink
                  text="Back"
                  prefix={<IoIosArrowBack size={16} />}
                  onClick={() => this.props.navigate(-1)}
                  className="bg-gray-200 hover:bg-gray-300 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 hover:opacity-80 border-0 shadow-none mb-5"
                />
                <h1 className="Medium text-4xl text-center">RFQ Quote</h1>
              </div>
              {rfq?.product_file_path !== null &&
                !this.props.isRivision &&
                !this.props.submitAgain && (
                  <div className="flex justify-end mb-10 items-center gap-x-4">
                    <label
                      htmlFor="product_file"
                      className="flex intro-x items-center gap-x-3 cursor-pointer"
                    >
                      <input
                        accept=".csv"
                        onChange={this.handleChangeProductFile}
                        type="file"
                        name="product_file"
                        className="hidden"
                        id="product_file"
                      />
                      <FaFileCsv className="primary" size={20} />{" "}
                      <p className="fs-14">Set quote by file</p>
                    </label>

                    <a
                      download
                      href={`${process.env.REACT_APP_MAIN_URL}/${rfq?.product_file_path}`}
                      className={`primary bg--lightPrimary intro-x hover:bg--primary relative hover:white hover:opacity-80 flex justify-center fs-13 py-1 px-4 border border-transparent rounded-lg focus:outline-none`}
                    >
                      Download sample
                    </a>
                  </div>
                )}
              {this.state.productFile !== null ? (
                <>
                  <div className="flex justify-end">
                    {this.state.file !== null && (
                      <label
                        htmlFor="product_file"
                        className="flex items-center gap-x-3 cursor-pointer"
                      >
                        <input
                          onChange={this.handleChangeProductFile}
                          type="file"
                          name="product_file"
                          className="hidden"
                          id="product_file"
                        />
                        <FaCirclePlus className="primary" size={24} />
                        <p className="text-base">Add Additional Files</p>
                      </label>
                    )}
                  </div>

                  <div>
                    <p className="text-base Regular pb-2">Comment</p>
                    <TextareaAutosize
                      minRows={3}
                      maxLength={300}
                      placeholder="optional..."
                      className="appearance-none rounded-md relative block md:w-2/3 px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm resize-none"
                      name="description"
                      value={comment}
                      onChange={(e) =>
                        this.setState({ comment: e.target.value })
                      }
                    />
                  </div>
                  <Buttonlink
                    className="mt-10 w-full max-w-[150px] text-white hover:text-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 h-11 rounded--xl border-0 shadow-none w-full"
                    prefix={loading ? <Loader spinner={true} /> : false}
                    text={loading ? "" : "Submit"}
                    onClick={submitProductFileQuotation}
                    disabled={this.state.submitted}
                  />
                </>
              ) : rfq?.uom === null && rfq.products?.length === 0 ? (
                <>
                  <div className="flex justify-start mb-10 items-center gap-x-4">
                    <p className="mb-5 font-medium text-lg">
                      <span className="text-white font-semibold bg-red-600 px-3 py-1">
                        Note
                      </span>{" "}
                      : Submit Quotation by Downloading RFQ file, Add Quotation
                      Price there and upload
                    </p>
                    <label
                      htmlFor="product_file"
                      className="flex intro-x items-center gap-x-3 cursor-pointer"
                    >
                      <input
                        onChange={this.handleChangeFile}
                        type="file"
                        name="product_file"
                        className="hidden"
                        id="product_file"
                      />
                      <FaFileCsv className="primary" size={24} />{" "}
                      <p className="text-base">
                        Send quotation by file (upload)
                      </p>
                    </label>
                  </div>
                  <div className="flex flex-row space-x-4">
                    {this.state.files !== null
                      ? this.state.files?.map((file, index) => (
                        <div key={index} className="relative flex flex-col">
                          <FcDocument className="h-24 w-24" />
                          <button
                            onClick={() => this.handleDeleteImage(index)}
                            className="absolute top-1 right-1 bg-red-500 text-white px-2 py-1 text-xs rounded-full hover:bg-red-700"
                          >
                            X
                          </button>
                          <p className="mt-1 text-center truncate">
                            {file?.path === undefined
                              ? file.name?.slice(0, 10) + "..."
                              : file?.path?.split("/")[2]?.slice(0, 10) +
                              "..."}
                          </p>
                        </div>
                      ))
                      : null}
                  </div>
                  <h2 className="pt-3">Buyer Attached Files :</h2>
                  <div className="flex justify-start mt-5 mb-10 items-center gap-x-4">
                    {rfq.files?.length > 0 &&
                      rfq.files?.map((file, index) => (
                        // <a
                        //   download
                        //   href={`${process.env.REACT_APP_MAIN_URL}/${file.path}`}
                        //   className={`primary bg--lightPrimary intro-x hover:bg--primary relative hover:white hover:opacity-80 flex justify-center fs-13 py-1 px-4 border border-transparent rounded-lg focus:outline-none`}
                        // >
                        //   Download RFQ file
                        // </a>
                        <ImageDisplay
                          key={index}
                          attachment={file}
                          index={index}
                          altText={'none'}
                          notify={this.notify}
                        />
                      ))}
                  </div>
                  <div className="flex justify-end">
                    {this.state.file !== null && (
                      <label
                        htmlFor="product_file"
                        className="flex items-center gap-x-3 cursor-pointer"
                      >
                        <input
                          onChange={this.handleChangeFile}
                          type="file"
                          name="product_file"
                          className="hidden"
                          id="product_file"
                        />
                        <FaCirclePlus className="primary" size={24} />
                        <p className="text-base">Add Additional Files</p>
                      </label>
                    )}
                  </div>

                  <div>
                    <p className="text-base Regular pb-2">Comment</p>
                    <TextareaAutosize
                      minRows={3}
                      maxLength={300}
                      placeholder="optional..."
                      className="appearance-none rounded-md relative block md:w-2/3 px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm resize-none"
                      name="description"
                      value={comment}
                      onChange={(e) =>
                        this.setState({ comment: e.target.value })
                      }
                    />
                  </div>
                  <Buttonlink
                    className="mt-10 w-full max-w-[150px] text-white hover:text-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 h-11 rounded--xl border-0 shadow-none "
                    prefix={loading ? <Loader spinner={true} /> : false}
                    text={loading ? "" : "Submit"}
                    onClick={submitfileQuotation}
                    disabled={this.state.submitted}
                  />
                </>
              ) : (
                <>
                  {
                    <div className="mb-3 space-y-5">
                      {!rfq.products?.length > 0 ? (
                        <>
                          {rfq?.product_file_path !== null &&
                            !this.props.isRivision &&
                            !this.props.submitAgain && (
                              <div className="flex justify-end mb-10 items-center gap-x-4">
                                <label
                                  htmlFor="product_file"
                                  className="flex intro-x items-center gap-x-3 cursor-pointer"
                                >
                                  <input
                                    accept=".csv"
                                    onChange={this.handleChangeFile}
                                    type="file"
                                    name="product_file"
                                    className="hidden"
                                    id="product_file"
                                  />
                                  <FaFileCsv className="primary" size={20} />{" "}
                                  <p className="fs-14">Set quote by file</p>
                                </label>
                                <a
                                  download
                                  href={`${process.env.REACT_APP_MAIN_URL}/${rfq?.product_file_path}`}
                                  className={`primary bg--lightPrimary intro-x hover:bg--primary relative hover:white hover:opacity-80 flex justify-center fs-13 py-1 px-4 border border-transparent rounded-lg focus:outline-none`}
                                >
                                  Download sample
                                </a>
                              </div>
                            )}
                          <div className={"flex items-center justify-between"}>
                            <p className={"Medium text-[18px] capitalize"}>
                              {rfq?.title}
                            </p>
                            <p className="Medium">
                              Required {rfq.uom ?? "quantity"}: {rfq.quantity}
                            </p>
                          </div>
                          <div>
                            <p className="flex items-center text-[13px] text-gray-600">
                              Quote Price (<HiOutlineCurrencyDollar />)
                            </p>
                            <div className="xs:flex items-center gap-x-2">
                              <input
                                type="text"
                                placeholder="Enter price"
                                maxLength={4}
                                className="appearance-none rounded-md relative md:w-2/3 block px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                                name="price"
                                value={price}
                                onChange={(e) => {
                                  const input = e.target.value;
                                  const numberRegex = /^\d*\.?\d*$/; // Regular expression to match float numbers

                                  if (
                                    input.length === 0 ||
                                    numberRegex.test(input)
                                  ) {
                                    this.quotePrice(input);
                                  }
                                }}
                              />

                              <p className="flex items-center gap-x-2 whitespace-nowrap  text-[13px] text-gray-600">
                                per {rfq.uom ?? "quantity"}
                              </p>
                            </div>
                          </div>

                          <div className="space-y-1">
                            <p className="flex items-center Medium">
                              Can you meet the deadline of {lastDate}
                            </p>
                            <div className="py-2 flex items-center gap-x-3">
                              <p>Agreed</p>
                              <Switch
                                checked={enabled}
                                onChange={() =>
                                  this.setState({ enabled: !enabled })
                                }
                                className={`${enabled ? "bg--primary" : "bg-gray-400"
                                  }
                relative inline-flex h-[24px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                              >
                                <span
                                  aria-hidden="true"
                                  className={`${enabled ? "translate-x-9" : "translate-x-0"
                                    }
                  pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
                              </Switch>

                              {!enabled && (
                                <>
                                  <div className="shadow-sm rounded-lg items-center gap-3 ledger-datepicker flex">
                                    <p className={"fs-12"}>
                                      Expected Delivery Date
                                    </p>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <div className="shadow-sm lg:col-span-6 md:col-span-6 col-span-12 rounded-lg flex items-center h-11 px-3 border border-gray-300">
                                        <input
                                          type="date"
                                          min={lastDate}
                                          value={deliveryDate}
                                          // max={moment()
                                          //   .add(2, "months")
                                          //   .format("YYYY-MM-DD")}
                                          onChange={(event) =>
                                            this.dateChangeHandler(
                                              event.target.value
                                            )
                                          }
                                          renderInput={(params) => (
                                            <TextField {...params} />
                                          )}
                                          placeholder="End date"
                                          className="bg-transparent h-full w-full pl-3"
                                        />
                                      </div>
                                    </LocalizationProvider>
                                  </div>
                                </>
                              )}
                            </div>
                            {!enabled && (
                              <div className="w-3/4 text-right">
                                <p className="text-red-500">
                                  Changing date may affect your request
                                </p>
                              </div>
                            )}
                          </div>
                        </>
                      ) : rfq.products?.length > 0 && this.props.isRivision ? (
                        <>
                          {this.state.revisionProducts?.map(
                            (product, index) => (
                              <div key={product.id} className="border-b pb-4">
                                <div className="flex items-center justify-between">
                                  <p className="Medium !text-lg primary capitalize">
                                    {product?.title}
                                  </p>
                                  <p className="Medium text-base">
                                    Required {product.uom ?? "quantity"} :{" "}
                                    {product?.quantity}
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <p className="flex items-center text-base text-gray-600">
                                    Quote Price (<HiOutlineCurrencyDollar />)
                                  </p>
                                  <div className="flex items-center mt-2 gap-x-2">
                                    <input
                                      type="text"
                                      placeholder="Enter price"
                                      maxLength={4}
                                      className="appearance-none rounded-md relative md:w-2/3 block px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                                      name="price"
                                      value={productQuote[index].price}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numberRegex = /^\d*\.?\d*$/;

                                        if (
                                          input.length === 0 ||
                                          numberRegex.test(input)
                                        ) {
                                          this.handlePriceChange(
                                            index,
                                            input,
                                            product.quantity
                                          );
                                        }
                                      }}
                                    />

                                    <p className="whitespace-nowrap text-base text-gray-600">
                                      Per {product.uom ?? "quantity"}
                                    </p>
                                  </div>
                                </div>

                                <div className="space-y-1 mt-3">
                                  <p className="text-base Medium">
                                    Can you meet the deadline of{" "}
                                    {product.last_date} ?
                                  </p>
                                  <div className="py-2 flex items-center gap-x-3">
                                    <p className="text-base Medium">Agreed</p>
                                    <Switch
                                      checked={productQuote[index].enabled}
                                      onChange={() =>
                                        this.handleSwitchChange(index)
                                      }
                                      className={`${productQuote[index].enabled
                                        ? "bg--primary"
                                        : "bg-gray-400"
                                        } relative inline-flex h-[24px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={`${productQuote[index].enabled
                                          ? "translate-x-9"
                                          : "translate-x-0"
                                          } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                      />
                                    </Switch>
                                  </div>
                                  {!productQuote[index].enabled && (
                                    <>
                                      <div className="rounded-lg gap-3 ledger-datepicker w-full max-w-sm flex flex-col">
                                        <p className="text-base Regular">
                                          Expected Delivery Date
                                        </p>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <div className="shadow-sm rounded-lg flex items-center h-11 px-3 border border-gray-300">
                                            <input
                                              type="date"
                                              min={productQuote[index].lastDate}
                                              value={
                                                productQuote[index].deliveryDate
                                              }
                                              onChange={(event) =>
                                                this.dateChangeHandler(
                                                  index,
                                                  event.target.value
                                                )
                                              }
                                              placeholder="End date"
                                              className="bg-transparent h-full w-full pl-3"
                                            />
                                          </div>
                                        </LocalizationProvider>
                                      </div>
                                    </>
                                  )}
                                  {!productQuote[index].enabled && (
                                    <div>
                                      <p className="danger text-base">
                                        Changing date may affect your request
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : rfq.products?.length > 0 && this.props.submitAgain ? (
                        <>
                          {this.state.remainingProducts?.map(
                            (product, index) => (
                              <div key={product.id} className="border-b pb-4">
                                <div className="flex items-center justify-between">
                                  <p className="Medium !text-lg primary capitalize">
                                    {product?.title}
                                  </p>
                                  <p className="Medium text-base">
                                    Required {product.uom ?? "quantity"} :{" "}
                                    {product.quantity}
                                  </p>
                                </div>
                                <div className="mt-3">
                                  <p className="flex items-center text-base text-gray-600">
                                    Quote Price (<HiOutlineCurrencyDollar />)
                                  </p>
                                  <div className="flex items-center mt-2 gap-x-2">
                                    <input
                                      type="text"
                                      placeholder="Enter price"
                                      maxLength={4}
                                      className="appearance-none rounded-md relative md:w-2/3 block px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                                      name="price"
                                      value={productQuote[index].price}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        const numberRegex = /^\d*\.?\d*$/;

                                        if (
                                          input.length === 0 ||
                                          numberRegex.test(input)
                                        ) {
                                          this.handlePriceChange(
                                            index,
                                            input,
                                            product.quantity
                                          );
                                        }
                                      }}
                                    />

                                    <p className="whitespace-nowrap text-base text-gray-600">
                                      Per {product.uom ?? "quantity"}
                                    </p>
                                  </div>
                                </div>

                                <div className="space-y-1 mt-3">
                                  <p className="text-base Medium">
                                    Can you meet the deadline of{" "}
                                    {product.last_date} ?
                                  </p>
                                  <div className="py-2 flex items-center gap-x-3">
                                    <p className="text-base Medium">Agreed</p>
                                    <Switch
                                      checked={productQuote[index].enabled}
                                      onChange={() =>
                                        this.handleSwitchChange(index)
                                      }
                                      className={`${productQuote[index].enabled
                                        ? "bg--primary"
                                        : "bg-gray-400"
                                        } relative inline-flex h-[24px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={`${productQuote[index].enabled
                                          ? "translate-x-9"
                                          : "translate-x-0"
                                          } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                      />
                                    </Switch>
                                  </div>
                                  {!productQuote[index].enabled && (
                                    <>
                                      <div className="rounded-lg gap-3 ledger-datepicker w-full max-w-sm flex flex-col">
                                        <p className="text-base Regular">
                                          Expected Delivery Date
                                        </p>
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                        >
                                          <div className="shadow-sm rounded-lg flex items-center h-11 px-3 border border-gray-300">
                                            <input
                                              type="date"
                                              min={productQuote[index].lastDate}
                                              value={
                                                productQuote[index].deliveryDate
                                              }
                                              onChange={(event) =>
                                                this.dateChangeHandler(
                                                  index,
                                                  event.target.value
                                                )
                                              }
                                              placeholder="End date"
                                              className="bg-transparent h-full w-full pl-3"
                                            />
                                          </div>
                                        </LocalizationProvider>
                                      </div>
                                    </>
                                  )}
                                  {!productQuote[index].enabled && (
                                    <div>
                                      <p className="danger text-base">
                                        Changing date may affect your request
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {rfq.products?.map((product, index) => (
                            <div key={product.id} className="border-b pb-4">
                              <div className="flex items-center justify-between">
                                <p className="Medium !text-lg primary capitalize">
                                  {product?.title}
                                </p>
                                <p className="Medium text-base">
                                  Required {product.uom ?? "quantity"} :{" "}
                                  {product.quantity}
                                </p>
                              </div>
                              <div className="mt-3">
                                <p className="flex items-center text-base text-gray-600">
                                  Quote Price (<HiOutlineCurrencyDollar />)
                                </p>
                                <div className="flex items-center mt-2 gap-x-2">
                                  <input
                                    type="text"
                                    placeholder="Enter price"
                                    maxLength={4}
                                    className="appearance-none rounded-md relative md:w-2/3 block px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                                    name="price"
                                    value={productQuote[index].price}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      const numberRegex = /^\d*\.?\d*$/;

                                      if (
                                        input.length === 0 ||
                                        numberRegex.test(input)
                                      ) {
                                        this.handlePriceChange(
                                          index,
                                          input,
                                          product.quantity
                                        );
                                      }
                                    }}
                                  />

                                  <p className="whitespace-nowrap text-base text-gray-600">
                                    Per {product.uom ?? "quantity"}
                                  </p>
                                </div>
                              </div>

                              <div className="space-y-1 mt-3">
                                <p className="text-base Medium">
                                  Can you meet the deadline of{" "}
                                  {product.last_date} ?
                                </p>
                                <div className="py-2 flex items-center gap-x-3">
                                  <p className="text-base Medium">Agreed</p>
                                  <Switch
                                    checked={productQuote[index].enabled}
                                    onChange={() =>
                                      this.handleSwitchChange(index)
                                    }
                                    className={`${productQuote[index].enabled
                                      ? "bg--primary"
                                      : "bg-gray-400"
                                      } relative inline-flex h-[24px] w-[56px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={`${productQuote[index].enabled
                                        ? "translate-x-9"
                                        : "translate-x-0"
                                        } pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                    />
                                  </Switch>
                                </div>
                                {!productQuote[index].enabled && (
                                  <>
                                    <div className="rounded-lg gap-3 ledger-datepicker w-full max-w-sm flex flex-col">
                                      <p className="text-base Regular">
                                        Expected Delivery Date
                                      </p>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <div className="shadow-sm rounded-lg flex items-center h-11 px-3 border border-gray-300">
                                          <input
                                            type="date"
                                            min={productQuote[index].lastDate}
                                            value={
                                              productQuote[index].deliveryDate
                                            }
                                            onChange={(event) =>
                                              this.dateChangeHandler(
                                                index,
                                                event.target.value
                                              )
                                            }
                                            placeholder="End date"
                                            className="bg-transparent h-full w-full pl-3"
                                          />
                                        </div>
                                      </LocalizationProvider>
                                    </div>
                                  </>
                                )}
                                {!productQuote[index].enabled && (
                                  <div>
                                    <p className="danger text-base">
                                      Changing date may affect your request
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      <div>
                        <p className="text-base Regular pb-2">Comment</p>
                        <TextareaAutosize
                          minRows={3}
                          maxLength={300}
                          placeholder="optional..."
                          className="appearance-none rounded-md relative block md:w-2/3 px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm resize-none"
                          name="description"
                          value={comment}
                          onChange={(e) =>
                            this.setState({ comment: e.target.value })
                          }
                        />
                      </div>
                      <div className="space-y-1">
                        <p className="text-base Regular pb-2">
                          Document(Optional):
                        </p>

                        <input
                          className="w-full"
                          onChange={(e) => this.fileUpload(e)}
                          type="file"
                          accept=".doc,.docx,.txt,.pdf,.ppt,.xls,.html,.dmg,.zip"
                          size="60 "
                        />
                        <p className="text-gray-500 text-[12px] Light">
                          Format: docx,doc,txt,pdf,png,jpg,jpeg,ppt,xls,
                        </p>
                        <p className="gray fs-12 Light">Size: Less then 10MB</p>
                      </div>
                      <div className={"flex items-center gap-x-4"}>
                        <p>Total:</p>
                        <p className={"Medium"}>
                          {price.length === 0 ? 0 : grossTotal}
                        </p>
                      </div>

                      <div>
                        <p className="text-[13px] text-gray-600">
                          Verify Tendered Amount:
                        </p>
                        <input
                          type="text"
                          placeholder="Verify Tendered Total Amount"
                          maxLength={50}
                          className="appearance-none rounded-md relative md:w-2/3 block px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                          name="totalamount"
                          value={tenderedPrice}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numberRegex = /^\d*\.?\d*$/; // Regular expression to match float numbers

                            if (input.length === 0 || numberRegex.test(input)) {
                              this.handleTenderAmount(input);
                            }
                          }}
                        />
                      </div>
                    </div>
                  }
                  <Buttonlink
                    className="mt-10 max-w-[150px] text-white hover:text-white Regular bg--primary hover:bg--primary focus:bg--primary hover:opacity-70 h-11 rounded--xl border-0 shadow-none w-full"
                    prefix={loading ? <Loader spinner={true} /> : false}
                    text={loading ? "" : "Submit"}
                    onClick={this.submitQuotation}
                    disabled={this.state.submitted}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <input
          className="hidden"
          accept="image/*"
          type="file"
          onChange={this.onPhotoChange}
          ref={this.photoRef}
        />
        <Dialog
          fullWidth={false}
          maxWidth={"sm"}
          open={this.state.isBluredPop}
          onClose={() => { }}
          PaperProps={{
            style: {
              borderRadius: "20px",
            },
          }}
        >
          <div className="p-10 mt-5 relative flex items-center flex-col gap-5">
            <div className="absolute right-3 -top-2 bg-white">
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.props.navigate(-1)}
                aria-label="close"
              >
                <IoCloseCircleOutline size={32} className="primary" />
              </IconButton>
            </div>
            <div className="flex flex-wrap gap-2 justify-center md:justify-start">
              <p>
                To proceed with viewing the RFQ inorder to submit quote, you are
                required to submit an NDA, Do you wish to Proceed?
              </p>
            </div>
            <Buttonlink
              className="w-32 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:bg--primary hover:opacity-70 bg--primary white hover:white gap-x-3 flex items-center justify-center"
              // disabled={authUser.categories?.length >= maxCategoriesProfile ? true : false}
              onClick={() => this.setState({ isDocu: true })}
              text="Proceed"
            ></Buttonlink>
          </div>
        </Dialog>
        <RFQSignaturePopup
          isDocu={this.state.isDocu}
          closePopup={() => this.setState({ isDocu: false })}
          closeSignature={() => this.closeSignature()}
          offer_id={rfq.id}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
    socket: Auth.socket,
  };
};
export default connect(mapStateToProps, null)(RFQquote);
