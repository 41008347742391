import React, { Component } from "react";
import { Buttonlink, Subbanner } from "components";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { connect } from "react-redux";
import { encryptfunction, seo } from "../../../helpers";
import { toast, ToastContainer } from "react-toastify";
import UPDATE_PASSWORD from "../../../graphql/mutations/updatePasswordMutation";
import client from "../../../graphql/client";
import AuthUserAction from "../../../store/actions/MyAuthUserAction";

class ChangePswd extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      current_password: "",
      new_password: "",
      confirm_password: "",
      message: "",
      error: false,
      errorClass: "",
      pswdType: true,
      disabled: true,
    };
  }

  componentDidMount() {
    seo({
      title: "Change Password | Sourcing Genie ",
    });
  }

  handleSubmit = () => {
    let { current_password, new_password, confirm_password } = this.state;

    if (
      current_password === "" ||
      new_password === "" ||
      confirm_password === ""
    ) {
      let clear = "";
      this.setState({
        current_password: clear,
        new_password: clear,
        confirm_password: clear,
        disabled: true,
      });
      return this.notify("Please fill in all the required fields.", "error");
    }

    if (current_password === new_password) {
      let clear = "";
      this.setState({
        new_password: clear,
        confirm_password: clear,
        disabled: true,
      });
      return this.notify(
        "The new password must be different from the old password",
        "error"
      );
    }

    if (new_password.length < 8) {
      let clear = "";
      this.setState({
        new_password: clear,
        confirm_password: clear,
        disabled: true,
      });
      return this.notify(
        "The password length must be greater than 8 characters",
        "error"
      );
    }

    if (
      current_password !== "" &&
      new_password !== "" &&
      confirm_password !== ""
    ) {
      client
        .mutate({
          mutation: UPDATE_PASSWORD,
          variables: {
            current_password: current_password,
            new_password: new_password,
            confirm_password: confirm_password,
          },
        })
        .then((res) => {
          let { error, message } = res.data.updatePassword;
          if (error) {
            this.setState({
              new_password: "",
              confirm_password: "",
            });
            AuthUserAction.getAuthUserInformation().then((res) => {
              return this.notify(message, "error");
            });
          } else {
            this.notify(message, "success");
          }
        })
        .catch((err) => {
          // Handle any error that occurred during the mutation
          this.notify(
            "Oops, something went wrong. Please try again later.",
            "error"
          );
        });

      let clear = "";
      this.setState({
        current_password: clear,
        new_password: clear,
        confirm_password: clear,
        disabled: true,
      });
    } else {
      this.notify("Please fill all required fields", "error");
    }
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    } else {
      this.props.navigate(`/${encryptfunction(this.props?.authUser?.id)}/profile`, { state: { value: "edit", id: this.props?.authUser?.id } });
      toast.success(message, { position: "top-right" });
    }
  };

  handleInputChange = (event) => {
    this.setState(
      {
        ...this.state,
        [event.target.name]: event.target.value,
      },
      () => {
        if (
          this.state.current_password.length !== 0 &&
          this.state.new_password.length !== 0 &&
          this.state.confirm_password.length !== 0
        ) {
          this.setState({
            disabled: false,
          });
        } else {
          this.setState({
            disabled: true,
          });
        }
      }
    );
  };

  showEyePswd = () => {
    this.setState({ pswdType: !this.state.pswdType });
  };

  render() {
    const { disabled } = this.state;
    console.log("a", this.props?.authUser?.company_linkedin_profile);
    return (
      <div>
        <Subbanner title="Update Password" />
        <div className="container -mt-28">
          <ToastContainer />
          <div className="grid xl:grid-cols-5 grid-cols-1 gap-4">
            <div className="xl:col-start-2 xl:col-span-3">
              <div className="mb-3">
                <Buttonlink
                  text="Back"
                  prefix={<IoIosArrowBack size={16} />}
                  onClick={() => this.props.navigate(-1)}
                  className="relative bg-gray-200 hover:bg-gray-300 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 hover:opacity-80 border-0 shadow-none"
                />
              </div>
            </div>
            <div className="xl:col-start-2 xl:col-span-3 bg-white rounded--xl shadow-lg">
              <div className="bg-white p-10 rounded-xl">
                <div className="space-y-4">
                  <div className="flex items-center relative">
                    <input
                      id="Current-Password"
                      name="current_password"
                      required
                      // disabled={this.props?.authUser?.company_linkedin_profile.length > 0}
                      className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      placeholder="Current Password"
                      type="password"
                      maxLength={32}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="flex items-center relative">
                    <input
                      id="New-Password"
                      name="new_password"
                      autoComplete="New Password"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      placeholder="New Password"
                      value={this.state.new_password}
                      type={this.state.pswdType ? "password" : "text"}
                      minLength={8}
                      maxLength={32}
                      onChange={this.handleInputChange}
                    />
                    <div
                      onClick={this.showEyePswd}
                      className="cursor-pointer h-12 w-12 flex items-center justify-center absolute right-0"
                    >
                      {this.state.pswdType ? (
                        <BsEye size={20} />
                      ) : (
                        <BsEyeSlash size={20} />
                      )}
                    </div>
                  </div>
                  <div className="flex items-center relative">
                    <input
                      id="confirm-password"
                      name="confirm_password"
                      autoComplete="Confirm Password"
                      required
                      className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                      placeholder="Confirm Password"
                      value={this.state.confirm_password}
                      type={this.state.pswdType ? "password" : "text"}
                      minLength={8}
                      maxLength={32}
                      onChange={this.handleInputChange}
                    />
                    <div
                      onClick={this.showEyePswd}
                      className="cursor-pointer h-12 w-12 flex items-center justify-center absolute right-0"
                    >
                      {this.state.pswdType ? (
                        <BsEye size={20} />
                      ) : (
                        <BsEyeSlash size={20} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="h-6" />
                <div className="flex items-center gap-x-4">
                  <div className="divider w-full" />
                  <p className="whitespace-nowrap Light fs-13 darkGray">
                    Update Password
                  </p>
                  <div className="divider w-full" />
                </div>
                <div className="h-6" />
                <Buttonlink
                  onClick={this.handleSubmit}
                  disabled={disabled}
                  text="Save"
                  // className="relative w-full hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none "
                  className={`relative w-full hover:white text-center flex items-center justify-center py-2.5 px-4 border border-transparent rounded-lg ${disabled
                    ? "text-white bg--gray hover:bg--gray"
                    : "text-white bg--primary hover:bg--primary hover:opacity-80 cursor-pointer"
                    } focus:outline-none`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
  };
};
export default connect(mapStateToProps, null)(ChangePswd);
