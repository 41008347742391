import React, { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { Buttonlink, Loader } from "../..";
import { useLocation } from 'react-router-dom';
import MySelectedRFQsAction from "store/actions/MySelectedRFQsAction";
import TextField from "@mui/material/TextField";
import client from "graphql/client";
import GET_OFFERS from "../../../graphql/queries/getOffers";
import { connect } from "react-redux";
import { gql } from "@apollo/client";

const SET_INTERESTS = gql`
  mutation ($title: String) {
  createUserInterests(title: $title) {
    error
  }
}
`;
const Search = (props) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [search, setSearch] = useState(props.searchText);
  const [placeHolder, setPlaceHolder] = useState("");
  const [films, setFilms] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const [loader, setLoader] = useState(false)
  const location = useLocation();

  //const history = useHistory();

  // Clear the search state when the route changes
  useEffect(() => {
    setSearch(''); // Clear the search state
  }, [location.pathname]);

  let searchTimeout = null;

  const getSearch = () => {
    const orderBy = [{ field: "updated_at", order: "DESC" }];
    let variables = { orderBy };
    // variables.me = from === "rfq";

    if (search !== "") {
      variables.search = search;
      setLoader(true);
    }

    // Clear any existing search timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set a new search timeout of 2 seconds (2000 milliseconds)
    searchTimeout = setTimeout(() => {
      client
        .query({
          query: GET_OFFERS,
          variables,
        })
        .then((result) => {
          const filmTitles = result.data.offers?.data?.map((element) => element.title) || [];
          if (search !== "") {
            setFilms(filmTitles);
            setLoader(false);
            // setIsOpen(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 1000); // 2 seconds delay
  };


  const handleSearch = (searchValue) => {
    // console.log("clicked", searchValue);
    setPlaceHolder(search);
    MySelectedRFQsAction.searchedRfqs(searchValue);
    if (searchValue !== null && searchValue !== '' && Object.keys(props.auth_user.authUserInformation).length !== 0) {
      client.mutate({
        mutation: SET_INTERESTS,
        variables: {
          title: searchValue
        }
      }).then(res => {
      }).catch(err => {
        console.log(err)
      })
    }

    window.scrollTo(0, 1250);
  };

  useEffect(() => {
    getSearch();
  }, [search]);

  // Custom autocomplete functionality
  const handleAutocompleteChange = (event) => {
    setSearch(event.target.value);
  };

  const handleAutocompleteSelect = (value) => {
    setSearch(value);
  };
  let height = 200;
  if (films?.length < 4) {
    height = films * 50;
  }
  // const handleSearch = (value) => {
  //   // Your search logic here
  //   console.log('Search for:', value);
  // };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(search !== '' ? search : selectedValue);
    }
  };
  return (
    <div className="flex bg--primary xl:border-2 border--primary rounded-full search-box flex-grow -intro-x z-xlfull">
      <div className="flex relative xl:border-none border-2 border--primary rounded-full search-box flex-grow -intro-x z-xlfull">
        <TextField
          className="rounded-full w-full hero-serach"
          placeholder={"Search any RFQ"}
          value={search}
          onChange={handleAutocompleteChange}
          onKeyDown={handleKeyDown}
          onFocus={() => {
            setIsOpen(true)
          }}
          onBlur={() => {
            setTimeout(() => {
              setIsOpen(false)
            }, 500);

          }}
        />
        {/* Implement a list of autocomplete options here */}
        {films?.length !== 0 &&
          <div className={`${isOpen || (!films?.length === 0) ? '' : 'hidden'} absolute h-[${height}px] overflow-y-auto top-14 w-full bg-white shadow-md p-4 rounded-xl`}>
            {loader ? (
              <Loader />
            ) : (
              films?.length !== 0 &&
              films.slice(0, 10).map((filmTitle) => (
                <p
                  key={filmTitle}
                  className="cursor-pointer black hover:primary Medium mt-4"
                  onClick={() => handleAutocompleteSelect(filmTitle)}
                >
                  {filmTitle}
                </p>
              ))
            )}
          </div>
        }

      </div>
      <Buttonlink
        onClick={() => handleSearch(search !== "" ? search : selectedValue)}
        text="Search"
        prefix={<GoSearch />}
        className="bg--primary px-8 mx-auto xl:py-0 py-2 xl:mt-0 flex items-center gap-x-3 white Regular xl:rounded-l-none rounded-l-full rounded-r-full hover:opacity-80"
      />
    </div>
  );
};
const mapStateToProps = ({ RFQs, Auth }) => {
  return {
    searchText: RFQs.searchedRfqs,
    auth_user: Auth
  };
};

// const mergeProps = (stateProps, dispatchProps, ownProps) => {
//   const { dispatch } = dispatchProps;
//   const { actions } = require("store/redux/RFQRedux");
//   return {
//     ...ownProps,
//     ...stateProps,
//     rfqSelectedFilter: (data) => {
//       dispatch(actions.rfqSelectedFilter(data));
//     },



//   };
// };

export default connect(mapStateToProps, undefined)(Search);

