import React, { useImperativeHandle, useState } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import "./styles.css";
import { UnitMeasurement, TagsSelect } from "navigation";
import ChooseRFQCategoryaside from "navigation/CategoriesScreen/components/chooseRFQCategoryaside";
import AddMembers from "navigation/NewRFQScreen/components/addMembers";
import SelectState from "navigation/NewRFQScreen/components/selectState";
import MoreCategories from "navigation/RfqLedgerScreen/components/morecategories";
import { actions } from "store/redux/RFQRedux";
import { useDispatch } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const Fullscreenpopup = React.forwardRef(
  ({ uom, type, country_id, data, clearState, from, maxValue }, ref) => {
    const [open, setOpen] = useState(false);
    const [temp, setTemp] = useState({});
    const dispatch = useDispatch();

    const openPopup = () => {
      setOpen(true);
    };

    useImperativeHandle(ref, () => ({
      openPopup,
    }));

    const closePopup = () => {
      setOpen(false);
      clearState(type);
    };
    return (
      <div>
        <Dialog
          TransitionComponent={Transition}
          fullScreen
          open={open}
          onClose={() => setOpen(false)}
          className="fullScreenDialog"
          id="scrollbar"
        >
          <div
            className="shadow-none bg-gray-100"
            sx={{ position: "relative" }}
          >
            <Toolbar className="flex items-center">
              <h3 className="black Medium text-xl mr-auto">{type}</h3>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  if (type === "Select State") {
                    dispatch(actions.selectState(temp));
                  }
                  closePopup();
                }}
                aria-label="close"
                className="rounded--lg  hover:bg--gray"
              >
                <div className="primary">Done</div>
              </IconButton>
            </Toolbar>
          </div>
          <div className="p-3">
            {(() => {
              switch (type) {
                case "Choose Category":
                  return <ChooseRFQCategoryaside closePopup={closePopup} from={from ?? ""} maxValue={maxValue ?? 1} />;
                case "Unit of Measure":
                  return <UnitMeasurement id={1} uom={uom} />;
                case "Tags":
                  return <TagsSelect closePopup={closePopup} from={from ?? ""} />;
                case "Add Members":
                  return <AddMembers />;
                case "Select State":
                  return (
                    <SelectState
                      country_id={country_id}
                      temp={temp}
                      setTemp={setTemp}
                      sortname={(v) => console.log("call back")}
                    />
                  );
                case "More Categories":
                  return <MoreCategories data={data} />;
                default:
                  return null; // Render null or a default component for unknown types
              }
            })()}
          </div>
        </Dialog>
      </div>
    );
  }
);

export default Fullscreenpopup;
