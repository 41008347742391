import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BsFillCameraFill } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { Buttonlink, Loader, SelectCountry, Subbanner } from "components";
import Profileavatar from "../../../components/profileavatar";
import client from "../../../graphql/client";
import ME from "../../../graphql/queries/me";
import UPDATE_USER_MUTATION from "../../../graphql/mutations/updateUserMutation";
import UPDATE_PROFILE_PIC from "../../../graphql/mutations/uploadProfilePic";
import AuthUserAction from "../../../store/actions/MyAuthUserAction";
import MyAuthUserAction from "../../../store/actions/MyAuthUserAction";
import { AUTH_USER_MRO } from "../../../config/constants";
import convertToSlug from "../../../helpers/convertToSlug";
import { gql } from "@apollo/client";
import "react-toastify/dist/ReactToastify.css";
import { encryptfunction } from "helpers";
class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstname: "",
      lastname: "",
      tagline: "",
      email: "",
      phone_number: "",
      loading: false,
      usernameErr: false,
      firstnameErr: false,
      lastnameErr: false,
      phoneNumErr: false,
      taglineErr: false,
      image: null,
      country_id: 101,
    };
    this.photoRef = React.createRef();
    this.handleFirstnameChange = this.handleFirstnameChange.bind(this);
    this.handleLastnameChange = this.handleLastnameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleTaglineChange = this.handleTaglineChange.bind(this);
    this.handlePhotoClick = this.handlePhotoClick.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleUpdateProfile = this.handleUpdateProfile.bind(this);
  }

  componentDidMount() {
    const { authUser } = this.props;
    this.setUpdatedMe();
    this.setState({
      username: authUser.username,
      firstname: authUser.firstname,
      lastname: authUser.lastname,
      tagline: authUser.tagline,
      email: authUser.email,
      phone_number: authUser.phone_number,
      uploadImage: false,
      loading: false,
      error: false,
      message: "",
      disable: true,
      country_id: authUser.country_id === null ? 101 : authUser.country_id,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { authUser } = this.props;
    if (
      (prevState.firstname !== prevProps.authUser.firstname &&
        prevState.disable) ||
      (prevState.username !== prevProps.authUser.username &&
        prevState.disable) ||
      (prevState.firstname !== prevProps.authUser.firstname &&
        prevState.disable) ||
      (prevState.lastname !== prevProps.authUser.lastname &&
        prevState.disable) ||
      (prevState.tagline !== prevProps.authUser.tagline && prevState.disable) ||
      (prevState.phone_number !== prevProps.authUser.phone_number &&
        prevState.disable)
    ) {
      this.setState({
        disable: false,
      });
    }
  }

  setUpdatedMe = () => {
    client
      .query({
        query: ME,
      })
      .then((result) => {
        this.setState(
          {
            username: result.data.me.username,
            firstname: result.data.me.firstname,
            lastname: result.data.me.lastname,
            tagline: result.data.me.tagline,
            email: result.data.me.email,
            phone_number: result.data.me.phone_number,
          },
          () => {
            localStorage.setItem(AUTH_USER_MRO, JSON.stringify(result.data.me));
          }
        );
      })
      .catch((error) => { });
  };

  promise = (error, message) => {
    const resolveAfter3Sec = new Promise((resolve, reject) => {
      if (error) {
        setTimeout(reject, 2000);
      } else {
        setTimeout(resolve, 0);
      }
    });
    toast.promise(resolveAfter3Sec, {
      pending: "Please Wait...",
      success: "Welcome to Sourcing League",
      error: message,
    });
  };

  handlePhotoClick = () => {
    this.photoRef.current.click();
  };

  isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg", "tif"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0];
      let sizeInMB = (file.size / (1024 * 1024)).toFixed(2);

      if (sizeInMB > 2) {
        return this.notify(
          "Your file size is " + sizeInMB + " MB. Allowed size is 2MB.",
          "error"
        );
      } else if (!this.isValidFileUploaded(file)) {
        return this.notify("Please upload image in JPG, JPEG, PNG or TIF format.", "error");
      } else {
        // Read the file and convert it to base64
        const reader = new FileReader();
        reader.onloadend = () => {
          // Combine the data URL prefix with the base64 string
          const base64String = `data:${file.type};base64,${reader.result.split(",")[1]
            }`;
          // Now you can use the base64String for further processing or to upload to a server
          this.uploadImage(file, base64String);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  uploadImage = (file, base64String) => {
    this.setState({
      uploadImage: true,
    });
    let variables = {
      file: base64String,
    };
    client
      .mutate({
        mutation: UPDATE_PROFILE_PIC,
        variables,
        update: (store, { data }) => {
          try {
            store.writeQuery({
              query: gql`
                query me {
                  me {
                    id
                    profile_photo
                  }
                }
              `,
              data: {
                me: {
                  __typename: "User",
                  id: this.props.authUser.id,
                  profile_photo: data.uploadProfilePic.path,
                },
              },
            });
          } catch (e) {
            console.log("error on role switch", e);
          }
        },
      })
      .then((res) => {
        AuthUserAction.getAuthUserInformation().then((data) => {
          this.notify("Profile image updated successfully!", "success");
          this.setState({
            uploadImage: false,
          });
        });
      })
      .catch((err) => {
        console.log("errrrrr", err);
        this.setState({
          uploadImage: false,
        });
        this.notify("Error in updating profile image!", "error");
      });
  };

  handleFirstnameChange(event) {
    const value = event.target.value.replace(/[^a-z]/gi, "");
    this.setState({ firstname: value });
  }

  handleLastnameChange(event) {
    const value = event.target.value.replace(/[^a-z]/gi, "");
    this.setState({ lastname: value });
  }

  handlePhoneChange(event) {
    const value = event.target.value.replace(/[^\d]/g, "");
    this.setState({ phone_number: value });
  }

  handleTaglineChange(event) {
    this.setState({ tagline: event.target.value });
  }

  validateInputs = () => {
    const { firstname, lastname, phone_number, tagline } = this.state;
    let valid = true;

    if (!firstname) {
      this.setState({ firstnameErr: true });
      valid = false;
    }

    if (!lastname) {
      this.setState({ lastnameErr: true });
      valid = false;
    }

    if (!phone_number) {
      this.setState({ phoneNumErr: true });
      valid = false;
    }

    if (!tagline) {
      this.setState({ taglineErr: true });
      valid = false;
    }

    return valid;
  };

  handleUpdateProfile = async () => {
    if (!this.validateInputs()) {
      return;
    }

    this.setState({ loading: true });

    try {
      const {
        username,
        firstname,
        lastname,
        tagline,
        phone_number,
        country_id,
      } = this.state;

      const slug = convertToSlug(firstname.concat(lastname)) || username;
      const variables = {
        username: slug,
        firstname,
        lastname,
        tagline,
        phone_number,
        linkedin_profile: "",
        country_id,
      };

      await client.mutate({
        mutation: UPDATE_USER_MUTATION,
        variables,
      });

      await this.setUpdatedMe();

      setTimeout(() => {
        this.setState({ loading: false, disable: true }, () => {
          MyAuthUserAction.getAuthUserInformation().then((res) => {
            this.props.navigate(`/${encryptfunction(this.props?.authUser?.id)}/profile`, { state: { value: "edit", id: this.props?.authUser?.id } });
            this.notify("Profile updated successfully!", "success");
          });
        });
      }, 500);
    } catch (error) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 500);
      this.notify("Error in updating profile!", "error");
    }
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    }
    toast.success(message, { position: "top-right" });
  };

  render() {
    const {
      firstname,
      firstnameErr,
      lastname,
      lastnameErr,
      email,
      phone_number,
      phoneNumErr,
      tagline,
      taglineErr,
      loading,
      disable,
      image,
      country_id,
      usernameErr,
      username,
    } = this.state;

    const mergedState =
      firstname && lastname ? convertToSlug(firstname.concat(lastname)) : "";

    const isFirstNameError = firstnameErr && !firstname;
    const isLastNameError = lastnameErr && !lastname;
    const isPhoneError = phoneNumErr && !phone_number;
    const isTaglineError = taglineErr && !tagline;

    const { navigate } = this.props;
    return (
      <div>
        <Subbanner title="Edit Profile" />
        {email ? (
          <div className="container -mt-28">
            <div className="grid xl:grid-cols-5 grid-cols-1 gap-4">
              <div className="xl:col-start-2 xl:col-span-3">
                <div className="mb-3">
                  <Buttonlink
                    text="Back"
                    prefix={<IoIosArrowBack size={16} />}
                    onClick={() => navigate(-1)}
                    className="relative bg-gray-200 hover:bg-gray-300 fs-13 flex items-center justify-center gap-x-1 h-9 rounded-full darkGray w-24 hover:opacity-80 border-0 shadow-none"
                  />
                </div>
              </div>
              <div className="xl:col-start-2 xl:col-span-3 bg-white rounded--xl shadow-lg">
                <div className="px-5 py-10">
                  {image !== null ? (
                    <img
                      alt="profile_image"
                      src={URL.createObjectURL(image)}
                      className="object-contain h-32 w-32 rounded-full mx-auto relative cursor-pointer "
                    />
                  ) : (
                    <div
                      className="h-32 w-32 rounded-md mx-auto relative cursor-pointer "
                      onClick={this.handlePhotoClick}
                    >
                      <Profileavatar
                        singleUser={this.props.authUser}
                        sizeStyle={{ width: 140, height: 140 }}
                        className="shadow-xl !rounded-md"
                      />
                      <div className="bg--lightGray shadow-xl w-9 h-9 rounded-full flex items-center justify-center absolute -bottom-10 -right-10 z-10">
                        <BsFillCameraFill size={12} className="darkGray" />
                      </div>
                    </div>
                  )}
                </div>
                <div className="bg-gray-200 w-full h-px" />
                <div className="bg-white p-10 rounded-xl">
                  <div className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        id="First-name"
                        name="Name"
                        autoComplete="First Name"
                        maxLength={32}
                        defaultValue={firstname}
                        onChange={this.handleFirstnameChange}
                        className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${isFirstNameError
                          ? "border-red-500"
                          : "border-gray-300"
                          } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm `}
                        placeholder="First Name"
                      />
                      <div className="flex">
                        <input
                          id="last-name"
                          name="Last Name"
                          autoComplete="Last Name"
                          required
                          defaultValue={lastname}
                          maxLength={32}
                          onChange={this.handleLastnameChange}
                          className={`appearance-none rounded-md relative block w-1/2 px-3 py-3 border ${isLastNameError
                            ? "border-red-500"
                            : "border-gray-300"
                            } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                          placeholder="Last Name"
                        />
                        <input
                          readOnly={true}
                          id="username"
                          name="username"
                          defaultValue={mergedState}
                          maxLength={32}
                          className={`appearance-none rounded-md relative block w-1/2 px-3 py-3 border ${usernameErr ? "border-red-500" : "border-gray-300"
                            } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                          placeholder="username"
                        />
                      </div>
                      <div>
                        <input
                          readOnly={true}
                          id="email-address"
                          name="email"
                          type="email"
                          pattern=".+@globex\.com"
                          placeholder={"Email Address"}
                          autoComplete="email"
                          contentEditable={false}
                          defaultValue={email}
                          maxLength={64}
                          className="appearance-none rounded-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm"
                        />
                      </div>
                      <div>
                        <input
                          id="phone-number"
                          name="phone-number"
                          type="phone-number"
                          pattern="[0-9]+"
                          placeholder="Phone Number"
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          defaultValue={phone_number}
                          onChange={this.handlePhoneChange}
                          required
                          maxLength={11}
                          className={`appearance-none rounded-md relative block w-full px-3 py-3 border ${isPhoneError ? "border-red-500" : "border-gray-300"
                            } placeholder-gray-500 focus:outline-none shadow-sm sm:text-sm`}
                        />
                      </div>
                      <SelectCountry
                        country_id={(value) =>
                          this.setState({ country_id: value })
                        }
                        seleced_country_object={this.props.authUser.country}
                        sortname={(v) => console.log("call back")}
                      />
                      <div className="col-span-2">
                        <div style={{ position: "relative" }}>
                          <textarea
                            id="about"
                            name="about"
                            rows={3}
                            maxLength={500}
                            className={`shadow-sm block w-full darkGray Light px-3 py-3 border ${isTaglineError
                              ? "border-red-500 placeholder-red-500"
                              : "border-gray-300 placeholder-gray-500"
                              } rounded-md`}
                            placeholder="Add Introduction"
                            value={tagline}
                            onChange={this.handleTaglineChange}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "0.5rem",
                              right: "0.5rem",
                              color: tagline?.length > 500 ? "red" : "black",
                              fontSize: "0.8rem",
                            }}
                          >
                            {tagline?.length}/500
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-12" />
                  <div className="flex items-center gap-x-4">
                    <div className="divider w-full" />
                    <p className="whitespace-nowrap  fs-13 darkGray">
                      Update your Profile
                    </p>
                    <div className="divider w-full" />
                  </div>
                  <div className="h-10" />
                  {loading ? (
                    <div className="relative w-full hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg primary bg--lightPrimary  hover:bg--primary focus:outline-none ">
                      <Loader spinner={true} />
                    </div>
                  ) : (
                    <Buttonlink
                      disabled={disable}
                      onClick={this.handleUpdateProfile}
                      text="Save"
                      className={`relative w-full hover:white flex justify-center py-2.5 px-4 border border-transparent rounded-lg ${disable
                        ? "text-white bg--gray hover:bg--gray"
                        : "text-white bg--primary hover:bg--primary hover:opacity-80 cursor-pointer"
                        } focus:outline-none`}
                    />
                  )}
                </div>
              </div>
            </div>
            <input
              className="hidden"
              accept="image/*"
              type="file"
              onChange={this.handleImageChange}
              ref={this.photoRef}
            />
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  return {
    authUser: Auth.authUserInformation,
  };
};

export default connect(mapStateToProps, null)(EditProfile);