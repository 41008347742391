import React, { Component } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import LoadMoreData from "../../../components/loadMoreData";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Loader, Noconnection } from "components";
import { Query } from "@apollo/client/react/components";
import SEARCH_TAGS from "graphql/queries/searchTags";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class tagsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchingTag: "",
      loading: false,
      loadMoreTags: true,
    };
  }

  componentDidUpdate() {
    if (this.state.loading && this.state.searchingTag === "") {
      this.setState({
        loadMoreTags: true,
        loading: false,
      });
    }
    // window.addEventListener("scroll", this.handleOnScroll);
  }

  loadMoreTags = (data, fetchMore, refetch) => {
    this.setState({
      loading: true,
    });
    fetchMore({
      variables: {
        cursor: data.tagSearch.paginatorInfo.currentPage + 1,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newQuestions = fetchMoreResult.tagSearch.data;
        const pageInfo = fetchMoreResult.tagSearch.paginatorInfo;
        if (data.tagSearch.paginatorInfo.total !== pageInfo.total) {
          refetch();
        }
        if (pageInfo.hasMorePages) {
          this.setState({
            loadMoreTags: true,
            loading: false,
          });
        } else {
          this.setState({
            loadMoreTags: false,
            loading: false,
          });
        }

        return newQuestions.length
          ? {
            tagSearch: {
              __typename: previousResult.tagSearch.__typename,
              data: [...previousResult.tagSearch.data, ...newQuestions],
              paginatorInfo: pageInfo,
            },
          }
          : previousResult;
      },
    });
  };

  // handleOnScroll = () => {
  //   var scrollTop =
  //     (document.documentElement && document.documentElement.scrollTop) ||
  //     document.body.scrollTop;
  //   var scrollHeight =
  //     (document.documentElement && document.documentElement.scrollHeight) ||
  //     document.body.scrollHeight;
  //   var clientHeight =
  //     document.documentElement.clientHeight || window.innerHeight;
  //   var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
  //   if (scrolledToBottom) {
  //   }
  // };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    } else {
      return toast.success(message, { position: "top-right" });
    }
  };

  handleClicked = (item) => {
    let checkedTags = Object.assign(
      [],
      this.props.fields && this.props.fields.tags
    );
    let found = checkedTags.find((data) => data.id === item.id);
    if (!found) {
      checkedTags.push(item);
    } else {
      checkedTags = checkedTags.filter((data) => data.id !== item.id);
    }
    this.addFields("tags", checkedTags);
  };

  addFields = (key, value) => {
    let form = Object.assign({}, this.props.fields);

    form[key] = value;
    let maxTags = 3;
    if (this.props.from === "createNewRfq") {
      maxTags = 1;
      this.props?.user?.authUserInformation?.payments?.forEach((plan) => {
        plan.plan.plan_features.forEach((plan_feature) => {
          if (plan_feature.name === "No. of tags selection on RFQ") {
            if (plan_feature.quantity > maxTags) {
              maxTags = plan_feature.quantity;
            }

          }
        })
      });
    }

    if (value?.length > maxTags) {
      this.notify(`You can only select ${maxTags} tags`, "error");
      this.props.closePopup();
    } else {
      this.props.addRfqFields(form);
      this.props.selectTags(form);
    }
  };

  handleChange = (e) => {
    const value = e.target.value;
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      this.setState({ searchingTag: value });
    }, 1500);
  };

  render() {
    return (
      <div className="relative px-4 pt-4 pb-2">
        <div className="bg--lightGray rounded-xl flex items-center h-10 px-3">
          <HiOutlineSearch className="darkGray" />
          <input
            placeholder="Enter to search and select"
            className="bg-transparent h-full w-full pl-3"
            defaultValue={this.state.searchingTag}
            onChange={this.handleChange}
          />
        </div>
        <div className="divider w-full my-3" />
        <div className="-mx-2">
          <Query
            query={SEARCH_TAGS}
            variables={{
              q: "%" + this.state.searchingTag + "%",
              orderBy: [{ field: "name", order: "ASC" }],
            }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, fetchMore, refetch }) => {
              if (loading) return <Loader />;

              if (error) return <Noconnection />;

              if (data) {
                return (
                  <>
                    {(data?.tagSearch?.data || [])?.map((tag) => (
                      <ListItem
                        disablePadding
                        className="rounded-lg overflow-hidden"
                      >
                        <ListItemButton dense className="hover:primary-all">
                          <ListItemText>
                            <div className="darkGray">{tag.name}</div>
                          </ListItemText>
                          <ListItemIcon
                            onClick={() => this.handleClicked(tag)}
                            className="min-w-auto"
                          >
                            <Checkbox
                              checked={
                                this.props.tags &&
                                  this.props.tags.tags &&
                                  this.props.tags.tags.find(
                                    (data) => data.id === tag.id
                                  )
                                  ? true
                                  : false
                              }
                              className="primary-checkbox"
                            />
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    ))}
                    {(data?.tagSearch?.data || [])?.length === 0 && 
                      <div className="w-full text-center mt-10 text-gray-500">
                        Searched Text is not found!
                      </div>
                    }
                    <div className="text-center mt-5">
                      {this.state.loadMoreTags &&
                        data.tagSearch.data.length >= 20 && (
                          <LoadMoreData
                            title="Load More Tags"
                            data={data}
                            fetchMore={fetchMore}
                            refetch={refetch}
                            callback={this.loadMoreTags}
                            loading={this.state.loading}
                          />
                        )}
                    </div>
                  </>
                );
              }
            }}
          </Query>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ Rfq, Auth }) => {
  return {
    tags: Rfq.selectedTags,
    form: Rfq.form,
    user: Auth,
    fields: Rfq.fields,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    addRfqFields: (data) => {
      dispatch(actions.addRfqFields(data));
    },
    selectTags: (data) => {
      dispatch(actions.selectTags(data));
    },
  };
};
export default connect(mapStateToProps, undefined, mergeProps)(tagsSelect);
