import React, { useEffect, useRef, useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Loader, Noconnection } from "components";
import { useQuery } from "@apollo/client";
import states from "../../../graphql/queries/states";
import { connect } from "react-redux";
import { debounce } from "lodash";
import xmark from "../../../assets/svgs/xmark.svg"

const SelectState = ({ country_id, _state, selectState, temp, setTemp }) => {
  const [searchName, setSearchName] = useState("");
  const inputRef = useRef('');

  useEffect(() => {
    setTemp(_state);
  }, [_state])

  const handleClicked = (state) => {
    setTemp(state);
  };

  const { loading, error, data } = useQuery(states, {
    variables: { q: searchName + "%", country_id: country_id === 0 ? temp?.country_id : country_id },
  });

  const setSearch = debounce((value) => {
		setSearchName(value);
	}, 1500);

  const clearInput = () => {
    if (inputRef.current) {
      setSearchName('');
      inputRef.current.value = '';
    }
  };


  if (loading) return <Loader />;
  if (error) return <Noconnection />;

  return (
    <div className="relative p-6">
      <div className="bg--lightGray rounded-xl flex items-center h-10 px-3">
        <HiOutlineSearch className="darkGray" />
        <input
          placeholder="Enter to Search / Select"
          className="bg-transparent h-full w-full pl-3"
          defaultValue={searchName || ''}
          ref={inputRef}
          onChange={(e) => {
            const inputValue = e.target.value;
            setSearch.cancel();
            setSearch(inputValue);
          }}
        />
        <img src={xmark} 
        onClick={() => clearInput()} 
        className="darkGray w-5 cursor-pointer text-gray-500 h-5" />
      </div>
      <div className="divider w-full my-3" />
      <div className="-mx-4">
        {data.states.data.length === 0 ? (
          searchName === '' ?
            <div className="w-full text-center mt-10 text-gray-500">Select Country First!</div>
            :
            <div className="w-full text-center mt-10 text-gray-500">Searched Text is not found!</div>
        ) : (
          data.states.data.map((state) => (
            <ListItem
              key={state.id}
              disablePadding
              className="rounded-lg overflow-hidden"
            >
              <ListItemButton dense className="hover:primary-all">
                <ListItemText>
                  <div className="darkGray">{state.name}</div>
                </ListItemText>
                <ListItemIcon className="min-w-auto">
                  <Checkbox
                    checked={temp !== null && temp?.id === state.id}
                    onChange={() => handleClicked(state)}
                    className="primary-checkbox"
                  />
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ Rfq }) => {
  return {
    _state: Rfq._state,
  };
};

export default connect(mapStateToProps)(SelectState);
