import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { PROFILE_PHOTO_URL } from "config/constants";
import { connect } from "react-redux";


function Avatarimage(props) {
    const { iscompany, imagesrc, firstname, className, imagesize, userId, ...otherProps } = props;
    const [userStatus, setUserStatus] = useState(false);
    useEffect(() => {
        if (props.socket?.connected) {
            props.socket?.on("checkActive", (data) => {
                if (data?.id == userId && data?.active == true) {
                    setUserStatus(true);
                }

            });
            props.socket?.on("checkInActive", (data) => {

                if (data?.id == userId) {
                    setUserStatus(false);
                }

            });
        }
    }, [props.socket]);
    const getAvatarSrc = () => {
        if (imagesrc !== null) {
            if (iscompany) {
                return imagesrc;
            } else {
                return process.env.REACT_APP_MAIN_URL + PROFILE_PHOTO_URL + imagesrc;
            }
        }
        return null;
    };

    const avatarSrc = getAvatarSrc();

    return (
        <div className="relative">
            <Avatar alt={firstname} src={avatarSrc} className={className} style={imagesize} {...otherProps} />
            {
                userStatus && !iscompany && <div className="absolute -top-1 rounded-full h-4 border-2 border-white w-4 bg-green-500" />
            }
        </div>
    );
}
const mapStateToProps = ({ Auth }) => {
    return {
        authUser: Auth.authUserInformation,
        socket: Auth.socket,
    };
};

export default connect(mapStateToProps, null)(Avatarimage);
