import { Avatarimage, Nocontent } from "components";
import React, { Component } from "react";
import { BsBookmark } from "react-icons/bs";
import { Link } from "react-router-dom";
import messages from "../../../assets/images/messages.png";
import offerUnSaveBookmark from "../../../graphql/mutations/offerUnSaveBookmark";
import GET_OFFERS from "../../../graphql/queries/getOffer";
import client from "graphql/client";
import { gql } from "@apollo/client";
import { convertToSlug, encryptfunction } from "helpers";

export default class Rfqquestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabName: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
  }

  handleOnScroll = () => {
    /* var scrollTop =
       (document.documentElement && document.documentElement.scrollTop) ||
       document.body.scrollTop;
     var scrollHeight =
       (document.documentElement && document.documentElement.scrollHeight) ||
       document.body.scrollHeight;
     var clientHeight =
       document.documentElement.clientHeight || window.innerHeight;
     var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
     if (scrolledToBottom) {
       this.props.onLoadMore();
     }*/
  };

  removeBookmark = (id, bookMark) => {
    client.mutate({
      mutation: offerUnSaveBookmark,
      variables: {
        offer_id: id,
      },
      optimisticResponse: {
        __typename: "Mutation",
        offerUnSaveBookmark: {
          __typename: "OfferUnSaveBookmark",
          id: id,
          isBookmarked: false,
        },
      },

      update: (store, { data: { OfferUnSaveBookmark } }) => {
        try {
          // const data = store.readQuery({
          //   query: GET_OFFERS,
          //   variables: { id: parseInt(id) },
          // });

          // data.offer.isBookmarked = false;

          store.writeQuery({
            query: gql`
              query Offer($id: ID) {
                offer(id: $id) {
                  id
                  isBookmarked
                }
              }
            `,
            data: {
              offer: {
                __typename: "Offer",
                id: OfferUnSaveBookmark.id,
                isBookmarked: OfferUnSaveBookmark.isBookmarked,
              },
            },
            variables: {
              id: id,
            },
          });
        } catch (e) { }
      },
    });
  };

  render() {
    return (
      <div className="space-y-5">
        {this.props?.data?.length > 0 ? (
          this.props.data
            .filter(o => o.isBookmarked !== false)
            .map(items => (
              <div className="box overflow-hidden mx-1 intro-y">
                <div className="p-5 pb-0 space-y-5">
                  <div className="flex items-center gap-x-3 intro-y">
                    <Avatarimage imagesrc={items?.user?.profile_photo} firstname={items?.user?.firstname} />
                    <div>
                      <Link
                        to={`/${encryptfunction(items.user.id)}/profile`}
                        state={{ value: "edit", id: items.user.id }}
                        className="SemiBold"
                      >
                        {`${items.user.firstname} ${items.user.lastname}`}
                      </Link>
                      <p className="Regular gray fs-11">
                        {`@${items.user.username}`}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center flex-wrap gap-2 ">
                    {items.tags.map(tag => (
                      <div className="bg-gray-100 hover:opacity-70 rounded-lg px-5 py-1.5 inline-block intro-x">
                        <p className="fs-13">{tag.name}</p>
                      </div>
                    ))}
                  </div>
                  <div>
                    <Link
                      to={`/rfq/${encryptfunction(items.id)}/${convertToSlug(
                        items.title
                      )}`}
                      state={{ alert: null }}
                      className="SemiBold fs-14 block intro-y"
                    >
                      {items.title}
                    </Link>
                  </div>
                  <div className="border-t py-3 intro-y flex items-center justify-between">
                    <div className="flex items-center gap-x-2">
                      <img
                        alt="messages"
                        src={messages}
                        className="h-7 w-7 cursor-pointer"
                      />
                      <p className="gray fs-13 Light">1</p>
                    </div>

                    {items.isBookmarked ? (
                      <div
                        onClick={() =>
                          this.removeBookmark(items.id, items.isBookmarked)
                        }
                        className="h-9 w-9 cursor-pointer absolute top-0 right-0 z-10 m-2 bg-[#ef553b] rounded-full flex items-center justify-center"
                      >
                        <BsBookmark className="text-white" />
                      </div>
                    ) : (
                      <div className="h-9 w-9 cursor-pointer absolute top-0 right-0 z-10 m-2 bg-gray-200 rounded-full flex items-center justify-center">
                        <BsBookmark className="gray" />
                      </div>
                    )}
                    {/* <div
                    onClick={() => this.removeBookmark(items.id)}
                    className="h-9 w-9 cursor-pointer absolute top-0 right-0 z-10 m-2 bg-[#ef553b] rounded-full flex items-center justify-center"
                  >
                    <BsBookmark className="text-white" />
                  </div> */}
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className="space-y-5">
            <div className="box overflow-hidden mx-1 intro-y">
              <div className="p-5 pb-0 space-y-5">
                <div className="flex items-center gap-x-3 intro-y">
                  <div className="flex items-center justify-center h-32 w-full">
                    <p className="items-center lg:text-2xl text-3xl Medium gray">
                      Bookmark your favorite RFQs to revisit them later
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
