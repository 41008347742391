import React, { Component } from "react";
import {
  Addressdialog,
  Fullscreenpopup,
  TeamsDialog,
  PurchaseHistoryDialog,
  Buttonlink,
  Avatarimage,
  PricingPopup,
  MoreCategoriesPopup,
  Loader,
  Nocontent,
  Noconnection,
  VerifyIcon,
} from "components";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineHistory, AiOutlineUserSwitch } from "react-icons/ai";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { encryptfunction, seo } from "helpers";
import Profileavatar from "../../../components/profileavatar";
import { FiEdit2, FiPower } from "react-icons/fi";
import { RiUserHeartLine } from "react-icons/ri";
import { BsBookmarks } from "react-icons/bs";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import {
  MdAddLocationAlt,
  MdOutlineControlPointDuplicate,
  MdOutlineEditLocationAlt,
  MdPassword,
} from "react-icons/md";
import client from "graphql/client";
import GET_USER_QUERY from "graphql/queries/getUser";
import updateCategoriesMutation from "../../../graphql/mutations/updateCategoriesMutation";
import updateStatus from "../../../graphql/mutations/changeRfqProductStatus";
import addKeyContactMutation from "graphql/mutations/addKeyContactMutation";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { AUTH_USER_MRO } from "../../../config/constants";
import BuyerAnalytic from "../../../components/buyeranalytic";
import { actions } from "../../../store/redux/RFQRedux";
import { Query } from "@apollo/client/react/components";
import { Pagenotfound } from "../../index";
import PREFERRED_VENDOR from "../../../graphql/queries/me";
import MySelectedRFQsAction from "../../../store/actions/MySelectedRFQsAction";
import addPrefferedVendorMutation from "../../../graphql/mutations/addPrefferedVendoMutation";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { IoCloseCircleOutline, IoTrashOutline } from "react-icons/io5";
import MyCurrentPageAction from "../../../store/actions/MyCurrentPageAction";
import AuthUserAction from "../../../store/actions/MyAuthUserAction";
import { toast } from "react-toastify";
import { ImLocation2 } from "react-icons/im";
import getPayment from "graphql/queries/getPayment";
import SwitchToggleTeams from "../../../components/listings/rfqcard/SwitchToggleTeams";
import { UPDATE_AUTH_USER_PROPERTY } from "store/actions/types";
import store from "store";
import { extractValidationErrors } from "helpers/extractValidationErrors ";
import { checkPlan } from "helpers/checkPlan";
import { Switch } from "@headlessui/react";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: "",
      catData: [],
      reloadStats: false,
      isOpen: false,
      idsArray: [],
      dataId: "",
      showform: false,
      payments: [],
      initialCategories: props?.categories?.category,
      // initialKeyContacts: props.auth_user?.keyContacts,
      // initialCategories: "",
      initialKeyContacts: "",
      isChecked: false,
      file_type: false,
      isAtBottom: false,
      toastVisibility: true,
      businessAddress: [],
    };
    this.baseState = this.state;
    this.selectFullPopup = React.createRef();
    this.addressDialogRef = React.createRef();
    this.teamsDialogRef = React.createRef();
    this.SelectPurchaseHistory = React.createRef();
    this.amount = React.createRef();
    this.pricingPopups = React.createRef();
    this.MoreCategoryPopup = React.createRef();
    this.timeout = 0;
  }

  componentDidMount() {
    const { selectTags, selectMembers, auth_user } = this.props;
    this.getPayments();
    selectTags({});
    //selectMembers([]);
    this.props.selectMembers(this.state.initialKeyContacts);
    this.loadVender();
    window.scrollTo(0, 0);

    const newObject = localStorage.getItem(AUTH_USER_MRO);
    const storageUser = JSON.parse(newObject);
    if (storageUser.is_seller) {
      this.addForm("category", storageUser?.categories);
    }
    seo({
      title: `${storageUser?.firstname
        ? storageUser?.firstname + " " + storageUser?.lastname
        : "User"
        } | Profile`,
    });

    MyCurrentPageAction.currentPageUrl("profile");
    this.setState({ file_type: auth_user.file_type });
  }
  handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);
    const bottomPosition = scrollBottom.toFixed(0);

    if (parseInt(bottomPosition) === 0) {
      // Perform your desired action here, e.g., fetch more data
    }
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.showCurrentPage !== "profile") {
      MyCurrentPageAction.currentPageUrl("profile");
    }
  }

  addForm = (key, value) => {
    let form = Object.assign({}, this.props.form);
    form[key] = value;
    this.props.addRfqForm(form);
    this.props.selectCategories(form);
  };

  modalopen = () => {
    this.setState({
      open: true,
    });
  };
  openViewDetailProf = (userid) => {
    this.setState(
      {
        isOpen: true,
      },
      () => {
        this.setState({ dataId: userid });
      }
    );
  };
  categorymodal = () => {
    this.setState({
      open: false,
    });
  };

  openPopupModal = () => {
    this.selectFullPopup?.current.openPopup();
  };
  openAddressDialog = () => {
    this.addressDialogRef.current.openPopup();
  };
  openTeamsDialog = (param) => {
    this.teamsDialogRef.current.openPopup(param);
  };
  openPurchaseHistoryDialog = () => {
    this.SelectPurchaseHistory.current.openPopup();
  };

  openPricing = () => {
    this.pricingPopups.current.openPopup();
  };

  openCategories = (data) => {
    let cateData = data?.offer?.categories;
    this.setState({
      catData: cateData,
    });

    this.MoreCategoryPopup.current.openPopup();
  };

  saveCategoryMutation = (category_ids) => {
    let areEqual = false;
    const idsArray = this.state?.initialCategories?.map((item) =>
      parseInt(item.id)
    );
    if (category_ids?.length !== idsArray?.length) {
    } else {
      // Check if all elements in both arrays are equal
      areEqual = category_ids.every(
        (element, index) => element === idsArray[index]
      );
    }
    let newObject = localStorage.getItem(AUTH_USER_MRO);
    let storageUser = JSON.parse(newObject);
    if (category_ids.length > 0 && !areEqual) {
      client
        .mutate({
          mutation: updateCategoriesMutation,
          variables: {
            categories: category_ids,
          },
        })
        .then((response) => {
          const idsArray = response.data?.updateCategories?.categories?.map(
            (item) => parseInt(item.id)
          );
          if (storageUser.is_seller) {
            this.setState({
              reloadStats: true,
              initialCategories: response.data?.updateCategories?.categories,
            });
            this.notify("Profile Updated Successfully !", "success");
          }
          // this.props.clearForm(() => { });
          // this.props.selectCategories([]);
        })
        .catch((err) => {
          console.log("err", err);
          extractValidationErrors(err).forEach((err) =>
            this.notify(err, "error")
          );
        });
    } else {
      if (category_ids.length <= 0) {
        this.notify("Please Select Atleast 1 category !", "error");
      }
    }
  };

  saveKeyContactMutation = () => {
    // const idArray = this.state.initialKeyContacts.map(item => parseInt(item.id));
    // let members_ids = idArray;
    // if (this.props?.members?.length > 0) {
    //   this.props?.members?.map((contact) => {
    //     members_ids.push(parseInt(contact.id));
    //   });
    // }
    // this.props.selectMembers(members_ids);
    let ids = [];
    if (this.props?.members.length !== 0) {
      ids = this.props?.members?.map((item) => parseInt(item.id));
    } else {
      const ids = [];
    }
    let newObject = localStorage.getItem(AUTH_USER_MRO);
    let storageUser = JSON.parse(newObject);
    if (ids.length > 0) {
      client
        .mutate({
          mutation: addKeyContactMutation,
          variables: {
            contact_ids: ids,
          },
        })
        .then((response) => {
          // this.setState({ initialKeyContacts: response.data.createUserContact })
          this.props.selectMembers([]);
          if (storageUser.is_seller) {
            this.setState({ reloadStats: true });
            this.notify("Profile Updated Successfully !", "success");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  loadVender = () => {
    client
      .query({
        query: PREFERRED_VENDOR,
        fetchPolicy: "network-only",
      })
      .then((responce) => {
        MySelectedRFQsAction.prefferedVendors(
          responce.data.me.preffered_vendors
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  likeVendor = (id) => {
    const canLikeVendor = checkPlan(
      "bool",
      "Select Preferred Vendor",
      this.props?.auth_user?.payments
    );

    if (!canLikeVendor) {
      if (this.state.toastVisibility) {
        this.setState({ toastVisibility: false });
        this.notify(
          "You are not allowed for this feature. Please upgrade your plan",
          "error"
        );

        setTimeout(() => {
          this.setState({ toastVisibility: true });
        }, 5000);
      }
    } else {
      client
        .mutate({
          mutation: addPrefferedVendorMutation,
          variables: {
            seller_id: [parseInt(id)],
            action: "like",
          },
        })
        .then((res) => {
          // console.log("res",res)
          this.loadVender();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  dislikeVendor = (id) => {
    client
      .mutate({
        mutation: addPrefferedVendorMutation,
        variables: {
          seller_id: [parseInt(id)],
          action: "dislike",
        },
      })
      .then((res) => {
        this.loadVender();
        // console.log("dislike", res);
        // MySelectedRFQsAction.removedVendor(parseInt(id));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error")
      return toast.error(message, { position: "top-right" });
    else return toast.success(message, { position: "top-right" });
  };
  getPayments = () => {
    client
      .query({
        query: getPayment,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        this.setState({ payments: res.data.me.payments });
        store.dispatch({
          type: UPDATE_AUTH_USER_PROPERTY,
          payload: { key: "payments", value: res.data.me.payments },
        });
      });
  };
  change = () => {
    this.setState((prevState) => ({
      isChecked: !prevState.isChecked,
    }));
  };
  handleAddressDialogClose = () => {
    this.setState({ reloadStats: true });
    // client.query({
    //   query: GET_USER_QUERY,
    //   variables: { id: this.props.id },
    //   fetchPolicy: 'no-cache',
    // }).then((res) => {
    //   this.setState({ businessAddress: res.data.user.addresses });
    // }).catch((err) => {
    //   console.error(err);
    // })
  };
  changeSetting = () => {
    this.setState(
      (prevState) => ({
        file_type: !prevState.file_type,
      }),
      () => {
        client
          .mutate({
            mutation: updateStatus,
            variables: {
              status: this.state.file_type,
              // is_closed: false,
            },
          })
          .then((response) => {
            // this.notify("Recommendation is closed successfully", "success");
            this.setState({ reloadStats: true });
            // this.setState({
            //   is_closed: true,
            // });
          });
      }
    );
  };

  handleAddressDialogClose = () => {
    this.setState({ reloadStats: true });
    // client.query({
    //   query: GET_USER_QUERY,
    //   variables: { id: this.props.id },
    //   fetchPolicy: 'no-cache',
    // }).then((res) => {
    //   this.setState({ businessAddress: res.data.user.addresses });
    // }).catch((err) => {
    //   console.error(err);
    // })
  };

  render() {
    const { value } = this.props;
    let newObject = localStorage.getItem(AUTH_USER_MRO);
    let storageUser = this.props.auth_user;
    let category_ids = [];
    if (this.props.form?.category) {
      this.props.form?.category?.map((category) => {
        category_ids.push(parseInt(category.id));
      });
    }
    const isUpgraded = checkPlan(
      "bool",
      "Seller Reviews",
      this.props?.auth_user?.payments
    );
    const maxCategoriesProfile = checkPlan(
      "number",
      "Categories on profile",
      this.props?.auth_user?.payments
    );
    const sellerSeeDetailProfile = checkPlan(
      "bool payment",
      "Enable detail profiles",
      this.props?.auth_user?.payments
    );
    const buyerSeeDetailProfile = checkPlan(
      "bool",
      "Extended Seller Profile",
      this.props?.auth_user?.payments
    );
    const canSwitchToPL = checkPlan(
      "bool",
      "Switch Profile PL - SG / SG - PL",
      this.props?.auth_user?.payments
    );
    // const idArray = this.state.initialKeyContacts.map(item => parseInt(item.id));
    // let members_ids = idArray;
    // if (this.props?.members?.length > 0) {
    //   this.props?.members?.map((contact) => {
    //     members_ids.push(parseInt(contact.id));
    //   });
    // }
    //this.props.selectMembers(members_ids);
    return (
      <>
        <Fullscreenpopup
          ref={this.selectFullPopup}
          type={this.state.open ? "Add Members" : "Choose Category"}
          clearState={(value) =>
            value === "Choose Category"
              ? this.saveCategoryMutation(category_ids)
              : value === "Add Members"
                ? this.saveKeyContactMutation()
                : null
          }
          maxValue={
            this.state.open === "Choose Category"
              ? null
              : // : value === "Add Members"
              //   ? this.saveKeyContactMutation()
              maxCategoriesProfile
          }
        />
        <Query
          query={GET_USER_QUERY}
          variables={{ id: this.props.id }}
          fetchPolicy="network-only"
        >
          {({ loading, error, data, refetch, fetchMore }) => {
            if (loading) return <Loader />;
            if (error) return <Noconnection />;
            if (this.state.reloadStats) {
              refetch();
              setTimeout(() => {
                this.setState({ reloadStats: false }, () => {
                  if (this.props.auth_user.id == this.props.id) {
                    AuthUserAction.getAuthUserInformation().then((res) => {
                      // if (res) {
                      //   this.notify(
                      //     "Profile Updated Successfully !",
                      //     "success"
                      //   );
                      // }
                    });
                  }
                });
              }, 10);
            }
            if (data) {
              if (data && data.user === null) {
                return <Pagenotfound />;
              }

              // console.log(this.props.auth_user);
              // console.log(data.user);
              if (
                this.props.auth_user.id !== data.user.id &&
                this.props.auth_user.is_seller === data.user.is_seller
              ) {
                return <Pagenotfound />;
              }
              let Check = [];
              let height = 600;
              let authUser = data.user;
              let businessAddress =
                this.state.businessAddress?.length !== 0
                  ? this.state.businessAddress
                  : data?.user?.addresses;
              const buyerReviews = authUser?.sellerReviews.filter(
                (review) => review.is_buyer !== true
              );

              if (this.props.auth_user.is_seller) {
                if (!this.state.isChecked) {
                  Check = buyerReviews;
                } else {
                  Check = authUser.sellerReviews.filter(
                    (review) => review.is_buyer === true
                  );
                }
              } else {
                Check = isUpgraded ? buyerReviews : buyerReviews.slice(0, 6);
              }
              if (Check.length < 5) {
                if (Check.length === 0) {
                  height = 150;
                } else {
                  height = Check.length * 100;
                }
              }
              height = height;
              const heightStyle = {
                height: height.toString() + "px",
              };

              // console.log('this.props.auth_user.is_seller', this.props.auth_user.is_seller);
              return (
                <>
                  {authUser?.is_seller ? (
                    <>
                      <div className="container my-20 space-y-20 ">
                        <div className="report-box">
                          <div className="intro-y box px-5 pt-5 mt-5 overflow-hidden">
                            <div className="flex items-center flex-col md:flex-row border-b border-gray-200 pb-5 -mx-5 px-5 ">
                              <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
                                <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative">
                                  <Profileavatar
                                    singleUser={authUser}
                                    sizeStyle={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    className="border"
                                  />
                                </div>
                                <div className="ml-5">
                                  <div className="flex items-center gap-x-1">
                                    <h4 className="text-2xl Medium capitalize">
                                      {authUser?.firstname} {authUser?.lastname}
                                    </h4>
                                    <div className="w-6 h-6">
                                      <VerifyIcon user={authUser} />
                                    </div>
                                    <div className="-mt-4 bg--lightPrimary rounded-md py-[1px] cursor-pointer flex items-center justify-center w-[55px] text-[12px]">
                                      Seller
                                    </div>
                                  </div>
                                  <div className="truncate sm:whitespace-normal Regular text-sm text-gray-600">
                                    @{authUser?.username}
                                  </div>
                                  {authUser &&
                                    authUser.state !== null &&
                                    authUser.country !== null ? (
                                    <p className="darkGray Light text-sm">
                                      {authUser.state.name},
                                      {authUser.country.name}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {storageUser?.id === this.props.id
                                ? sellerSeeDetailProfile &&
                                (canSwitchToPL ? (
                                  storageUser?.has_pl_account && (
                                    <div className="flex flex-col gap-2">
                                      <Buttonlink
                                        prefix={
                                          <HiOutlineSwitchHorizontal
                                            size={20}
                                            className="primary bg-white"
                                          />
                                        }
                                        href={`${process.env.REACT_APP_PL_WEB_URL}/auto-login/${this.props.auth_user?.cross_token}`}
                                        className="gap-x-2 white hover:opacity-80 hover:text-white w-48 ml-auto px-5 flex items-center bg--primary h-11 rounded-xl"
                                        text="Switch to PL"
                                      />
                                      <Buttonlink
                                        prefix={
                                          <FaRegArrowAltCircleUp
                                            size={20}
                                            className="primary bg-white"
                                          />
                                        }
                                        to={"/pricing/profile_systemplan"}
                                        className="gap-x-2 white hover:opacity-80 hover:text-white w-48 ml-auto px-5 flex items-center bg--primary h-11 rounded-xl"
                                        text="Upgrade Plan"
                                      />
                                    </div>
                                  )
                                ) : (
                                  <Buttonlink
                                    prefix={
                                      <HiOutlineSwitchHorizontal
                                        size={20}
                                        className="primary bg-white"
                                      />
                                    }
                                    onClick={() => {
                                      this.notify(
                                        "You don't have permission to perform this action. Please purchase a plan first",
                                        "error"
                                      );
                                    }}
                                    className="gap-x-2 white hover:opacity-80 hover:text-white w-max ml-auto px-5 flex items-center bg--primary h-11 rounded-xl"
                                    text="Switch to PL"
                                  />
                                ))
                                : null}
                              <div className="flex flex-col gap-3">
                                {storageUser?.id !== this.props.id &&
                                  this.props?.preffered_vendors.find(
                                    (o) => o.seller_id == this.props.id
                                  ) ? (
                                  <Buttonlink
                                    text="Remove Preferred Supplier"
                                    onClick={() =>
                                      this.dislikeVendor(authUser.id)
                                    }
                                    className="bg--primary text-white hover:text-white hover:opacity-80 flex items-center justify-center h-12 px-10 white rounded-full text-[15px] mt-10 md:mt-0"
                                  />
                                ) : !storageUser?.is_seller &&
                                  storageUser?.id !== this.props.id ? (
                                  <Buttonlink
                                    text="Add Preferred Supplier"
                                    onClick={() => this.likeVendor(authUser.id)}
                                    className="bg--gray text-white hover:text-white hover:opacity-80 flex items-center justify-center h-12 px-10 white rounded-full text-[15px] mt-10 md:mt-0"
                                  />
                                ) : null}
                                {/* {storageUser?.has_pl_account &&
                                  !storageUser?.is_seller &&
                                  storageUser?.id !== this.props.id && (
                                    <Buttonlink
                                      prefix={
                                        <HiOutlineSwitchHorizontal
                                          size={20}
                                          className="primary bg-white"
                                        />
                                      }
                                      href={`${
                                        process.env.REACT_APP_PL_WEB_URL
                                      }/sellers/${encryptfunction(
                                        authUser?.id
                                      )}/${authUser?.username}`}
                                      className="gap-x-2 white hover:opacity-80 hover:text-white w-max ml-auto px-5 flex items-center bg--primary h-11 rounded-xl"
                                      text="View PL Profile"
                                    />
                                  )} */}
                              </div>
                            </div>
                            {storageUser?.id === this.props.id && (
                              <div className="bg-white -mx-5 px-5 py-2 flex gap-x-5 flex-col sm:flex-row justify-center lg:justify-start">
                                <Link
                                  to={`/profile/${authUser?.username}`}
                                  className="bg-slate-100 intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center justify-between"
                                >
                                  <FiEdit2 size={14} className="primary" />
                                  <p className="">Edit Profile</p>
                                </Link>
                                <Link
                                  to="/change-password"
                                  className="bg-slate-100 intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center justify-between"
                                >
                                  <FiEdit2 size={14} className="primary" />
                                  <p className="">Change Password</p>
                                </Link>

                                <div
                                  onClick={this.openAddressDialog}
                                  className="bg-slate-100 cursor-pointer intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center justify-between"
                                >
                                  <MdAddLocationAlt
                                    size={18}
                                    className="primary "
                                  />
                                  <p className="">Business Address</p>
                                </div>

                                <Link
                                  to="/bookmarks"
                                  className="bg-slate-100 intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center sm:justify-between"
                                >
                                  <BsBookmarks size={15} className="primary" />
                                  <p className="">Bookmarks</p>
                                </Link>
                                <div
                                  onClick={this.openPurchaseHistoryDialog}
                                  className="bg-slate-100 cursor-pointer intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center sm:justify-between"
                                >
                                  <AiOutlineHistory
                                    size={20}
                                    className="primary "
                                  />
                                  <p className="">Purchase history</p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="intro-y mt-5">
                          <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 lg:col-span-6 space-y-5">
                              <div className="intro-y box">
                                <div className="flex items-center px-5 py-3 border-b border-gray-200">
                                  <h4 className="text-lg mr-auto">
                                    Introduction
                                  </h4>
                                  {storageUser?.id === this.props.id && (
                                    <div className="h-8 w-8 bg-slate-100 hover:bg-slate-200 flex items-center justify-center rounded-full cursor-pointer">
                                      <Link
                                        to={`/profile/${authUser?.username}`}
                                        state={{ authUser: authUser }}
                                      >
                                        <FiEdit2
                                          size={14}
                                          className="primary"
                                        />
                                      </Link>
                                    </div>
                                  )}
                                </div>
                                <div className="p-5">
                                  <p className="darkGray">
                                    {authUser && authUser.tagline}
                                  </p>
                                </div>
                              </div>

                              <div className="intro-y box">
                                <div className="flex items-center px-5 py-3 border-b border-gray-200">
                                  <h4 className="text-lg mr-auto">
                                    Key Contacts
                                  </h4>
                                  {storageUser?.id === this.props.id && (
                                    <div
                                      onClick={() => {
                                        this.openPopupModal();
                                        this.modalopen();
                                      }}
                                      className="h-8 w-8 bg-slate-100 hover:bg-slate-200 flex items-center justify-center rounded-full cursor-pointer"
                                    >
                                      <FiEdit2 size={14} className="primary" />
                                    </div>
                                  )}
                                </div>
                                <div className="p-5">
                                  <div className="space-y-5 ">
                                    {authUser?.keyContacts?.map(
                                      (user, index) => (
                                        <div
                                          className="flex items-center gap-2 intro-y"
                                          key={index}
                                        >
                                          <Profileavatar
                                            singleUser={user}
                                            sizeStyle={{
                                              width: 38,
                                              height: 38,
                                            }}
                                          />
                                          <p>
                                            {user.firstname} {user.lastname}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Reviews */}

                              <div className="intro-y box">
                                <div className="flex items-center justify-between px-5 py-3 border-b border-gray-200">
                                  {this.state.isChecked &&
                                    this.props.auth_user.is_seller ? (
                                    <h4 className="text-lg mr-auto">
                                      Reviews Given
                                    </h4>
                                  ) : (
                                    <h4 className="text-lg mr-auto">
                                      Buyer Reviews
                                    </h4>
                                  )}

                                  {this.props.auth_user.is_seller && (
                                    <Switch
                                      checked={this.state.isChecked}
                                      onChange={this.change}
                                      className={`${this.state.isChecked
                                        ? "bg--primary"
                                        : "bg--primary"
                                        }
          relative inline-flex flex-shrink-0 h-8 w-[54px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    >
                                      {/* <span className="sr-only">Use setting</span> */}
                                      <span
                                        className={`${this.state.isChecked
                                          ? "translate-x-7"
                                          : "translate-x-0"
                                          }
            pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                                      />
                                    </Switch>
                                  )}
                                </div>
                                <div
                                  className={`p-5 ${Check.length !== 0 && `overflow-y-auto`
                                    }`}
                                  style={heightStyle}
                                  onScroll={this.handleScroll}
                                >
                                  {Check?.length === 0 ? (
                                    <Nocontent text="Nothing here? Engage with more RFQs to boost yourself!" />
                                  ) : !storageUser?.is_seller &&
                                    storageUser?.id !== this.props.id &&
                                    !isUpgraded ? (
                                    Check.slice(0, 4).map((item, index) => {
                                      return (
                                        <div
                                          className={`mb-6 ${index >= 2 &&
                                            "blur-sm opacity-60 cursor-not-allowed"
                                            }`}
                                          onCopy={(e) => {
                                            index >= 2 && e.preventDefault();
                                            return false;
                                          }}
                                        >
                                          <h6 className="Medium fs-14 black mb-3">
                                            {item?.offer?.title}
                                          </h6>
                                          <div className="flex items-start justify-center intro-y lg:justify-start">
                                            <div className="image-fit relative">
                                              <Avatarimage
                                                imagesrc={
                                                  item.buyer.profile_photo
                                                }
                                                imagesize={{
                                                  width: 45,
                                                  height: 45,
                                                }}
                                              />
                                            </div>
                                            <div className="ml-5 flex-1">
                                              <h4 className="fs-13 Medium mb-2">
                                                {item.buyer.firstname}{" "}
                                                {item.buyer.lastname}
                                              </h4>
                                              <p className="fs-12 darkGray Regular">
                                                {item.comment}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    Check.map((item, index) => {
                                      return (
                                        <div
                                          className={`mb-6 ${index < -1 &&
                                            "blur-md opacity-60 cursor-not-allowed"
                                            }`}
                                          onCopy={(e) => {
                                            index < -1 && e.preventDefault();
                                            return false;
                                          }}
                                        >
                                          <h6 className="Medium fs-14 black mb-3">
                                            {item?.offer?.title}
                                          </h6>
                                          <div className="flex items-start justify-center intro-y lg:justify-start">
                                            <div className="image-fit relative">
                                              <Avatarimage
                                                imagesrc={
                                                  item.buyer.profile_photo
                                                }
                                                imagesize={{
                                                  width: 45,
                                                  height: 45,
                                                }}
                                              />
                                            </div>
                                            <div className="ml-5 flex-1">
                                              <h4 className="fs-13 Medium mb-2">
                                                {item.buyer.firstname}{" "}
                                                {item.buyer.lastname}
                                              </h4>
                                              <p className="fs-12 darkGray Regular">
                                                {item.comment}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })
                                  )}

                                  {!isUpgraded &&
                                    buyerReviews.length > 0 &&
                                    storageUser?.id !== this.props.id && (
                                      <div className="flex justify-end">
                                        <Buttonlink
                                          // onClick={this.openPricing}
                                          to={"/pricing/system"}
                                          text="Upgrade to see more"
                                          // title="See more"
                                          // rel="nofollow"
                                          className="primary hover:opacity-70"
                                        />
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-span-12 lg:col-span-6  space-y-5">
                              <div className="intro-y box">
                                <div className="flex items-center px-5 py-3 border-b border-gray-200">
                                  <h4 className="text-lg mr-auto">
                                    Categories
                                  </h4>
                                  {storageUser &&
                                    storageUser.id === this.props.id && (
                                      <div
                                        onClick={() => {
                                          this.openPopupModal();
                                          this.categorymodal();
                                        }}
                                        className="h-8 w-8 bg-slate-100 hover:bg-slate-200 flex items-center justify-center rounded-full cursor-pointer"
                                      >
                                        <FiEdit2
                                          size={14}
                                          className="primary"
                                        />
                                      </div>
                                    )}
                                </div>
                                <div className="p-5">
                                  <div className="flex items-center flex-wrap gap-2 ">
                                    {authUser?.categories?.map((category) => (
                                      <div
                                        className="bg-gray-200 hover:opacity-70 rounded-lg px-5 py-2 inline-block intro-x"
                                        key={category.id}
                                      >
                                        <p className="fs-13">
                                          {" "}
                                          {category.name}{" "}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <div className="intro-y box">
                                <div className="flex items-center px-5 py-3 border-b border-gray-200">
                                  <h4 className="text-lg mr-auto">Address</h4>
                                  {storageUser &&
                                    storageUser.id === this.props.id && (
                                      <div
                                        onClick={this.openAddressDialog}
                                        className="h-8 w-8 bg-slate-200 flex items-center justify-center rounded-full cursor-pointer"
                                      >
                                        <FiEdit2
                                          size={14}
                                          className="primary"
                                        />
                                      </div>
                                    )}
                                </div>

                                <div className="p-5">
                                  {businessAddress?.map(
                                    (address, index) =>
                                      address.is_primary && (
                                        <div
                                          className="flex items-center justify-between my-4"
                                          key={address.id}
                                        >
                                          <div
                                            div
                                            className="flex items-center gap-x-3"
                                          >
                                            <ImLocation2 className="darkGray" />
                                            <p className="darkGray break-all fs-14">
                                              {address.name},{" "}
                                              {address.address_1},{" "}
                                              {address.city},{" "}
                                              {address.state?.name},{" "}
                                              {address.zip_code}
                                            </p>
                                          </div>
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>

                              {/* Ratings */}

                              <div className="intro-y box">
                                <div className="flex items-center px-5 py-3 border-b border-gray-200">
                                  <h4 className="text-lg mr-auto">Ratings</h4>
                                </div>
                                <div
                                  className={`p-5 ${Check.length !== 0 && `overflow-y-auto`
                                    }`}
                                  style={heightStyle}
                                >
                                  {Check?.length === 0 ? (
                                    <Nocontent text="Nothing here? Engage with more RFQs to boost yourself!" />
                                  ) : !storageUser?.is_seller &&
                                    storageUser?.id !== this.props.id &&
                                    !isUpgraded ? (
                                    Check.slice(0, 4).map(
                                      (seller_review, index) => {
                                        return (
                                          <>
                                            <div
                                              className={`flex justify-between mb-4 ${index >= 2 &&
                                                "blur-sm opacity-60 cursor-not-allowed"
                                                }`}
                                              onCopy={(e) => {
                                                index >= 2 &&
                                                  e.preventDefault();
                                                return false;
                                              }}
                                            >
                                              <div>
                                                <p className="Medium fs-14 mb-3">
                                                  {seller_review?.offer?.title}
                                                </p>
                                                <div className="gap-2 flex items-center selected-tags mb-3">
                                                  {seller_review?.offer
                                                    ?.categories?.length > 0 &&
                                                    seller_review.offer.categories
                                                      .slice(0, 2)
                                                      .map((cat) => {
                                                        return (
                                                          <>
                                                            <div className="relative intro-x">
                                                              <label className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                                                <p className="fs-11">
                                                                  {cat.name}
                                                                </p>
                                                              </label>
                                                            </div>
                                                          </>
                                                        );
                                                      })}
                                                  {seller_review?.offer
                                                    ?.categories?.length <
                                                    2 ? null : (
                                                    <div>
                                                      <BsThreeDots
                                                        size={20}
                                                        onClick={() =>
                                                          this.openCategories(
                                                            seller_review
                                                          )
                                                        }
                                                        className="text-gray-400 hover:text-gray-900"
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="gap-2 flex items-center selected-tags">
                                                  {seller_review?.offer?.tags
                                                    ?.length > 0 &&
                                                    seller_review?.offer?.tags?.map(
                                                      (tag) => {
                                                        return (
                                                          <div className="relative intro-x">
                                                            <label className="bg-gray-200 rounded-lg border-gray-200 hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                                              <p className="fs-11">
                                                                {tag.name}
                                                              </p>
                                                            </label>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                              <div className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center fs-14 w-[50px] h-[32px]">
                                                {seller_review.rate}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    Check.map((seller_review, index) => {
                                      return (
                                        <>
                                          <div
                                            className={`flex justify-between mb-4 ${authUser.is_plan_expired &&
                                              index < -1 &&
                                              "blur-md opacity-60 cursor-not-allowed"
                                              }`}
                                            onCopy={(e) => {
                                              authUser?.is_plan_expired &&
                                                index < -1 &&
                                                e.preventDefault();
                                              return false;
                                            }}
                                          >
                                            <div>
                                              <p className="Medium fs-14 mb-3">
                                                {seller_review?.offer?.title}
                                              </p>
                                              <div className="gap-2 flex items-center selected-tags mb-3">
                                                {seller_review?.offer
                                                  ?.categories?.length > 0 &&
                                                  seller_review.offer.categories
                                                    .slice(0, 2)
                                                    .map((cat) => {
                                                      return (
                                                        <>
                                                          <div className="relative intro-x">
                                                            <label className="bg--lightPrimary rounded-lg border--primary hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                                              <p className="fs-11">
                                                                {cat.name}
                                                              </p>
                                                            </label>
                                                          </div>
                                                        </>
                                                      );
                                                    })}
                                                {seller_review?.offer
                                                  ?.categories?.length <
                                                  2 ? null : (
                                                  <div>
                                                    <BsThreeDots
                                                      size={20}
                                                      onClick={() =>
                                                        this.openCategories(
                                                          seller_review
                                                        )
                                                      }
                                                      className="text-gray-400 hover:text-gray-900"
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                              <div className="gap-2 flex items-center selected-tags">
                                                {seller_review?.offer?.tags
                                                  ?.length > 0 &&
                                                  seller_review?.offer?.tags?.map(
                                                    (tag) => {
                                                      return (
                                                        <div className="relative intro-x">
                                                          <label className="bg-gray-200 rounded-lg border-gray-200 hover:opacity-80 px-4 py-1.5 cursor-pointer flex items-center gap-x-2">
                                                            <p className="fs-11">
                                                              {tag.name}
                                                            </p>
                                                          </label>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                            <div className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center fs-14 w-[50px] h-[32px]">
                                              {seller_review.rate}
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                  )}
                                  {!isUpgraded &&
                                    buyerReviews.length > 0 &&
                                    storageUser?.id !== this.props.id && (
                                      <div className="flex justify-end">
                                        <Buttonlink
                                          // onClick={this.openPricing}
                                          to={"/pricing/system"}
                                          text="Upgrade to see more"
                                          // title="See more"
                                          // rel="nofollow"
                                          className="primary hover:opacity-70"
                                        />
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-center gap-5">
                          {storageUser.id === authUser.id ? (
                            !sellerSeeDetailProfile ? (
                              <Buttonlink
                                text="Enable Details"
                                to={"/pricing/profile_systemplan"}
                                // onClick={() => this.setState({ isOpen: true })}
                                className="bg--primary text-white hover:text-white hover:opacity-80 flex items-center justify-center h-12 w-[200px] white rounded-full text-[15px]"
                              />
                            ) : (
                              <Buttonlink
                                text="View Detail Profile"
                                href={`${process.env.REACT_APP_PL_WEB_URL}/auto-login/${this.props.auth_user?.cross_token}`}
                                /* onClick={() => {
                                   this.openViewDetailProf("userId");
                                 }}*/
                                className="bg--primary text-white hover:text-white hover:opacity-80 flex items-center justify-center h-12 w-[200px] white rounded-full text-[15px]"
                              />
                            )
                          ) : buyerSeeDetailProfile ? (
                            <Buttonlink
                              text="View Detail Profile"
                              href={`${process.env.REACT_APP_PL_WEB_URL
                                }/seller/${encryptfunction(authUser.id)}/${authUser.username
                                }`}
                              // onClick={() => this.setState({ isOpen: true })}
                              /*   onClick={() => {
                                   this.openViewDetailProf("userId");
                                 }}*/
                              className="bg--primary text-white hover:text-white hover:opacity-80 flex items-center justify-center h-12 w-[200px] white rounded-full text-[15px]"
                            />
                          ) : (
                            <Buttonlink
                              text="View Detail Profile"
                              // href={`${process.env.REACT_APP_PL_WEB_URL}/seller/${encryptfunction(authUser.id)}/${authUser.username}`}
                              onClick={() => this.setState({ isOpen: true })}
                              className="bg--gray text-white hover:text-white hover:opacity-80 flex items-center justify-center h-12 w-[200px] white rounded-full text-[15px]"
                            />
                          )}
                        </div>
                      </div>
                      <MoreCategoriesPopup
                        ref={this.MoreCategoryPopup}
                        data={this.state.catData}
                        type={"More Categories"}
                      />
                      <PricingPopup ref={this.pricingPopups} />
                      <Addressdialog
                        ref={this.addressDialogRef}
                        onClose={this.handleAddressDialogClose}
                      />
                      <PurchaseHistoryDialog
                        ref={this.SelectPurchaseHistory}
                        payments={this.state?.payments}
                      />
                    </>
                  ) : (
                    <>
                      <div className="my-20 space-y-5 container">
                        {value === "edit" ? (
                          <div className="report-box">
                            <div className="intro-y box px-5 pt-5 mt-5 overflow-hidden">
                              <div className="flex flex-col lg:flex-row border-b border-gray-200 pb-5 overflow-hidden">
                                <div className="flex flex-1 items-center justify-center lg:justify-start">
                                  <div className="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-28 lg:h-28 image-fit relative">
                                    <Profileavatar
                                      singleUser={authUser && authUser}
                                      sizeStyle={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      className={"rounded-full min-w-[100px] min-h-[100px]"}
                                    />
                                  </div>

                                  <div className="ml-5 flex-grow">
                                    <div className="text-2xl Regular leading-8 inline-block">
                                      {authUser && authUser.firstname}
                                      {authUser && authUser.lastname}
                                    </div>
                                    <div className="w-5 h-5 inline-block ml-1">
                                      <VerifyIcon user={authUser} />
                                    </div>
                                    <div className="truncate sm:whitespace-normal Regular text-md text-gray-600">
                                      @{authUser && authUser.username}
                                    </div>
                                    <div className="truncate sm:whitespace-normal Regular text-sm text-gray-500">
                                      Role: Buyer
                                    </div>
                                  </div>
                                </div>
                                {storageUser &&
                                  storageUser.id === this.props.id && (
                                    <div>
                                      <Buttonlink
                                        prefix={<FiPower />}
                                        to={"/deactivate-account"}
                                        className="gap-x-2 primary hover:opacity-80 flex-1 grow w-full flex items-center justify-center h-11 rounded-xl"
                                        text="Deactivate your account"
                                      />
                                      <div className="flex flex-row-reverse items-center gap-x-3">
                                        <Switch
                                          checked={this.state.file_type}
                                          onChange={this.changeSetting}
                                          className={`${this.state.file_type
                                            ? "bg--primary"
                                            : "bg--lightGray"
                                            }
            relative inline-flex flex-shrink-0 h-8 w-[54px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                                        >
                                          {/* <span className="sr-only">Use setting</span> */}
                                          <span
                                            className={`${this.state.file_type
                                              ? "translate-x-7"
                                              : "translate-x-0"
                                              }
              pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
                                          />
                                        </Switch>
                                        <p className="fs-15 Regular">
                                          {this.state.file_type ? 'Use self-made file for multiple product RFQ' : 'Use system generated file for multiple product RFQ'}
                                        </p>
                                      </div>
                                      {/* {canSwitchToPL ? (
                                        storageUser?.has_pl_account && (
                                          <Buttonlink
                                            prefix={<HiOutlineSwitchHorizontal size={20} className="primary bg-white" />}
                                            href={`${process.env.REACT_APP_PL_WEB_URL}/auto-login/${this.props.auth_user?.cross_token}`}
                                            className="gap-x-2 white hover:opacity-80 hover:text-white w-max ml-auto px-5 flex items-     center bg--primary h-11 rounded-xl"
                                            text="Switch to PL"
                                          />
                                        )
                                      ) : (
                                        <Buttonlink
                                          prefix={<HiOutlineSwitchHorizontal size={20} className="primary bg-white" />}
                                          onClick={() => {
                                            this.notify("You don't have permission to perform this action. Please purchase a plan first", "error");
                                          }}
                                          className="gap-x-2 white hover:opacity-80 hover:text-white w-max ml-auto px-5 flex items-center bg--primary h-11 rounded-xl"
                                          text="Switch to PL"
                                        />
                                      )} */}
                                    </div>
                                  )}
                              </div>

                              {storageUser &&
                                storageUser.id === this.props.id && (
                                  <div className="bg-white -mx-5 px-5 py-2 flex gap-x-5 space-y-4 sm:space-y-0 flex-col sm:flex-row justify-center lg:justify-start">
                                    <Link
                                      to={`/profile/${authUser && authUser.username
                                        }`}
                                      state={{ authUser: authUser && authUser }}
                                      className="bg-slate-100 intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center sm:justify-between"
                                    >
                                      <FiEdit2 size={14} className="primary" />
                                      <p className="">Edit Profile</p>
                                    </Link>

                                    <Link
                                      to="/change-password"
                                      className="bg-slate-100 intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center sm:justify-between"
                                    >
                                      <MdPassword
                                        size={14}
                                        className="primary"
                                      />
                                      <p className="">Change Password</p>
                                    </Link>
                                    <div
                                      onClick={() => {
                                        this.props.selectMembers([]);
                                        this.openTeamsDialog(["Team", false]);
                                      }}
                                      className="bg-slate-100 cursor-pointer intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center sm:justify-between"
                                    >
                                      <MdOutlineControlPointDuplicate
                                        size={22}
                                        className="primary "
                                      />
                                      <p className="">My Teams</p>
                                    </div>
                                    <Link
                                      to="/preferred-vendor"
                                      className="bg-slate-100 intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center justify-between"
                                    >
                                      <RiUserHeartLine
                                        size={16}
                                        className="primary"
                                      />
                                      <p className="">Preferred Vendor</p>
                                    </Link>
                                    {/* <Link
                          to="/rfq-ledger"
                          className="bg-slate-100 intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center justify-between"
                        >
                          <SiKnowledgebase size={16} className="primary" />
                          <p className="">My RFQ Ledger</p>
                        </Link> */}
                                    <Link
                                      to="/bookmarks"
                                      className="bg-slate-100 intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center sm:justify-between"
                                    >
                                      <BsBookmarks
                                        size={15}
                                        className="primary"
                                      />
                                      <p className="">Bookmarks</p>
                                    </Link>
                                    <div
                                      onClick={this.openPurchaseHistoryDialog}
                                      className="bg-slate-100 cursor-pointer intro-y hover:bg-slate-200 px-4 rounded-xl h-12 flex gap-x-3 items-center sm:justify-between"
                                    >
                                      <AiOutlineHistory
                                        size={20}
                                        className="primary "
                                      />
                                      <p className="">Purchase history</p>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                        {storageUser && storageUser.id === this.props.id && (
                          <BuyerAnalytic />
                        )}
                      </div>
                      <TeamsDialog
                        ref={this.teamsDialogRef}
                        from={"profile"}
                        clearCompany={(e) => console.log("value", e)}
                        companyCreated={(e) => {
                          this.notify("Team added Successfully", "success");
                          this.getPayments();
                        }}
                        offerHasRoles={(v) => console.log("offerHasRoles", v)}
                      />
                      <PurchaseHistoryDialog
                        ref={this.SelectPurchaseHistory}
                        payments={storageUser.payments}
                      />
                    </>
                  )}
                </>
              );
            } else {
              return <Pagenotfound />;
            }
          }}
        </Query>
        <div>
          <Dialog
            fullWidth={false}
            maxWidth={"sm"}
            scroll="body"
            open={this.state.isOpen}
            onClose={() => this.setState({ isOpen: false })}
            className="rounded-2xl"
          >
            <div className="shadow-none bg-gray-100">
              <Toolbar className="flex items-center justify-between">
                <h3 className="black Medium text-xl">{this.props.type}</h3>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => this.setState({ isOpen: false })}
                  aria-label="close"
                >
                  <IoCloseCircleOutline size={32} />
                </IconButton>
              </Toolbar>
            </div>
            <div className="p-6">
              {this.state.dataId === "userId" ? (
                <div>
                  <div className="flex items-center justify-between px-6 pt-6 pb-8 gap-4 ">
                    <Buttonlink
                      onClick={true}
                      text="Account exist?"
                      className="bg--primary text-white hover:text-white hover:opacity-80 px-2 flex items-center justify-center h-12 w-[160px] white rounded-xl "
                    />
                    <Buttonlink
                      onClick={() => this.setState({ showform: true })}
                      text="Create an account"
                      className="bg--primary text-white hover:text-white hover:opacity-80 px-2 flex items-center justify-center h-12 w-[160px] white rounded-xl "
                    />
                  </div>
                  {this.state.showform && (
                    <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4 ">
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          for="username"
                        >
                          Username
                        </label>
                        <div className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                          {this.props.auth_user.username}
                        </div>
                      </div>
                      <div className="mb-4 flex justify-between">
                        <div className="w-[45%]">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            for="username"
                          >
                            First Name
                          </label>
                          <div className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {this.props.auth_user.firstname}
                          </div>
                        </div>
                        <div className="w-[45%]">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            for="username"
                          >
                            Last Name
                          </label>
                          <div className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                            {this.props.auth_user.lastname}
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          for="username"
                        >
                          Email
                        </label>
                        <div className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                          {this.props.auth_user.email}
                        </div>
                      </div>
                      <div className="mb-6">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          for="password"
                        >
                          Password
                        </label>
                        <input
                          className=" appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                          id="password"
                          type="password"
                          placeholder="Type password here "
                        />
                      </div>
                      <div className="flex items-center justify-center">
                        <button
                          className="bg--primary text-white hover:text-white hover:opacity-80  py-2 w-[150px] rounded-xl focus:outline-none focus:shadow-outline"
                          type="button"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              ) : (
                <div className="mt-2">
                  {/*<img
                    src={notfound}
                    alt="notfound"
                    className="max-h-[300px] w-full object-contain"
                  />*/}
                  <p className="text-[22px] text-center text-gray-500">
                    Sorry, you don't have permission to perform this action.
                    Please consider upgrading your plan to access this feature.
                  </p>
                </div>
              )}
            </div>
          </Dialog>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ Rfq, RFQs, Auth, Page }) => {
  return {
    auth_user: Auth.authUserInformation,
    form: Rfq.form,
    members: Rfq.members,
    categories: Rfq.categories,
    preffered_vendors: RFQs.prefferedVendors,
    showCurrentPage: Page.url,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("store/redux/RFQRedux");
  return {
    ...ownProps,
    ...stateProps,
    selectCategories: (data) => {
      dispatch(actions.selectCategories(data));
    },
    addRfqForm: (data) => {
      dispatch(actions.addRfqForm(data));
    },
    clearForm: () => {
      dispatch(actions.clearForm());
    },
    selectTags: (data) => {
      dispatch(actions.selectTags(data));
    },
    selectMembers: (data) => {
      dispatch(actions.selectMembers(data));
    },
  };
};
export default connect(mapStateToProps, null, mergeProps)(Profile);
