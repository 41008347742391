import React, { Component, Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import { Menu, Listbox, Tab, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import {
  Avatarimage,
  Alertpopup,
  Noconnection,
  ChatPopup,
  Tooltips,
} from "components";
import { Loader, Buttonlink } from "components";
import SocialShare from "components/socialShare";
import {
  AiOutlineCloudDownload,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { FiX } from "react-icons/fi";
import { Popup } from "semantic-ui-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "graphql/client";
import offerParticipantMutation from "graphql/mutations/offerParticipantMutation";
import getOfferWinner from "graphql/queries/getOfferWinner";
import { Query } from "@apollo/client/react/components";
import { Link, Navigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";
import offerPermissionMutation from "graphql/mutations/offerPermissionMutation";
import offerParticipantCancel from "../../../graphql/mutations/offerParticipantCancel";
import { encryptfunction } from "helpers";
import OFFER_CLOSED from "../../../graphql/mutations/offerClosedMutation";
import GET_RATTING from "../../../graphql/mutations/createReviewMutation";
import Auth from "../../../Auth";
import getOffer from "graphql/queries/getOffer";
import { connect } from "react-redux";
import { VscFilePdf } from "react-icons/vsc";
import Profileavatar from "../../../components/profileavatar";
import Moment from "react-moment";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { IoCloseCircleOutline } from "react-icons/io5";
import { extractValidationErrors } from "helpers/extractValidationErrors ";
import ProductParticipents from "./ProductParticipents";
import ExecutivePermissions from "./ExecutivePermissions";
import { IoIosArrowDown } from "react-icons/io";
import ImageDisplay from "components/Imagedisplay";

const auth = new Auth();
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const filters = [{ name: "Users" }, { name: "Price" }];

class RFQdetailstabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shareOptions: false,
      loginOptions: false,
      redirect: false,
      ratingValue: 0,
      supplierRating: 0,
      responsiveRfq: 0,
      abilityShip: 0,
      competitive: 0,
      delivered: 0,
      compliance: 0,
      chatPopup: false,
      openModal:
        props?.alert?.message === "Send you new message" ? true : false,
      receiver_id: 0,
      is_closed: props.rfqDetail.is_closed,
      Reviews: "",
      is_revision: true,
      is_reject: false,
      is_message: false,
      selectedIndex: 1,
      rfqDetail: this.props.rfqDetail,
      offerAcceptLoader: false,
      disabled: true,
      permissionDisabled: false,
      id: props.id,
      room_id: props.id,
      executive_permissions: [],
      itemId: "",
      isRejectToastVisible: false,
      isReviseToastVisible: false,
      filter: null,
      selectProduct: null,
      loader: false,
    };
    this.images = {
      "3ds": require("../../../assets/icon/3ds.png"),
      aac: require("../../../assets/icon/aac.png"),
      ai: require("../../../assets/icon/ai.png"),
      avi: require("../../../assets/icon/avi.png"),
      bmp: require("../../../assets/icon/bmp.png"),
      cad: require("../../../assets/icon/cad.png"),
      csv: require("../../../assets/icon/csv.png"),
      cdr: require("../../../assets/icon/cdr.png"),
      css: require("../../../assets/icon/css.png"),
      dat: require("../../../assets/icon/dat.png"),
      dll: require("../../../assets/icon/dll.png"),
      dmg: require("../../../assets/icon/dmg.png"),
      doc: require("../../../assets/icon/doc.png"),
      eps: require("../../../assets/icon/eps.png"),
      fla: require("../../../assets/icon/fla.png"),
      flv: require("../../../assets/icon/flv.png"),
      gif: require("../../../assets/icon/gif.png"),
      html: require("../../../assets/icon/html.png"),
      indd: require("../../../assets/icon/indd.png"),
      iso: require("../../../assets/icon/iso.png"),
      jpg: require("../../../assets/icon/jpg.png"),
      jpeg: require("../../../assets/icon/jpeg.png"),
      js: require("../../../assets/icon/js.png"),
      midi: require("../../../assets/icon/midi.png"),
      mov: require("../../../assets/icon/mov.png"),
      mp3: require("../../../assets/icon/mp3.png"),
      mpg: require("../../../assets/icon/mpg.png"),
      pdf: require("../../../assets/icon/pdf.png"),
      php: require("../../../assets/icon/php.png"),
      png: require("../../../assets/icon/png.png"),
      ppt: require("../../../assets/icon/ppt.png"),
      pptx: require("../../../assets/icon/pptx.png"),
      ps: require("../../../assets/icon/ps.png"),
      psd: require("../../../assets/icon/psd.png"),
      raw: require("../../../assets/icon/raw.png"),
      sql: require("../../../assets/icon/sql.png"),
      svg: require("../../../assets/icon/svg.png"),
      tif: require("../../../assets/icon/tif.png"),
      txt: require("../../../assets/icon/txt.png"),
      text: require("../../../assets/icon/txt.png"),
      wmv: require("../../../assets/icon/wmv.png"),
      xls: require("../../../assets/icon/xls.png"),
      xlsx: require("../../../assets/icon/xls.png"),
      xml: require("../../../assets/icon/xml.png"),
      zip: require("../../../assets/icon/zip.png"),
      unkown: require("../../../assets/icon/unkown.png"),
    };

    this.alertPopupRef = React.createRef();
  }

  handleMenuItemClick = (index, id) => {
    let isRevision = false;
    let isReject = false;
    let isMessage = false;

    if (index === 1) {
      isRevision = true;
      this.setState({
        is_revision: isRevision,
        is_reject: isReject,
        is_message: isMessage,
        itemId: id,
      });
      toast.dismiss();
      return toast.warn(
        <div>
          <p>
            Are you certain that you want to proceed with the Revision? This
            action cannot be undone.
          </p>
          <button
            className={
              "h-10 px-3 ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
            }
            onClick={() => {
              this.quotationRevised(id);
            }}
          >
            Yes
          </button>
          <button
            className={
              "inline-flex px-3 ml-2 items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray"
            }
            onClick={() => {
              toast.dismiss();
            }}
          >
            No
          </button>
        </div>
      );
    } else if (index === 2) {
      isReject = true;
      this.setState({
        is_revision: isRevision,
        is_reject: isReject,
        is_message: isMessage,
        itemId: id,
      });
      toast.dismiss();
      return toast.warn(
        <div>
          <p>
            Are you certain that you want to proceed with the rejection? This
            action cannot be undone.
          </p>
          <button
            className={
              "h-10 px-3 ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
            }
            onClick={() => {
              this.rejectRfqReq(id);
              toast.dismiss();
            }}
          >
            Yes
          </button>
          <button
            className={
              "inline-flex px-3 ml-2 items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray"
            }
            onClick={() => {
              toast.dismiss();
            }}
          >
            No
          </button>
        </div>
      );
    } else if (index === 3) {
      isMessage = true;
      this.openChatPopup(id);
    }
    if (this.state.rfqDetail.participants.data.some((item) => item.id === id)) {
      this.setState({
        is_revision: isRevision,
        is_reject: isReject,
        is_message: isMessage,
        itemId: id,
      });
    }
  };

  doNothing = () => { };

  quotationRevised = (id) => {
    if (this.state.offerAcceptLoader) {
      return; // Prevent multiple clicks while the request is in progress
    }
    this.setState({
      offerAcceptLoader: true,
    });
    client
      .mutate({
        mutation: offerParticipantMutation,
        variables: {
          id: id,
          status: 1,
        },
      })
      .then((res) => {
        //console.log("Quotation Revised Response", res);
        this.notify(
          "Revision sent! Your revision request has been sent to this user.",
          "success"
        );
        this.getOfferByID();
      })
      .catch((err) => {
        console.log("Quotation Revised Error", err);
        extractValidationErrors(err).forEach((error) =>
          this.notify(error, "error")
        );
        this.getOfferByID();
      })
      .finally(() => {
        this.setState({
          offerAcceptLoader: false,
        });
      });
  };

  quotationCanceled = (id) => {
    client
      .mutate({
        mutation: offerParticipantCancel,
        variables: {
          id: id,
        },
      })
      .then((res) => {
        this.notify("Quotation Expected Request is rejected.", "info");
      })
      .catch((err) => {
        console.log("Quotation Canceled Error", err);
        this.notify(
          "Expected Request not send! Please try again later.",
          "error"
        );
      });
  };

  rejectRfqReq = (id) => {
    if (this.state.offerAcceptLoader) {
      return; // Prevent multiple clicks while the request is in progress
    }
    this.setState({
      offerAcceptLoader: true,
    });
    client
      .mutate({
        mutation: offerParticipantMutation,
        variables: {
          id: id,
          status: 3,
        },
      })
      .then((res) => {
        //this.updateData("reject");
        this.notify("You have rejected this User!", "success");
        this.props.onBlock();
        this.getOfferByID();
      })
      .catch((err) => {
        extractValidationErrors(err).forEach((error) =>
          this.notify(error, "error")
        );
        this.getOfferByID();
        // this.notify("Error in rejected this User!", "error");
      })
      .finally(() => {
        this.setState({
          offerAcceptLoader: false,
        });
      });
  };

  quotationApproved = (id, productId) => {
    if (this.state.offerAcceptLoader) {
      return; // Prevent multiple clicks while the request is in progress
    }
    this.setState({
      offerAcceptLoader: true,
    });
    client
      .mutate({
        mutation: offerParticipantMutation,
        variables: {
          id: id,
          status: 2,
          product_id: productId,
        },
      })
      .then((res) => {
        if (this.props.socket?.connected) {
          this.props?.socket?.emit("notify", "Notification data");
        }
        // console.log("Quotation Accepted Response", res);
        this.notify("You approved this proposal!", "success");
        this.getOfferByID();
      })
      .catch((err) => {
        console.log("Quotation Acceptance Error", err);
        extractValidationErrors(err).forEach((error) =>
          this.notify(error, "error")
        );
        this.getOfferByID();

        // this.notify(
        //   "Revision didn't accepted! Please try again later.",
        //   "error"
        // );
      })
      .finally(() => {
        this.setState({
          offerAcceptLoader: false,
        });
      });
  };

  notify = (message = "", type = "error") => {
    // Dismiss any existing toasts before showing a new one
    toast.dismiss();
    if (type === "error") {
      return toast.error(message, { position: "top-right" });
    } else if (type === "info") {
      return toast.info(message, { position: "top-right" });
    } else {
      return toast.success(message, { position: "top-right" });
    }
  };

  reviewsRatting = (e) => {
    this.setState({
      Reviews: e.target.value,
    });
    setTimeout(() => {
      if (this.state.Reviews.length === 0) {
        this.setState({ disabled: true });
      }
    });
  };

  renderWinner = (offer, item, key) => {
    if (!item.user) return null;
    /*   const ratingSetting = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      value: this.state.ratingValue,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={26} />,
      halfIcon: <FaStarHalfAlt size={26} />,
      filledIcon: <FaStar size={26} />,
      onChange: (newValue) => {
        console.log(this.setState({ ratingValue: newValue }));
      },
    };*/

    const supplierRating = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      value: this.state.supplierRating,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={22} />,
      halfIcon: <FaStarHalfAlt size={22} />,
      filledIcon: <FaStar size={22} />,
      onChange: (newValue) => {
        this.setState({ supplierRating: newValue });
      },
    };

    const supplierReview = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      edit: false,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={22} />,
      halfIcon: <FaStarHalfAlt size={22} />,
      filledIcon: <FaStar size={22} />,
    };

    const responsiveRfq = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      value: this.state.responsiveRfq,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={22} />,
      halfIcon: <FaStarHalfAlt size={22} />,
      filledIcon: <FaStar size={22} />,
      onChange: (newValue) => {
        this.setState({ responsiveRfq: newValue });
      },
    };

    const abilityShip = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      value: this.state.abilityShip,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={22} />,
      halfIcon: <FaStarHalfAlt size={22} />,
      filledIcon: <FaStar size={22} />,
      onChange: (newValue) => {
        this.setState({ abilityShip: newValue });
      },
    };

    const competitive = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      value: this.state.competitive,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={22} />,
      halfIcon: <FaStarHalfAlt size={22} />,
      filledIcon: <FaStar size={22} />,
      onChange: (newValue) => {
        this.setState({ competitive: newValue });
      },
    };

    const delivered = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      value: this.state.delivered,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={22} />,
      halfIcon: <FaStarHalfAlt size={22} />,
      filledIcon: <FaStar size={22} />,
      onChange: (newValue) => {
        this.setState({ delivered: newValue });
      },
    };

    const compliance = {
      count: 5,
      color: "#d1d5db",
      activeColor: "#ffd700",
      value: this.state.compliance,
      a11y: true,
      isHalf: true,
      emptyIcon: <FaRegStar size={22} />,
      halfIcon: <FaStarHalfAlt size={22} />,
      filledIcon: <FaStar size={22} />,
      onChange: (newValue) => {
        this.setState({ compliance: newValue });
      },
    };

    const handleReview = () => {
      this.setState({ disabled: true });
      client
        .mutate({
          mutation: GET_RATTING,
          variables: {
            seller_id: item.user.id,
            product_id: item.product_id,
            buyer_id: this.props.auth_user.id,
            offer_id: this.props.rfqDetail.id,
            comment: this.state.Reviews,
            rate: this.state.supplierRating,
            is_buyer: this.props.auth_user.is_seller,
            responsiveness: this.state.responsiveRfq,
            shipment: this.state.abilityShip,
            lead_time: this.state.competitive,
            delivered: this.state.delivered,
            compliance: this.state.compliance,
          },
        })
        .then((res) => {
          if (this.props.socket?.connected) {
            this.props?.socket?.emit("notify", "Notification data");
          }
          toast.success("Your Review has been Submitted", {
            position: "top-right",
            autoClose: 2500,
          });
          setTimeout(() => {
            this.getOfferByID();
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
        });

      setTimeout(() => {
        this.setState({
          supplierRating: 0,
          responsiveRfq: 0,
          abilityShip: 0,
          competitive: 0,
          delivered: 0,
          compliance: 0,
          Reviews: "",
        });
      });
    };

    const matchProId = offer?.review?.filter(
      (dat) => dat?.is_buyer == false && dat?.product_id == item?.product_id
    );

    let found = offer?.is_aggregated === null;
    const url = process.env.REACT_APP_WEB_URL;
    const hasDev = url.includes("dev");
    return (
      <div className="my-5 space-y-7">
        <div className={"flex justify-between"}>
          <div className="flex items-center gap-x-3">
            <Avatarimage
              imagesrc={item.user.profile_photo}
              userId={item.user.id}
            />
            <h3 className="leading-5 Medium text-md">
              {item.user.firstname} {item.user.lastname}
            </h3>
          </div>
          {offer?.is_aggregated ? (
            <div>
              <Buttonlink
                to={`/aggregate/${encryptfunction(offer.id)}`}
                state={{ offer: offer }}
                text={"Aggregate"}
                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
              />
            </div>
          ) : (
            <div>
              <Buttonlink
                href={`${process.env.REACT_APP_MAIN_URL
                  }/print-po/${encryptfunction(offer.id + "," + hasDev)}`}
                text={"PO"}
                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
              />
            </div>
          )}
        </div>

        {item.comment !== null && (
          <p className="items-center mt-5 Medium text-md">{item?.comment}</p>
        )}
        {/* <div className="flex items-center mt-2 gap-x-1">
          <p className="Medium primary text-md ">Quoted Amount:</p>
          <p className="Medium">${item.total}</p>
        </div> */}

        <div className="flex flex-col justify-center intro-y">
          <h3 className="text-xl Medium">Seller Information</h3>
          <div className="mt-5 space-y-4">
            <div className="flex items-center gap-x-5">
              <p className="w-28 gray shrink-0">Email:</p>
              <p className="break-all">{item?.user?.email || "N/A"}</p>
            </div>
            <div className="flex items-center gap-x-5">
              <p className="w-28 gray shrink-0">Phone:</p>
              <p className="break-all">
                {item?.user?.primaryAddress?.phone || "N/A"}
              </p>
            </div>
            <div className="flex items-center gap-x-5">
              <p className="w-28 gray shrink-0">Address:</p>
              <p className="break-all">
                {item?.user?.primaryAddress?.address_1 || "N/A"}
              </p>
            </div>
            <div className="flex items-center gap-x-5">
              <p className="w-28 gray shrink-0">State:</p>
              <p className="break-all">
                {item?.user?.primaryAddress?.state?.name || "N/A"}
              </p>
            </div>
            <div className="flex items-center gap-x-5">
              <p className="w-28 gray shrink-0">City:</p>
              <p className="break-all">
                {item?.user?.primaryAddress?.city || "N/A"}
              </p>
            </div>
            <div className="flex items-center gap-x-5">
              <p className="w-28 gray ">Zip Code:</p>
              <p className="break-all">
                {item?.user?.primaryAddress?.zip_code || "N/A"}
              </p>
            </div>
            <div className="flex items-center gap-x-5">
              <p className="w-28 gray shrink-0">Country Code:</p>
              <p className="break-all">
                {item?.user?.primaryAddress?.country_code || "N/A"}
              </p>
            </div>
          </div>
        </div>

        {matchProId && matchProId?.length > 0 ? (
          matchProId?.map((data) => (
            <div>
              <div className="grid grid-cols-12 mt-5">
                <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                  <p className="mb-3 leading-none Medium">Overall Rating</p>
                  <div className="flex items-center mb-5 ml-auto leading-none">
                    <ReactStars {...supplierReview} value={data.rate} />
                    <p className="w-6 ml-3 leading-none fs-16">{data.rate}</p>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                  <p className="mb-3 leading-none Medium">
                    Responsiveness To RFQs
                  </p>
                  <div className="flex items-center mb-5 ml-auto leading-none">
                    <ReactStars
                      {...supplierReview}
                      value={data.responsiveness}
                    />
                    <p className="w-6 ml-3 leading-none fs-16">
                      {data.responsiveness}
                    </p>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                  <p className="mb-3 leading-none Medium">
                    Ability To Ship Parts Ordered
                  </p>
                  <div className="flex items-center mb-5 ml-auto leading-none">
                    <ReactStars {...supplierReview} value={data.shipment} />
                    <p className="w-6 ml-3 leading-none fs-16">
                      {data.shipment}
                    </p>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                  <p className="mb-3 leading-none Medium">
                    Competitive Lead Time
                  </p>
                  <div className="flex items-center mb-5 ml-auto leading-none">
                    <ReactStars {...supplierReview} value={data.lead_time} />
                    <p className="w-6 ml-3 leading-none fs-16">
                      {data.lead_time}
                    </p>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                  <p className="mb-3 leading-none Medium">
                    Delivered Parts Met Order Specifications
                  </p>
                  <div className="flex items-center mb-5 ml-auto leading-none">
                    <ReactStars {...supplierReview} value={data.delivered} />
                    <p className="w-6 ml-3 leading-none fs-16">
                      {data.delivered}
                    </p>
                  </div>
                </div>
                <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                  <p className="mb-3 leading-none Medium">Compliance Rate</p>
                  <div className="flex items-center mb-5 ml-auto leading-none">
                    <ReactStars {...supplierReview} value={data.compliance} />
                    <p className="w-6 ml-3 leading-none fs-16">
                      {data.compliance}
                    </p>
                  </div>
                </div>
              </div>
              <p className="text-xl Medium">Reviews</p>
              <p>{data.comment}</p>
            </div>
          ))
        ) : (
          <>
            <div className="grid grid-cols-12 mt-5">
              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                <p className="mb-3 leading-none Medium">Overall Rating</p>
                <div className="flex items-center mb-5 ml-auto leading-none">
                  <ReactStars {...supplierRating} />
                  <p className="w-6 ml-3 leading-none fs-16">
                    {this.state.supplierRating}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                <p className="mb-3 leading-none Medium">
                  Responsiveness To RFQs
                </p>
                <div className="flex items-center mb-5 ml-auto leading-none">
                  <ReactStars {...responsiveRfq} />
                  <p className="w-6 ml-3 leading-none fs-16">
                    {this.state.responsiveRfq}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                <p className="mb-3 leading-none Medium">
                  Ability To Ship Parts Ordered
                </p>
                <div className="flex items-center mb-5 ml-auto leading-none">
                  <ReactStars {...abilityShip} />
                  <p className="w-6 ml-3 leading-none fs-16">
                    {this.state?.abilityShip}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                <p className="mb-3 leading-none Medium">
                  Competitive Lead Time
                </p>
                <div className="flex items-center mb-5 ml-auto leading-none">
                  <ReactStars {...competitive} />
                  <p className="w-6 ml-3 leading-none fs-16">
                    {this.state?.competitive}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                <p className="mb-3 leading-none Medium">
                  Delivered Parts Met Order Specifications
                </p>
                <div className="flex items-center mb-5 ml-auto leading-none">
                  <ReactStars {...delivered} />
                  <p className="w-6 ml-3 leading-none fs-16">
                    {this.state?.delivered}
                  </p>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between col-span-12 md:col-span-9">
                <p className="mb-3 leading-none Medium">Compliance Rate</p>
                <Popup
                  trigger={
                    <AiOutlineQuestionCircle
                      className="mb-3 ml-1 cursor-pointer gray"
                      size={20}
                    />
                  }
                  content="How successfully did supplier adhered to company policies such as safety and regulations and purchase order cycle time required to monitor lag periods in your company"
                  inverted
                />
                <div className="flex items-center mb-5 ml-auto leading-none">
                  <ReactStars {...compliance} />
                  <p className="w-6 ml-3 leading-none fs-16">
                    {this.state?.compliance}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <p className="Medium">Reviews</p>
              <textarea
                rows={3}
                className="block w-full px-3 py-3 mt-2 mb-3 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm darkGray Light"
                placeholder="Add Reviews"
                onChange={this.reviewsRatting}
              />
            </div>
            <Buttonlink
              disabled={this.state.disabled}
              text="Submit"
              onClick={handleReview}
              className={` ${this.state.disabled
                ? "gray bg--lightGray hover:bg--gray"
                : "primary bg--lightPrimary hover:bg--primary"
                } relative w-52 hover:white hover:opacity-80 flex justify-center py-2.5 px-4 border border-transparent rounded-lg focus:outline-none`}
            />
          </>
        )}
        <Alertpopup rfqDetail={offer} ref={this.alertPopupRef} found={found} />
      </div>
    );
  };

  respondOfferPermission = (offer_id, type, user_id, id) => {
    this.setState({ permissionDisabled: true });

    let variables = {};
    variables.offer_id = offer_id;
    variables.user_id = user_id;
    variables.is_pending = false;
    if (type === "Accept") {
      variables.is_permitted = true;
    } else if (type === "Reject") {
      variables.is_permitted = false;
    }

    client
      .mutate({
        mutation: offerPermissionMutation,
        variables: variables,

        optimisticResponse: {
          __typename: "Mutation",
          generalOfferPermission: {
            __typename: "GeneralPermission",
            is_pending: false,
            is_permitted: variables.is_permitted,
            offer_id: offer_id,
            user_id: user_id,
          },
        },
      })
      .then((res) => {
        if (variables.is_permitted) {
          this.notify("You have accepted this offer!", "success");
        } else {
          this.notify("You have rejected this offer!", "success");
        }

        this.getOfferByID();

        this.setState({ permissionDisabled: false });

        // this.setState({ redirect: true });
      })
      .catch((err) => {
        console.log("err", err);
        extractValidationErrors(err).forEach((error) =>
          this.notify(error, "error")
        );
        // this.notify("Error in responding this offer!", "error");
        this.getOfferByID();
      });
  };

  openChatPopup = (id) => {
    this.setState(
      {
        chatPopup: !this.state.chatPopup,
        receiver_id: id,
      },
      () => {
        this.getOfferByID();
      }
    );
  };

  OfferClosed = (id) => {
    client
      .mutate({
        mutation: OFFER_CLOSED,
        variables: {
          offer_id: id,
          // is_closed: false,
        },
      })
      .then((responce) => {
        this.notify("Recommendation is closed successfully", "success");
        this.setState({
          is_closed: true,
        });
      });
  };

  handleUpdate = (data) => {
    //console.log("receive_message", data);
    //let newMsg = JSON.parse(data.message);
    // let payload = {
    //   text: newMsg[0].text,
    //   user: { _id: data.user_id, to: data.receiver_id },
    //   createdAt: new Date(),
    //   _id: data.room_id,
    // };
  };

  componentDidMount() {
    this.getOfferByID();
    this.setState({ id: this.props.id });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.supplierRating != 0 &&
      this.state.responsiveRfq != 0 &&
      this.state.abilityShip != 0 &&
      this.state.competitive != 0 &&
      this.state.delivered != 0 &&
      this.state.compliance != 0 &&
      this.state.Reviews != "" &&
      this.state.disabled
    ) {
      this.setState({
        disabled: false,
      });
    }
  }

  getOfferByID = () => {
    client
      .query({
        query: getOffer,
        variables: { id: this.props.id },
        fetchPolicy: "network-only",
      })
      .then((result) => {
        this.setState({
          rfqDetail: result.data.offer,
        });
      })
      .catch((error) => {
        console.log("rfqDetail_error", error);
      });
    if (this.state.openModal) {
      this.openChatPopup(this.props.alert.sender.id);
      this.setState({ openModal: false });
    }
  };
  updateState = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  handleChangeFilter = (filt) => {
    this.setState(
      {
        loader: true,
        filter: filt,
        selectProduct: null,
      },
      () => {
        client
          .query({
            query: getOffer,
            variables: { id: this.props.id, sortField: filt.name },
            fetchPolicy: "network-only",
          })
          .then((result) => {
            this.setState({
              rfqDetail: result.data.offer,
              loader: false,
            });
          })
          .catch((error) => {
            console.log("rfqDetail_error", error);
          });
      }
    );
  };
  handleChangeProductFilter = (product) => {
    this.setState(
      {
        loader: true,
        selectProduct: product,
      },
      () => {
        client
          .query({
            query: getOffer,
            variables: {
              id: this.props.id,
              sortField: this.state.filter.name,
              product: product.id,
            },
            fetchPolicy: "network-only",
          })
          .then((result) => {
            this.setState({
              rfqDetail: result.data.offer,
              loader: false,
            });
          })
          .catch((error) => {
            console.log("rfqDetail_error", error);
          });
      }
    );
  };
  render() {
    const { shareOptions, redirect, loginOptions } = this.state;
    const { rfqDetail } = this.state;
    // this.isExecutive(rfqDetail);
    let categoryNames = [];
    rfqDetail.categories &&
      rfqDetail.categories.data &&
      rfqDetail.categories?.data?.map((category) =>
        categoryNames.push(category.name)
      );

    if (redirect === true) {
      return <Navigate replace to="/" />;
    }

    const capitalizeFirst = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };

    let sellerratting = rfqDetail?.review?.filter((r) => r.is_buyer === true);

    const url = process.env.REACT_APP_WEB_URL;
    const hasDev = url.includes("dev");
    let matchingExecutive = rfqDetail?.executives.find(
      (executive) => executive.id === this.props?.auth_user.id
    );

    let permissionNames = [];

    if (matchingExecutive) {
      permissionNames =
        matchingExecutive?.offer_executive_user_roles[0]?.permissions.map(
          (permission) => permission.name
        );
    }
    const executiveIdsWithCanMessage = rfqDetail.executives
      ?.filter((executive) =>
        executive.offer_executive_user_roles[0].permissions.some((permission) =>
          permission.name.includes("canMessage")
        )
      )
      .map((executive) => executive.id);
    return (
      <>
        {rfqDetail.loading ? (
          <Loader />
        ) : !auth.isAuthenticated() ? (
          <>
            <p className="text-center my-7">
              You can either try a new vendor or use the following option.
            </p>
            <div className="flex flex-wrap items-center justify-center gap-3 xs:gap-x-5">
              {shareOptions === true ? (
                false
              ) : (
                <>
                  <Buttonlink
                    onClick={() => {
                      this.setState({ shareOptions: true });
                    }}
                    text="Share"
                    className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white whitespace-nowrap"
                  />
                </>
              )}
              {shareOptions && (
                <>
                  <SocialShare
                    size={30}
                    url={`${process.env.REACT_APP_MAIN_URL}/share/rfq/${rfqDetail.id}`}
                    title={rfqDetail?.title}
                    subject={rfqDetail?.title}
                    quote={rfqDetail?.title}
                    source={process.env.REACT_APP_MAIN_URL}
                    via={process.env.REACT_APP_MAIN_URL}
                    summary={{ __html: rfqDetail.description }}
                    hashtags={categoryNames}
                  />
                  <FiX
                    className="cursor-pointer"
                    size={24}
                    onClick={() => {
                      this.setState({ shareOptions: false });
                    }}
                  />
                </>
              )}
              {!loginOptions == true && (
                <Buttonlink
                  onClick={() => {
                    this.setState({ loginOptions: true });
                  }}
                  text="Post Your Rfq"
                  className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white whitespace-nowrap"
                />
              )}
              {loginOptions && (
                <>
                  <div className="h-6 bg-gray-400 w--1" />
                  <Buttonlink
                    to="/login"
                    state={{ params: this.props.params }}
                    text="Login"
                    className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white whitespace-nowrap"
                  />

                  <p className="text-gray-500 fs-12">or</p>
                  <Buttonlink
                    to="/registration"
                    state={{ params: this.props.params }}
                    text="Register"
                    className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white whitespace-nowrap"
                  />
                  <FiX
                    className="cursor-pointer"
                    size={24}
                    onClick={() => {
                      this.setState({ loginOptions: false });
                    }}
                  />
                </>
              )}
            </div>
          </>
        ) : this.props.auth_user.is_seller ? (
          false
        ) : (
          <div className="w-full px-2 sm:px-0">
            <Tab.Group>
              <Tab.List className="flex p-2 space-x-3 bg--lightGray rounded-xl">
                <Tab
                  key="submission"
                  className={({ selected }) =>
                    classNames(
                      "w-full py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md",
                      selected ? "bg--primary white" : "bg-white"
                    )
                  }
                >
                  Submissions
                </Tab>
                <Tab
                  key="award"
                  className={({ selected }) =>
                    classNames(
                      "w-full py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md",
                      selected ? "bg--primary white" : "bg-white"
                    )
                  }
                >
                  Awarded
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-2">
                <Tab.Panel
                  key="submission"
                  className="bg-white rounded-xl xs:p-6"
                >
                  {this.props.rfqDetail?.products &&
                    this.props.rfqDetail?.products?.length > 0 && (
                      <div className=" items-center flex">
                        <div className="w-full flex justify-end gap-x-3 items-center mb-4">
                          <p className="Regular">Sort by:</p>
                          <Listbox
                            value={this.state.filter}
                            onChange={this.handleChangeFilter}
                          >
                            <div className="relative z-10 w-full max-w-xs">
                              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow outline-none border">
                                <span className="block Regular truncate">
                                  {this.state.filter?.name || "Select"}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <IoIosArrowDown
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                  {filters.map((filt, index) => (
                                    <Listbox.Option
                                      key={index}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                          ? "bg--lightPrimary primary"
                                          : "text-gray-900"
                                        }`
                                      }
                                      value={filt}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${this.state.filter
                                              ? "font-medium Medium"
                                              : "font-normal Regular"
                                              }`}
                                          >
                                            {filt.name}
                                          </span>
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                        {(this.state.filter?.name === "Users" ||
                          this.state.filter?.name === "Price") && (
                            <div className="w-full flex justify-end gap-x-3 items-center mb-4">
                              <p className="Regular">Products:</p>
                              <Listbox
                                value={this.state.selectProduct}
                                onChange={this.handleChangeProductFilter}
                              >
                                <div className="relative z-50 w-full max-w-xs">
                                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow outline-none border">
                                    <span className="block Regular truncate">
                                      {this.state.selectProduct?.title ||
                                        "Select Product"}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                      <IoIosArrowDown
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                      {this.state?.rfqDetail?.products?.map(
                                        (product, index) => (
                                          <Listbox.Option
                                            key={index}
                                            className={({ active }) =>
                                              `relative cursor-default select-none py-2 pl-10 pr-4 ${active
                                                ? "bg--lightPrimary primary"
                                                : "text-gray-900"
                                              }`
                                            }
                                            value={product}
                                          >
                                            {({ selected }) => (
                                              <>
                                                <span
                                                  className={`block truncate ${this.state.filter
                                                    ? "font-medium Medium"
                                                    : "font-normal Regular"
                                                    }`}
                                                >
                                                  {product?.title}
                                                </span>
                                              </>
                                            )}
                                          </Listbox.Option>
                                        )
                                      )}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </div>
                          )}
                      </div>
                    )}
                  {!matchingExecutive ? (
                    <div className="flex justify-between">
                      <div>
                        <p className="Medium">Submissions</p>
                        <p className="gray fs-13">
                          Following is the Submissions
                        </p>
                      </div>
                      <div className="mr-5 cursor-pointer">
                        {/* <VscFilePdf className="primary" size={26} /> */}
                      </div>
                    </div>
                  ) : this.state?.rfqDetail?.isOwner ? (
                    <div className="flex justify-between">
                      <div>
                        <p className="Medium">Submissions</p>
                        <p className="gray fs-13">
                          Following is the Submissions
                        </p>
                      </div>
                      <div className="mr-5 cursor-pointer">
                        {/* <VscFilePdf className="primary" size={26} /> */}
                      </div>
                    </div>
                  ) : null}
                  {this.state?.rfqDetail?.products.length !== 0 ? (
                    <ProductParticipents
                      rfqDetail={this.state?.rfqDetail}
                      shareOptions={shareOptions}
                      state={this.state}
                      updateParentState={this.updateState}
                      auth_user={this.props.auth_user}
                      callfunction={this.updateState}
                      quotationApproved={this.quotationApproved}
                      OfferClosed={this.OfferClosed}
                      quotationRevised={this.quotationRevised}
                      rejectRfqReq={this.rejectRfqReq}
                      openChatPopup={this.openChatPopup}
                      handleMenuItemClick={this.handleMenuItemClick}
                      quotationCanceled={this.quotationCanceled}
                      loader={this.state.loader}
                    />
                  ) : this.state?.rfqDetail?.isOwner &&
                    this.state?.rfqDetail?.participants?.data?.length > 0 ? (
                    this.state.rfqDetail?.participants?.data?.map((item) => (
                      <div className="pb-10 my-5 mb-10 border-b">
                        <div className="flex">
                          <Popup
                            trigger={
                              <Link
                                className="flex items-center leading-5 gap-x-3 Medium text-md hover:primary"
                                to={`/${encryptfunction(item.user.id)}/profile`}
                                state={{ value: "edit", id: item.user.id }}
                              >
                                <Avatarimage
                                  imagesrc={item.user.profile_photo}
                                  userId={item.user.id}
                                />
                                {capitalizeFirst(item.user.firstname)}{" "}
                                {capitalizeFirst(item.user.lastname)}
                              </Link>
                            }
                            content={
                              <div className="profile-stats">
                                <p className="Medium fs-20 black">
                                  Supplier Qulaity Rating System
                                </p>
                                <p className="px-3 py-1 text-white bg-black rounded-sm Medium fs-16">
                                  Rating Scale
                                </p>
                                <div className="hidden grid-cols-5 gap-3 px-3 py-1 bg-gray-300 rounded-sm lg:grid">
                                  <p className="Medium fs-14 black">
                                    5 - Excellent
                                  </p>
                                  <p className="Medium fs-14 black">4 - Good</p>
                                  <p className="Medium fs-14 black">3 - Fair</p>
                                  <p className="Medium fs-14 black">2 - Poor</p>
                                  <p className="Medium fs-14 black">
                                    1 - Extremely Poor
                                  </p>
                                </div>
                                <div className="grid grid-cols-2 gap-3 mt-2 lg:grid-cols-5 md:grid-cols-3">
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Responsiveness to RFQs
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Ability to Qoute Parts Listed
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Ability to Ship Parts Ordered
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Delivered Parts Met Order Specification
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                  <div className="px-3 pt-4 pb-5 text-center bg-gray-300 rounded-md">
                                    <p className="mb-3 fs-14 min-h-[60px] flex items-center justify-center">
                                      Handling of Return Requests
                                    </p>
                                    <p className="mb-2 fs-14">
                                      Average Rating:
                                    </p>
                                    <p className="bg-gray-200 rounded-md py-1 px-4 inline-flex items-center justify-center mx-auto mb-4 w-[50px] h-[32px]">
                                      1.22
                                    </p>
                                  </div>
                                </div>
                              </div>
                            }
                            className="shadow-lg profile-tooltip"
                            inverted
                          />
                        </div>

                        <p className="items-center mt-5 Medium text-md">
                          {item.comment}
                        </p>
                        <div className="flex">
                          {item.document && (
                            <ImageDisplay
                              key={1}
                              attachment={item.document}
                              index={1}
                              altText={'Download Attachment'}
                              notify={this.notify}
                            />
                            // <Buttonlink
                            //   className="flex items-center px-6 py-2 my-3 leading-5 rounded-lg shadow gap-x-2 fs-13 Regular hover:opacity-70 bg--lightGray darkGray"
                            //   href={`${process.env.REACT_APP_MAIN_URL}/${item.document}`}
                            //   target="_blank"
                            //   prefix={<AiOutlineCloudDownload size={22} />}
                            //   text="Download Attachment"
                            // />
                          )}
                        </div>
                        {item.action_user_id !== null ? (
                          <div>
                            Rejection of Quotation from Seller Side
                            <br />
                            <strong>Reason:</strong> {item?.reason ?? ""}
                          </div>
                        ) : (
                          <>
                            <div>
                              {item?.expected_date && (
                                <div className={"flex"}>
                                  <p className="Medium primary text-md ">
                                    Expected delivery date:
                                  </p>
                                  <p>
                                    <Moment
                                      date={item?.expected_date}
                                      format={"LL"}
                                      className="ml-2 text-right"
                                    />
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="flex items-center mt-2 gap-x-2">
                              <p className="Medium primary text-md ">
                                Quoted Amount:
                              </p>

                              <div>
                                <p
                                  className={`rounded-full fs-13 ${item.readable_status === "Revision"
                                    ? "bg-orange-500"
                                    : item.readable_status === "Rejected"
                                      ? "bg-red-500"
                                      : item.readable_status === "Pending"
                                        ? "bg-gray-500"
                                        : item.readable_status === "Waiting"
                                          ? "bg-pink-400"
                                          : item.readable_status === "Complete"
                                            ? "bg-green-500"
                                            : "bg-amber-500"
                                    } text-white px-4 py-1 inline-flex`}
                                >
                                  {item.readable_status === "Waiting"
                                    ? "Waiting For Seller Response"
                                    : item.readable_status}
                                </p>
                              </div>
                              {item?.userSignature !== "false" && (
                                <div>
                                  {/* {console.log("item", item)} */}
                                  {item?.userSignature === "true" ? (
                                    <a
                                      href={`${process.env.REACT_APP_MAIN_URL
                                        }/non-disclosure-agreement/${encryptfunction(
                                          rfqDetail?.id + "," + item?.user?.id
                                        )}`}
                                      target="_blank"
                                    >
                                      <VscFilePdf
                                        size={24}
                                        className="primary"
                                      />
                                    </a>
                                  ) : (
                                    <a
                                      download
                                      href={item?.userSignature}
                                      target="_blank"
                                    >
                                      <VscFilePdf
                                        size={24}
                                        className="primary"
                                      />
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                            {item.total !== 0 && (
                              <p className="Medium">${item.total}</p>
                            )}
                            {
                              <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-5 my-8">
                                {item.participant_files?.length > 0 &&
                                  item.participant_files.map((file, index) => {
                                    const pathParts = file?.path.split("/");
                                    const filenameWithExtension =
                                      pathParts[pathParts.length - 1];
                                    const filenameArray =
                                      filenameWithExtension.split(".");
                                    const filename = filenameArray[0];
                                    const extension = filenameArray[1];

                                    const prevFile =
                                      item.participant_files[index - 1];
                                    let timeDiff = 0;
                                    if (prevFile) {
                                      const prevTime = new Date(
                                        prevFile.created_at
                                      );
                                      const thisTime = new Date(
                                        file.created_at
                                      );
                                      timeDiff =
                                        Math.abs(thisTime - prevTime) /
                                        (1000 * 60);
                                    }

                                    const latestIndex =
                                      item.participant_files.length - 1;
                                    const showText =
                                      timeDiff > 1 || index === latestIndex;

                                    return (
                                      <div className="flex space-y-2 justify-center md:items-start items-center flex-col w-full">
                                        <div className="w-28 h-28 relative">
                                          <a
                                            href={`${process.env.REACT_APP_MAIN_URL}/${file?.path}`}
                                            target="_blank" // Opens the link in a new tab
                                            rel="noopener noreferrer" // Improves security
                                          >
                                            <img
                                              src={this.images[extension]}
                                              alt="file"
                                              className="w-full h-full"
                                            />
                                          </a>
                                          {showText && (
                                            <span className="text-sm absolute bg--primary text-white top-[-6px] px-2 py-[2px] transform rotate-[-45deg] left-0">
                                              new
                                            </span>
                                          )}
                                        </div>
                                        <p className="truncate w-full max-w-[130px]">
                                          {filename}
                                        </p>
                                      </div>

                                    );
                                  })}
                              </div>
                            }
                            {item.readable_status === "Complete" &&
                              sellerratting.length > 0 && (
                                <div className={"my-2"}>
                                  <p className={"Medium text-xl"}>
                                    {" "}
                                    {capitalizeFirst(item.user.firstname)}{" "}
                                    {capitalizeFirst(item.user.lastname)} Leaves
                                    A Review
                                  </p>

                                  {sellerratting?.map((rate) => (
                                    <>
                                      <p className="Medium text-md">Ratings</p>
                                      <ReactStars
                                        classNames="mt-2"
                                        count={5}
                                        edit={false}
                                        color={"#d1d5db"}
                                        activeColor={"#ffd700"}
                                        value={parseInt(rate?.rate)}
                                        size={24}
                                        emptyIcon={
                                          <i className="far fa-star"></i>
                                        }
                                        halfIcon={
                                          <i className="fa fa-star-half-alt"></i>
                                        }
                                        fullIcon={
                                          <i className="fa fa-star"></i>
                                        }
                                      />
                                      <p className="Medium text-md">Review</p>
                                      <p>{rate?.comment}</p>
                                    </>
                                  ))}
                                </div>
                              )}
                            {item.approved === false &&
                              this.state?.rfqDetail?.approvedParticipant
                                .length === 0
                              ? !item.blocked && (
                                <>
                                  <div className="flex items-center justify-center mt-10 gap-x-5">
                                    <Buttonlink
                                      disabled={
                                        !!(
                                          item.approved ||
                                          item.isRevision ||
                                          item.blocked ||
                                          this.state.offerAcceptLoader
                                        )
                                      }
                                      onClick={() =>
                                        item.expected_date !== null
                                          ? this.setState({ isOpen: true })
                                          : this.quotationApproved(item.id)
                                      }
                                      text="Approve"
                                      className={
                                        item.isRevision || item.blocked
                                          ? "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow opacity-70 bg--primary white hover:text-white"
                                          : "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                      }
                                    />

                                    {!item.isRevision ? (
                                      <Menu
                                        key={item.id}
                                        as="div"
                                        className="relative inline-block text-left"
                                      >
                                        <div className="flex items-center bg--lightGray rounded-lg shadow-md">
                                          {this.state.rfqDetail?.messages?.some(
                                            (message) =>
                                              message.user?.id ==
                                              item?.user?.id &&
                                              message?.is_buyer === 1
                                          ) ? (
                                            <Tooltips
                                              trigger={
                                                <div className="inline-flex items-center h-10 overflow-hidden leading-5 fs-15 Regular hover:opacity-70 bg--lightGray darkGray">
                                                  {this.state.itemId ===
                                                    item?.id ? (
                                                    <>
                                                      {this.state
                                                        .is_revision &&
                                                        !item.isRevision &&
                                                        !item.blocked && (
                                                          <p
                                                            onClick={() => {
                                                              setTimeout(
                                                                () => {
                                                                  this.setState(
                                                                    {
                                                                      isRejectToastVisible: false,
                                                                    }
                                                                  );
                                                                },
                                                                10000
                                                              );
                                                              {
                                                                !this.state
                                                                  .isRejectToastVisible &&
                                                                  toast.warn(
                                                                    <div>
                                                                      <p>
                                                                        Are
                                                                        you
                                                                        certain
                                                                        that
                                                                        you
                                                                        want
                                                                        to
                                                                        proceed
                                                                        with
                                                                        the
                                                                        Revision?
                                                                        This
                                                                        action
                                                                        cannot
                                                                        be
                                                                        undone.
                                                                      </p>
                                                                      {this.setState(
                                                                        {
                                                                          isRejectToastVisible: true,
                                                                        }
                                                                      )}
                                                                      <button
                                                                        className={
                                                                          "h-10 px-3 ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                                                        }
                                                                        onClick={() => {
                                                                          this.setState(
                                                                            {
                                                                              isRejectToastVisible: false,
                                                                            }
                                                                          );
                                                                          this.quotationRevised(
                                                                            item.id
                                                                          );
                                                                        }}
                                                                      >
                                                                        Yes
                                                                      </button>
                                                                      <button
                                                                        className={
                                                                          "inline-flex px-3 ml-2 items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray"
                                                                        }
                                                                        onClick={() => {
                                                                          this.setState(
                                                                            {
                                                                              isRejectToastVisible: false,
                                                                            }
                                                                          );
                                                                          toast.dismiss();
                                                                        }}
                                                                      >
                                                                        No
                                                                      </button>
                                                                    </div>
                                                                  );
                                                              }
                                                            }}
                                                            className={`darkGray cursor-pointer pr-2 pl-6 py-2.5 ${item.isRevision ||
                                                              item.blocked
                                                              ? "opacity-75 cursor-default"
                                                              : ""
                                                              }`}
                                                          >
                                                            Revision
                                                          </p>
                                                        )}

                                                      {this.state.is_reject &&
                                                        !item.blocked && (
                                                          <p
                                                            onClick={() => {
                                                              setTimeout(
                                                                () => {
                                                                  this.setState(
                                                                    {
                                                                      isReviseToastVisible: false,
                                                                    }
                                                                  );
                                                                },
                                                                10000
                                                              );
                                                              {
                                                                !this.state
                                                                  .isReviseToastVisible &&
                                                                  toast.warn(
                                                                    <div>
                                                                      <p>
                                                                        Are
                                                                        you
                                                                        certain
                                                                        that
                                                                        you
                                                                        want
                                                                        to
                                                                        proceed
                                                                        with
                                                                        the
                                                                        rejection?
                                                                        This
                                                                        action
                                                                        cannot
                                                                        be
                                                                        undone.
                                                                      </p>
                                                                      {this.setState(
                                                                        {
                                                                          isReviseToastVisible: true,
                                                                        }
                                                                      )}
                                                                      <button
                                                                        className={
                                                                          "h-10 px-3 ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                                                        }
                                                                        onClick={() => {
                                                                          this.setState(
                                                                            {
                                                                              isReviseToastVisible: false,
                                                                            }
                                                                          );
                                                                          this.rejectRfqReq(
                                                                            item.id
                                                                          );
                                                                          toast.dismiss();
                                                                        }}
                                                                      >
                                                                        Yes
                                                                      </button>
                                                                      <button
                                                                        className={
                                                                          "inline-flex px-3 ml-2 items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray"
                                                                        }
                                                                        onClick={() => {
                                                                          this.setState(
                                                                            {
                                                                              isReviseToastVisible: false,
                                                                            }
                                                                          );
                                                                          toast.dismiss();
                                                                        }}
                                                                      >
                                                                        No
                                                                      </button>
                                                                    </div>
                                                                  );
                                                              }
                                                            }}
                                                            className={`darkGray cursor-pointer pr-2 pl-6 py-2.5 ${item.isRevision ||
                                                              item.blocked
                                                              ? "opacity-75 cursor-default"
                                                              : ""
                                                              }`}
                                                          >
                                                            Reject
                                                          </p>
                                                        )}

                                                      {this.state
                                                        .is_message && (
                                                          <p
                                                            onClick={() =>
                                                              this.openChatPopup(
                                                                item.user?.id
                                                              )
                                                            }
                                                            className="darkGray pr-2 pl-6 py-2.5 cursor-pointer"
                                                          >
                                                            Message
                                                            <div className="absolute right-0 top-[7px] w-2 h-2 bg-green-500 rounded-full" />
                                                          </p>
                                                        )}
                                                    </>
                                                  ) : (
                                                    <p className="darkGray relative pr-2 pl-6 py-2.5 cursor-pointer">
                                                      options
                                                      <div className="absolute right-0 top-[7px] w-2 h-2 bg-green-500 rounded-full" />
                                                    </p>
                                                  )}
                                                </div>
                                              }
                                              toolcontent={`You might have some unread message`}
                                            />
                                          ) : (
                                            <div className="inline-flex items-center h-10 overflow-hidden leading-5 fs-15 Regular hover:opacity-70 bg--lightGray darkGray">
                                              {this.state.itemId ===
                                                item.id ? (
                                                <>
                                                  {this.state.is_revision &&
                                                    !item.isRevision &&
                                                    !item.blocked && (
                                                      <p
                                                        onClick={() => {
                                                          setTimeout(() => {
                                                            this.setState({
                                                              isRejectToastVisible: false,
                                                            });
                                                          }, 10000);
                                                          {
                                                            !this.state
                                                              .isRejectToastVisible &&
                                                              toast.warn(
                                                                <div>
                                                                  <p>
                                                                    Are you
                                                                    certain
                                                                    that you
                                                                    want to
                                                                    proceed
                                                                    with the
                                                                    Revision?
                                                                    This
                                                                    action
                                                                    cannot be
                                                                    undone.
                                                                  </p>
                                                                  {this.setState(
                                                                    {
                                                                      isRejectToastVisible: true,
                                                                    }
                                                                  )}
                                                                  <button
                                                                    className={
                                                                      "h-10 px-3 ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                                                    }
                                                                    onClick={() => {
                                                                      this.setState(
                                                                        {
                                                                          isRejectToastVisible: false,
                                                                        }
                                                                      );
                                                                      this.quotationRevised(
                                                                        item.id
                                                                      );
                                                                    }}
                                                                  >
                                                                    Yes
                                                                  </button>
                                                                  <button
                                                                    className={
                                                                      "inline-flex px-3 ml-2 items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray"
                                                                    }
                                                                    onClick={() => {
                                                                      this.setState(
                                                                        {
                                                                          isRejectToastVisible: false,
                                                                        }
                                                                      );
                                                                      toast.dismiss();
                                                                    }}
                                                                  >
                                                                    No
                                                                  </button>
                                                                </div>
                                                              );
                                                          }
                                                        }}
                                                        className={`darkGray pr-2 pl-6 py-2.5 ${item.isRevision ||
                                                          item.blocked
                                                          ? "opacity-75 cursor-default"
                                                          : ""
                                                          }`}
                                                      >
                                                        Revision
                                                      </p>
                                                    )}

                                                  {this.state.is_reject &&
                                                    !item.blocked && (
                                                      <p
                                                        onClick={() => {
                                                          setTimeout(() => {
                                                            this.setState({
                                                              isReviseToastVisible: false,
                                                            });
                                                          }, 10000);
                                                          {
                                                            !this.state
                                                              .isReviseToastVisible &&
                                                              toast.warn(
                                                                <div>
                                                                  <p>
                                                                    Are you
                                                                    certain
                                                                    that you
                                                                    want to
                                                                    proceed
                                                                    with the
                                                                    rejection?
                                                                    This
                                                                    action
                                                                    cannot be
                                                                    undone.
                                                                  </p>
                                                                  {this.setState(
                                                                    {
                                                                      isReviseToastVisible: true,
                                                                    }
                                                                  )}
                                                                  <button
                                                                    className={
                                                                      "h-10 px-3 ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                                                    }
                                                                    onClick={() => {
                                                                      this.setState(
                                                                        {
                                                                          isReviseToastVisible: false,
                                                                        }
                                                                      );
                                                                      this.rejectRfqReq(
                                                                        item.id
                                                                      );
                                                                      toast.dismiss();
                                                                    }}
                                                                  >
                                                                    Yes
                                                                  </button>
                                                                  <button
                                                                    className={
                                                                      "inline-flex px-3 ml-2 items-center h-10 overflow-hidden leading-5 rounded-lg shadow-md fs-15 Regular hover:opacity-70 bg--lightGray darkGray"
                                                                    }
                                                                    onClick={() => {
                                                                      this.setState(
                                                                        {
                                                                          isReviseToastVisible: false,
                                                                        }
                                                                      );
                                                                      toast.dismiss();
                                                                    }}
                                                                  >
                                                                    No
                                                                  </button>
                                                                </div>
                                                              );
                                                          }
                                                        }}
                                                        className={`darkGray pr-2 pl-6 py-2.5 ${item.isRevision ||
                                                          item.blocked
                                                          ? "opacity-75 cursor-default"
                                                          : ""
                                                          }`}
                                                      >
                                                        Reject
                                                      </p>
                                                    )}

                                                  {this.state.is_message && (
                                                    <p
                                                      onClick={() =>
                                                        this.openChatPopup(
                                                          item.user.id
                                                        )
                                                      }
                                                      className="darkGray pr-2 pl-6 py-2.5 cursor-pointer"
                                                    >
                                                      Message
                                                    </p>
                                                  )}
                                                </>
                                              ) : (
                                                <p className="darkGray relative pr-2 pl-6 py-2.5 cursor-pointer">
                                                  options
                                                </p>
                                              )}
                                            </div>
                                          )}
                                          <Menu.Button
                                            key={item.id}
                                            className="pr-6 py-2.5 fs-15 leading-5 inline-flex items-center cursor-default"
                                          >
                                            <FiChevronDown className="w-5 h-5 ml-2 -mr-1" />
                                          </Menu.Button>
                                        </div>

                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95"
                                          key={item.id}
                                        >
                                          <Menu.Items
                                            key={item.id}
                                            className="absolute right-0 w-64 p-2 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg z-xlfull ring-1 ring-black ring-opacity-5 focus:outline-none"
                                          >
                                            <div className="px-1 py-1 ">
                                              <Menu.Item
                                                key={item.id}
                                                onClick={() =>
                                                  this.handleMenuItemClick(
                                                    1,
                                                    item.id
                                                  )
                                                }
                                                disabled={
                                                  item.isRevision ||
                                                    item.blocked
                                                    ? true
                                                    : false
                                                }
                                                className={`w-full text-left p-2.5 fs-15 leading-5 Regular rounded-lg hover:bg-slate-200 darkGray ${item.isRevision ||
                                                  item.blocked
                                                  ? "opacity-75"
                                                  : ""
                                                  }`}
                                              >
                                                <p>Revision</p>
                                              </Menu.Item>
                                              <Menu.Item
                                                key={item.id}
                                                onClick={() =>
                                                  this.handleMenuItemClick(
                                                    2,
                                                    item.id
                                                  )
                                                }
                                                disabled={
                                                  item.isRevision ||
                                                    item.blocked
                                                    ? true
                                                    : false
                                                }
                                                className={`w-full text-left p-2.5 fs-15 leading-5 Regular rounded-lg hover:bg-slate-200 darkGray ${item.isRevision ||
                                                  item.blocked
                                                  ? "opacity-75"
                                                  : ""
                                                  }`}
                                              >
                                                <p>Reject</p>
                                              </Menu.Item>
                                              <Menu.Item
                                                key={item.id}
                                                onClick={() =>
                                                  this.handleMenuItemClick(
                                                    3,
                                                    item.id
                                                  )
                                                }
                                              >
                                                <Buttonlink
                                                  text="Message"
                                                  className="w-full text-left p-2.5 fs-15 leading-5 Regular rounded-lg hover:bg-slate-200 darkGray"
                                                />
                                              </Menu.Item>
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    ) : (
                                      <div className="">
                                        <p
                                          onClick={() =>
                                            this.openChatPopup(item.user?.id)
                                          }
                                          className="h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow hover:opacity-70 relative bg--lightGray darkGray cursor-pointer"
                                        >
                                          Message
                                          {this.state.rfqDetail?.messages?.some(
                                            (message) =>
                                              message.user?.id ==
                                              item?.user?.id &&
                                              message?.is_buyer === 1
                                          ) && (
                                              <div className="absolute right-0 top-[7px] w-2 h-2 bg-green-500 rounded-full" />
                                            )}
                                        </p>
                                      </div>
                                    )}
                                  </div>

                                  {/*Dialog*/}

                                  <div>
                                    <Dialog
                                      fullWidth={true}
                                      maxWidth={"md"}
                                      scroll="body"
                                      open={this.state.isOpen}
                                      onClose={() =>
                                        this.setState({ isOpen: false })
                                      }
                                      id="scrollbar"
                                      className="rounded--xl"
                                    >
                                      <div className="shadow-none bg-gray-100">
                                        <Toolbar className="flex items-center justify-between">
                                          <p className="text-lg font-medium leading-6 text-gray-900">
                                            Expected delivery date:
                                          </p>
                                          <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() =>
                                              this.setState({ isOpen: false })
                                            }
                                            aria-label="close"
                                            className="ml-auto"
                                          >
                                            <IoCloseCircleOutline
                                              size={32}
                                              className="primary"
                                            />
                                          </IconButton>
                                        </Toolbar>
                                      </div>
                                      <div className="p-4">
                                        <div className="my-4">
                                          {item?.expected_date && (
                                            <p>
                                              <Moment
                                                date={item?.expected_date}
                                                format={"LL"}
                                                className="text-center primary"
                                              />
                                            </p>
                                          )}
                                        </div>

                                        <div className="mt-4 text-right">
                                          <button
                                            type="button"
                                            className="h-10 w-[150px] ml-auto py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                            onClick={() => {
                                              this.quotationCanceled(item.id);
                                              this.quotationApproved(item.id);
                                              this.setState({
                                                isOpen: false,
                                              });
                                            }}
                                          >
                                            Accept
                                          </button>
                                          <button
                                            type="button"
                                            className="h-10 w-[150px] ml-5 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--gray white hover:text-white"
                                            onClick={() => {
                                              this.quotationApproved(item.id);
                                              this.setState({
                                                isOpen: false,
                                              });
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </Dialog>
                                  </div>
                                </>
                              )
                              : null}
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      {!matchingExecutive ? (
                        <p className="text-center my-7">
                          No Offer found. You can either try a new vendor or
                          choose following options.
                        </p>
                      ) : this.state?.rfqDetail?.isOwner ? (
                        <p className="text-center my-7">
                          No Offer found. You can either try a new vendor or
                          choose following options.
                        </p>
                      ) : null}
                      <div className="flex flex-wrap items-center justify-center gap-3 xs:gap-x-5">
                        {this.state?.rfqDetail?.isOwner && !shareOptions ? (
                          <Buttonlink
                            onClick={() => {
                              this.setState({ shareOptions: true });
                            }}
                            text="Share"
                            className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                          />
                        ) : matchingExecutive || shareOptions === true ? (
                          false
                        ) : (
                          <Buttonlink
                            onClick={() => {
                              this.setState({ shareOptions: true });
                            }}
                            text="Share"
                            className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                          />
                        )}

                        {shareOptions && (
                          <>
                            <SocialShare
                              size={30}
                              url={`${process.env.REACT_APP_MAIN_URL}/share/rfq/${rfqDetail.id}`}
                              title={rfqDetail?.title}
                              subject={rfqDetail?.title}
                              quote={rfqDetail?.title}
                              source={process.env.REACT_APP_MAIN_URL}
                              via={process.env.REACT_APP_MAIN_URL}
                              summary={{ __html: rfqDetail.description }}
                              hashtags={categoryNames}
                            />
                            <FiX
                              className="cursor-pointer"
                              size={24}
                              onClick={() => {
                                this.setState({ shareOptions: false });
                              }}
                            />
                          </>
                        )}
                      </div>
                      {rfqDetail.menu_id === 6 || rfqDetail.menu_id === 7 ? (
                        <div className="text-center mt-7">
                          <Buttonlink
                            onClick={() => this.OfferClosed(rfqDetail.id)}
                            text="Close Q&A?"
                            disabled={this.state.is_closed ? true : false}
                            className={`px-28 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 ${this.state.is_closed
                              ? "bg-gray-200 text-black"
                              : "bg--primary text-white"
                              } `}
                          />
                        </div>
                      ) : null}
                    </>
                  )}

                  {/* ///////// */}
                  <ExecutivePermissions
                    rfqDetail={this.state.rfqDetail}
                    auth_user={this.props.auth_user}
                    state={this.state}
                    quotationCanceled={this.quotationCanceled}
                    openChatPopup={this.openChatPopup}
                    quotationRevised={this.quotationRevised}
                    quotationApproved={this.quotationApproved}
                    rejectRfqReq={this.rejectRfqReq}
                  />
                  {/* {permissionNames.length !== 0 && !rfqDetail.isOwner && (
                    <>
                      <div className="flex justify-between mt-12">
                        <div>
                          <p className="Medium">Permissions</p>
                          <p className="gray fs-13">
                            Following is the Permissions as an Executive
                          </p>
                        </div>
                        {permissionNames.includes("download-report") && (
                          <Buttonlink
                            className="flex items-center h-10 px-6 leading-5 rounded-lg shadow bg--lightGray darkGray fs-15 Regular hover:opacity-80 gap-x-2"
                            href={`${process.env.REACT_APP_MAIN_URL
                              }/rfq-progress/${encryptfunction(
                                rfqDetail.id + "," + hasDev
                              )}`}
                            target="_blank"
                            prefix={
                              <VscFilePdf size={16} className="darkGray" />
                            }
                            text="Report"
                          />
                        )}
                      </div>

                      {this.state.rfqDetail?.participants?.data?.length > 0
                        ? this.state.rfqDetail?.participants?.data.map((item) => (
                          <div
                            key={item.id}
                            className="pb-10 my-5 mb-10 border-b"
                          >
                            <Link
                              to={`/${encryptfunction(item.user.id)}/profile`}
                              state={{
                                value: "edit",
                                id: item.user.id,
                              }}
                            >
                              <div className="flex items-center gap-x-3">
                                <Avatarimage
                                  imagesrc={item.user.profile_photo}
                                  userId={item.user.id}
                                />
                                <h3 className="leading-5 Medium text-md">
                                  {item.user.firstname} {item.user.lastname}
                                </h3>
                              </div>
                            </Link>
                            <p className="items-center mt-5 Medium text-md">
                              {item.comment}
                            </p>
                            <div className="flex">
                              {item.document && (

                                <Buttonlink
                                  href={`${process.env.REACT_APP_MAIN_URL}/${item.document}`}
                                  prefix={
                                    <AiOutlineCloudDownload size={22} />
                                  }
                                  text={"Download Attachment"}
                                  className="flex items-center px-6 py-2 my-3 leading-5 rounded-lg shadow gap-x-2 fs-13 Regular hover:opacity-70 bg--lightGray darkGray"
                                />
                              )}
                            </div>

                            <div className="flex items-center mt-2 gap-x-1">
                              <p className="Medium primary text-md ">
                                Quoted Amount:
                              </p>
                              <p className="Medium">${item.total}</p>
                              <div>
                                <p
                                  className={`rounded-full fs-13 ${item.readable_status === "Revision"
                                    ? "bg-orange-500"
                                    : item.readable_status === "Rejected"
                                      ? "bg-red-500"
                                      : item.readable_status === "Pending"
                                        ? "bg-gray-500"
                                        : item.readable_status === "Waiting"
                                          ? "bg-pink-400"
                                          : item.readable_status === "Complete"
                                            ? "bg-green-500"
                                            : "bg-amber-500"
                                    } text-white px-4 py-1 inline-flex`}
                                >
                                  {item.readable_status === "Waiting"
                                    ? "Waiting For Seller Response"
                                    : item.readable_status === "Rejected"
                                      ? "Rejected"
                                      : item.readable_status}
                                </p>
                              </div>
                            </div>
                            <div className={`flex items-center justify-center ${item.action_user_id !== null ? 'mt-4' : 'mt-10'} gap-x-4`}>
                              {permissionNames.includes("canMessage") ? (
                                <Buttonlink
                                  onClick={() =>
                                    this.openChatPopup(item.user.id)
                                  }
                                  className="h-10 px-6 leading-5 rounded-lg shadow bg--lightGray darkGray fs-15 Regular hover:opacity-80"
                                  text="Message"
                                />
                              ) : null}
                              {permissionNames.includes(
                                "reject-or-approve"
                              ) ? (
                                item.approved == true ||
                                  item.blocked == true ? (
                                  false
                                ) :
                                  item.action_user_id !== null ? (
                                    <div className="flex-grow self-start">
                                      Rejection of Quotation from Seller Side
                                      <br />
                                      <strong>Reason:</strong> {item?.reason ?? ""}
                                    </div>
                                  ) : (
                                    <>
                                      <Buttonlink
                                        onClick={() => {
                                          item.blocked
                                            ? this.doNothing()
                                            : this.rejectRfqReq(item.id);
                                        }}
                                        className={`bg--lightGray darkGray h-10 px-6 fs-15 leading-5 Regular rounded-lg shadow hover:opacity-80 ${item.isRevision || item.blocked
                                          ? "opacity-75 cursor-default"
                                          : ""
                                          }`}
                                        text="Reject"
                                      />

                                      <Buttonlink
                                        disabled={
                                          !!(
                                            item.isRevision ||
                                            item.blocked ||
                                            this.state.offerAcceptLoader
                                          )
                                        }
                                        onClick={() =>
                                          this.quotationApproved(item.id)
                                        }
                                        text="Approve"
                                        className={
                                          item.isRevision || item.blocked
                                            ? "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow opacity-70 bg--primary white hover:text-white"
                                            : "h-10 px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                                        }
                                      />
                                    </>
                                  )
                              ) : (
                                false
                              )}
                            </div>
                          </div>
                        ))
                        : <p className="text-center my-7">
                          No Offer found, We'll notify you as soon as you a get a new offer.
                        </p>}
                    </>
                  )} */}

                  {this.state?.rfqDetail?.isOwner &&
                    this.state?.rfqDetail?.alerts?.length > 0 &&
                    this.state.rfqDetail?.alerts.map((alert) => (
                      <div>
                        {alert.is_pending && (
                          <>
                            <p className="mt-10 Medium">Permissions</p>
                            <p className="gray fs-13">
                              Following is the Permission
                            </p>
                            <div className="flex items-center justify-center gap-x-5 my-7">
                              <div className="flex gap-x-3 mb-2 min-w-[240px] max-w-[240px]">
                                <div>
                                  <Profileavatar singleUser={alert.sender} />
                                </div>
                                <div>
                                  <div>
                                    {alert.sender.firstname}{" "}
                                    {alert.sender.lastname}
                                  </div>
                                  <p className="Light gray fs-11">
                                    @{alert.sender.username}
                                  </p>
                                </div>
                              </div>
                              <Buttonlink
                                disabled={this.state.permissionDisabled}
                                onClick={() =>
                                  this.respondOfferPermission(
                                    rfqDetail.id,
                                    "Reject",
                                    alert.sender.id,
                                    alert.id
                                  )
                                }
                                text="Reject"
                                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--lightGray darkGray"
                              />
                              <Buttonlink
                                disabled={this.state.permissionDisabled}
                                onClick={() =>
                                  this.respondOfferPermission(
                                    rfqDetail.id,
                                    "Accept",
                                    alert.sender.id,
                                    alert.id
                                  )
                                }
                                text="Accept"
                                className="px-6 py-2.5 fs-15 leading-5 Regular rounded-lg shadow-md hover:opacity-70 bg--primary white hover:text-white"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                </Tab.Panel>

                <Tab.Panel key="award" className="bg-white rounded-xl xs:p-6">
                  <div className="flex justify-between flex-wrap gap-3">
                    <div>
                      <p className="Medium">Awarded</p>
                      <p className="gray fs-13">Following is the Awarded</p>
                    </div>
                  </div>
                  <Query
                    query={getOfferWinner}
                    variables={{ id: rfqDetail.id }}
                  >
                    {({ loading, error, data, fetchMore, refetch }) => {
                      this.refetch = refetch;
                      if (loading) return <Loader />;

                      if (error) return <Noconnection />;

                      if (data.offer?.winners?.length > 0) {
                        // Use reduce to filter out duplicates based on user.id
                        const uniqueWinners = data.offer.winners.reduce(
                          (acc, current) => {
                            const x = acc.find(
                              (item) => item.user.id === current.user.id
                            );
                            if (!x) {
                              acc.push(current);
                            }
                            return acc;
                          },
                          []
                        );
                        // let buyyerratting = data.offer?.review
                        //   ?.filter((r) => r.is_buyer === false)
                        //   ?.reduce((acc, current) => {
                        //     const x = acc.find(item => item.product_id === current.product_id);
                        //     if (!x) {
                        //       acc.push(current);
                        //     }
                        //     return acc;
                        //   }, []);

                        return uniqueWinners.map((item, key) => {
                          return this.renderWinner(data.offer, item, key);
                        });
                      } else {
                        return (
                          <div className="flex flex-col items-center justify-center intro-y">
                            <h3>No Winner</h3>
                          </div>
                        );
                      }
                    }}
                  </Query>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            {this.props.auth_user?.id && rfqDetail?.id && (
              <ChatPopup
                openChatPopup={() => this.openChatPopup()}
                chatPopup={this.state.chatPopup}
                authUser={this.props.auth_user}
                onClose={() => this.getOfferByID()}
                rfq={rfqDetail}
                is_seller={this.props.auth_user.is_seller}
                canMessage={executiveIdsWithCanMessage}
                receiver_id={this.state.receiver_id}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  return {
    auth_user: Auth.authUserInformation,
    socket: Auth.socket,
  };
};
export default connect(mapStateToProps, null)(RFQdetailstabs);
