import React, { useEffect, useState } from "react";
import { gql } from "@apollo/client";
import { InputAdornment, TextField, Autocomplete } from "@mui/material";
import client from "../../graphql/client";

const GET_OPTIONS = gql`
  query getCountries($searchText: String) {
    countries(first: 246, name: $searchText) {
      data {
        id
        sortname
        name
        phonecode
      }
    }
  }
`;

function SelectCountry(props) {
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const [code, setCode] = useState([]);
  const [selectedCountryPhonecode, setSelectedCountryPhonecode] = useState("0");
  const [searchTextDebounced, setSearchTextDebounced] = useState(
    typeof props?.seleced_country_object === "string"
      ? props?.seleced_country_object
      : null
  );
  useEffect(() => {
    //console.log('first', props);
    const variables = {
      searchText: searchTextDebounced ?? "",
    };

    client
      .query({
        query: GET_OPTIONS,
        variables,
      })
      .then((result) => {
        // console.log("result.data.countries.data", result);
        setCode(result.data.countries.data);
        //setSelectedCountryPhonecode(code[0].phonecode);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  useEffect(() => {
    const variables = {
      searchText: "",
    };

    client
      .query({
        query: GET_OPTIONS,
        variables,
      })
      .then((result) => {
        setOptions(result.data.countries.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    let selectedCountry = ""; // This variable is not necessary, we can remove it.

    const timerId = setTimeout(() => {
      setSearchTextDebounced(searchText); // Assuming setSearchTextDebounced is a state setter function for the debounced searchText.

      const defaultSelectedCountry = props?.selected_country_object; // Using destructuring to get the selected_country_object from props.
      const selectedCountryAfterChanged = options.find(
        (option) => option.name === searchText
      ); // Finding the selected country based on the searchText.
      let countryCode =
        selectedCountryAfterChanged?.phonecode ||
        props?.seleced_country_object?.phonecode ||
        (code && code.length < 2 && code[0]?.phonecode) ||
        "0"; // Simplified the code to find the countryCode.
      if (code) {
        selectedCountry =
          selectedCountryAfterChanged?.sortname ?? code[0]?.sortname; // Only update selectedCountry if code exists, otherwise, it remains empty.
      }

      setSelectedCountryPhonecode(countryCode); // Setting the countryCode using the state setter function.

      props?.country_id(
        selectedCountryAfterChanged?.id ||
        props?.seleced_country_object?.id ||
        selectedCountry?.id ||
        0
      ); // Call the country_id function from props with the selected country id or 0 if not available.

      // Simplified the condition checks for setting the sortname.
      if (
        props?.sortname &&
        (selectedCountryAfterChanged?.sortname || selectedCountry)
      ) {
        const sortnameValue =
          selectedCountryAfterChanged?.sortname ||
          defaultSelectedCountry?.sortname; // Set sortnameValue based on selectedCountryAfterChanged or defaultSelectedCountry.
        props.sortname(sortnameValue); // Call the sortname function from props with the sortnameValue.
      }
    }, 500);

    return () => {
      clearTimeout(timerId); // Cleanup the timer on unmount to avoid any potential memory leaks.
    };
  }, [searchText, code]);

  const handleInputChange = (event, value) => {
    setSearchText(value);
  };

  return (
    <>
      <div>
        <Autocomplete
          onInputChange={handleInputChange}
          options={options.map((option) => option.name)}
          defaultValue={
            props?.seleced_country_object?.name ?? props?.seleced_country_object
          }
          size="small"
          renderInput={(params) => <TextField {...params} label="Country *" />}
        />
      </div>
      <div>
        <TextField
          label="Country Code *"
          id="outlined-start-adornment"
          size="small"
          value={selectedCountryPhonecode}
          className="w-full"
          InputProps={{
            readOnly: true,
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          }}
        />
      </div>
    </>
  );
}

export default SelectCountry;
